import { notEmpty } from "@product/scmp-sdk";
import take from "lodash/take";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { articleAuthorDetailsContent$key } from "scmp-app/queries/__generated__/articleAuthorDetailsContent.graphql";

import { AuthorCard } from "./author-card";
import type { AuthorCardsContainerProps } from "./styles";
import { AuthorCardsContainer, PlusAuthorCardsContainer } from "./styles";

export type Props = {
  className?: string;
  firstNthAuthor?: number;
  reference: articleAuthorDetailsContent$key;
  variant?: AuthorCardsContainerProps["$variant"];
};

export const ArticleAuthorsDetails: FunctionComponent<Props> = ({
  className,
  firstNthAuthor,
  reference: reference_,
  variant = "light",
}) => {
  const content = useFragment(
    graphql`
      fragment articleAuthorDetailsContent on Content {
        authors {
          bio
          entityId
          ...authorCardAuthor
        }
      }
    `,
    reference_,
  );

  const authors = useMemo(() => {
    const items = content.authors ?? [];
    const filtered =
      variant === "magazines-style" ? items : items.filter(author => notEmpty(author?.bio));
    return firstNthAuthor ? take(filtered, firstNthAuthor) : filtered;
  }, [content.authors, firstNthAuthor, variant]);

  const renderAuthors = () =>
    authors?.map(
      author => author && <AuthorCard key={author.entityId} reference={author} variant={variant} />,
    );

  if (!notEmpty(content) || authors.length === 0) return null;

  switch (variant) {
    case "plus":
      return <PlusAuthorCardsContainer>{renderAuthors()}</PlusAuthorCardsContainer>;

    default:
      return (
        <AuthorCardsContainer $variant={variant} className={className}>
          {renderAuthors()}
        </AuthorCardsContainer>
      );
  }
};

ArticleAuthorsDetails.displayName = "ArticleAuthorsDetails";
