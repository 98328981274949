import styled from "@emotion/styled";
import { fontAngkor } from "@product/scmp-sdk";

import { ContentShareWidget } from "scmp-app/components/content/content-share-widget";

export const GenericPostiesArticleFigure = styled.figure`
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 28px;
  font-family: ${fontAngkor};
  line-height: 140%;

  &:not(:last-of-type) {
    margin-block-end: 20px;
  }
`;

export const GenericPostiesArticleBlockquote = styled.blockquote`
  margin-block-start: 28px;

  color: #0f75bf;
  letter-spacing: -0.06em;
`;

export const GenericPostiesArticleFigcaption = styled.figcaption`
  margin-block-start: 14px;

  color: #389810;
  letter-spacing: -0.05em;
`;

export const GenericPostiesArticleFooter = styled.footer`
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  margin-block-start: 14px;
`;

export const GenericPostiesArticleContentShareWidgetContainer = styled.div`
  display: flex;
  flex-flow: row;

  column-gap: 16px;
`;

export const GenericPostiesArticleContentShareWidget = styled(ContentShareWidget)`
  display: flex;
  flex-flow: row;

  column-gap: 16px;
`;
