import { memo } from "react";
import type { FunctionComponent, ReactNode } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { Portal } from "scmp-app/components/common/portal";
import { HeaderArticleSpeechContainerElementID } from "scmp-app/components/header/consts";

export type Props = {
  children?: ReactNode;
};

const Component: FunctionComponent<Props> = ({ children }) => (
  <IdleQueue>
    <Portal selector={`#${HeaderArticleSpeechContainerElementID}`}>{children}</Portal>
  </IdleQueue>
);
Component.displayName = "HeaderArticleSpeech";

export const HeaderArticleSpeech = memo(Component);
