import axios from "axios";
import orderBy from "lodash/orderBy";
import type { Dispatch, SetStateAction } from "react";
import * as yup from "yup";

import { config } from "shared/data";

import type {
  Question,
  StudentFormFields,
  StudentStaticFields,
} from "scmp-app/components/posties/student-form/types";

import { GetFormById, GetLatestForm, RequiredMessage } from "./consts";
import type {
  MergedStudentFormFields,
  ModifiedFormData,
  OriginalFormData,
  StsTokenResponse,
  StudentFormStaticData,
} from "./types";

type StudentForm = {
  formData: ModifiedFormData;
  setFields: Dispatch<SetStateAction<null | StudentFormFields>>;
  studentFormFields: StudentStaticFields;
};

export const getStsToken = async (recaptchaToken: string | undefined) => {
  const responseData = await axios.post<StsTokenResponse>(
    config.sts.studentService.uri,
    { recaptchaToken },
    {
      headers: {
        apiKey: config.graphql.studentService.apiKey,
        "Content-Type": "application/json",
      },
    },
  );

  const {
    credentials: { accessKeyId, accessKeySecret, stsToken },
  } = responseData.data;
  if (!accessKeyId || !accessKeySecret || !stsToken) {
    throw new Error("Failed to get OSS credential");
  }
  return {
    accessKeyId,
    accessKeySecret,
    stsToken,
  };
};

export const getFormData = async (form: { documentId: string; prefix: string }) => {
  const isLatestForm = form.prefix === "form-latest";
  const documentId = form.documentId;
  const formData = await axios.post<{
    data: {
      getForm: null | OriginalFormData;
      getLatestFormByType: null | OriginalFormData;
    };
  }>(
    config.graphql.studentService.host,
    {
      query: isLatestForm ? GetLatestForm : GetFormById,
      variables: isLatestForm
        ? {
            typeId: documentId,
          }
        : {
            documentId,
          },
    },
    {
      headers: {
        apiKey: config.graphql.studentService.apiKey,
        "Content-Type": "application/json",
      },
    },
  );

  const data = isLatestForm
    ? formData.data?.data?.getLatestFormByType
    : formData.data?.data?.getForm;

  if (data) {
    const { description, documentId, educations, isExpired, questions, title, ...staticFields } =
      data;

    const modifiedData = {
      educations,
      questions,
      restData: {
        description,
        documentId,
        isExpired,
        title,
      },
      staticFields,
    };

    return modifiedData;
  }
  return null;
};

const isNonEmptyString = (value: unknown): value is string =>
  typeof value === "string" && value.trim() !== "";

const sortedKeys = (staticFields: StudentFormStaticData) => {
  const staticFieldsObject = Object.keys(staticFields);

  return staticFieldsObject.sort((a, b) => {
    const itemA = staticFields[a as keyof StudentFormStaticData];
    const itemB = staticFields[b as keyof StudentFormStaticData];

    const orderA = isNonEmptyString(itemA?.order)
      ? Number(itemA?.order)
      : staticFieldsObject.length;
    const orderB = isNonEmptyString(itemB?.order)
      ? Number(itemB?.order)
      : staticFieldsObject.length;

    return orderA - orderB;
  });
};

export const getStudentForm = ({ formData, setFields, studentFormFields }: StudentForm) => {
  const { educations, questions, staticFields } = formData;

  const orderedStaticFields = sortedKeys(staticFields).reduce((accumulator, currentValue) => {
    const key = currentValue as keyof StudentFormStaticData;

    Object.assign(accumulator, { [key]: staticFields[key] });
    return accumulator;
  }, {} as StudentFormStaticData);

  const studentForm = {
    defaultValues: {},
    questionsIds: [],
    schema: {},
    staticFields: {},
  } as unknown as StudentFormFields;

  studentForm.staticFields = Object.keys(orderedStaticFields).reduce(
    (accumulator: MergedStudentFormFields, currentValue) => {
      const key = currentValue as keyof StudentStaticFields;
      if (orderedStaticFields[key] && orderedStaticFields[key].display) {
        if (studentFormFields[key]?.validation) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          studentForm.schema[key] = orderedStaticFields[key].required
            ? studentFormFields[key].validation.required(RequiredMessage)
            : studentFormFields[key].validation;
        } else if (orderedStaticFields[key].required) {
          studentForm.schema[key] = yup.string().required(RequiredMessage);
        }

        if (
          (studentFormFields[key]?.type === "autoComplete" ||
            studentFormFields[key]?.type === "school") &&
          studentFormFields[key]?.others?.field
        ) {
          const others = studentFormFields[key]?.others;
          studentForm.schema[others.fieldKey] = others.field.validation;
          studentForm.defaultValues[others.fieldKey] = "";
        }
      }

      studentForm.defaultValues[key] =
        studentFormFields[key]?.defaultValue === undefined
          ? ""
          : studentFormFields[key]?.defaultValue;

      const getExtraData = () => {
        if (studentFormFields[key]?.type !== "autoComplete") return {};

        switch (key) {
          case "yearOrGrade":
            return { options: educations };
          default:
            return {};
        }
      };

      const modifiedField = {
        ...studentFormFields[key],
        ...orderedStaticFields[key],
        ...getExtraData(),
      };

      Object.assign(accumulator, { [key]: modifiedField });

      return accumulator;
    },
    {} as MergedStudentFormFields,
  );

  orderBy(questions, ["order"], ["asc"])?.map(question => {
    const documentId = question.documentId as keyof typeof studentForm.schema;

    studentForm.schema[documentId] = getQuestionSchema(question);
    studentForm.questionsIds.push(documentId);

    studentForm.defaultValues[documentId] = question.answerType === "multiple_choice" ? [] : "";
  });

  setFields(studentForm);
};

export const getQuestionSchema = (question: Question) => {
  switch (question.answerType) {
    case "multiple_choice":
      return question.required
        ? yup.array().of(yup.string()).min(1, RequiredMessage)
        : yup.array().of(yup.string());
    default:
      const wordLimit = question.wordLimit ? Number(question.wordLimit) : null;
      if (wordLimit) {
        return question.required
          ? yup
              .string()
              .test("maxWords", `Please limit your answer to ${wordLimit} words`, value =>
                value ? value.split(" ").length <= wordLimit : true,
              )
              .required(RequiredMessage)
          : yup
              .string()
              .nullable()
              .test("maxWords", `Please limit your answer to ${wordLimit} words`, value =>
                value ? value.split(" ").length <= wordLimit : true,
              );
      }

      return question.required ? yup.string().required(RequiredMessage) : yup.string().nullable();
  }
};
