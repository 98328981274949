import { useAtomValue } from "jotai";
import { type FunctionComponent, useMemo, useState } from "react";

import { ZIndexLayer } from "shared/lib/styles";

import { useArticleRenderContext } from "scmp-app/components/article/article-render/contexts";
import type { VariantProps } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/types";
import { useArticleSpeechPlayerController } from "scmp-app/components/article/article-speech/article-speech-player/hooks";
import { useArticleSpeechContext } from "scmp-app/components/article/article-speech/contexts";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import Pippa from "./img-pippa.png";
import {
  Container,
  Description,
  PippaImage,
  StyledButton,
  StyledLineImg,
  StyledPlayIcon,
  TextContainer,
  Title,
} from "./styles";

type Props = VariantProps & {
  entityId: string;
};

export const ArticleSpeechInlineWidgetPosties: FunctionComponent<Props> = ({
  className,
  entityId,
}) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const { articleElementReference } = useArticleRenderContext();
  const [hasArticleSpeechPlayed, setHasArticleSpeechPlayed] = useState(false);
  const { handleInitNewPlayerAndPlay } = useArticleSpeechPlayerController();
  const { articleSpeechifyPlayerState } = useArticleSpeechContext();

  const isShowForPostiesArticle = useMemo(() => {
    const shouldShowPaywall =
      asyncRosettaState?.result?.hasPianoExperienceExecuted &&
      !asyncRosettaState.result?.hasPostiesAccessRight;

    return !shouldShowPaywall;
  }, [
    asyncRosettaState?.result?.hasPostiesAccessRight,
    asyncRosettaState?.result?.hasPianoExperienceExecuted,
  ]);

  const isButtonDisable = useMemo(
    () =>
      articleSpeechifyPlayerState === "playing" ||
      !isShowForPostiesArticle ||
      hasArticleSpeechPlayed,
    [hasArticleSpeechPlayed, articleSpeechifyPlayerState, isShowForPostiesArticle],
  );

  const handlePlayClick = () => {
    if (hasArticleSpeechPlayed || !articleElementReference?.current) return;

    void handleInitNewPlayerAndPlay({
      entityId,
      playerConfig: {
        customStyles: {
          playButton: {
            background: "radial-gradient(#ffffff 50%, transparent 50%)",
          },
          primaryColor: "#ff7c1d",
          widget: {
            borderRadius: "8px",
            zIndex: `${ZIndexLayer.Drawer - 1}`,
          },
        },
        enableAutoScroll: true,
        enableHighlighting: true,
        inlinePlayerElement: undefined,
        inlinePlayerSelector: undefined,
        paragraphSettings: {
          isEnabled: "while-playing",
          marginRight: "-10px",
        },
        rootElement: articleElementReference.current,
      },
    });

    setHasArticleSpeechPlayed(true);
  };

  return (
    <Container className={className}>
      <PippaImage src={Pippa.src} />
      <TextContainer>
        <Title>Listen Now</Title>
        <StyledLineImg />
        <Description>Enjoy the audio version of this article!</Description>
      </TextContainer>
      <StyledButton
        $isDisabled={isButtonDisable}
        backgroundColor={isButtonDisable ? "#cccccc" : "#fecc17"}
        onClick={() => handlePlayClick()}
        variant="button"
      >
        <StyledPlayIcon />
        Play
      </StyledButton>
    </Container>
  );
};

ArticleSpeechInlineWidgetPosties.displayName = "ArticleSpeechInlineWidgetPosties";
