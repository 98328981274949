/**
 * @generated SignedSource<<502bd336b8bb34d256561fb9b6ca3e0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleTopicFollowWidgetArticle$data = {
  readonly topics: ReadonlyArray<{
    readonly disableFollow: boolean | null | undefined;
    readonly entityId: string;
    readonly entityUuid: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityFollowButtonBase" | "topicLinkTopic">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "articleTopicFollowWidgetArticle";
};
export type articleTopicFollowWidgetArticle$key = {
  readonly " $data"?: articleTopicFollowWidgetArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleTopicFollowWidgetArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleTopicFollowWidgetArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicLinkTopic"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityFollowButtonBase"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableFollow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityUuid",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "f79e82fcbb8407e07bd509edfd0d8014";

export default node;
