import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBarlow, fontRoboto } from "@product/scmp-sdk";

import type { AuthorCardsContainerProps } from "scmp-app/components/article/article-author-details/styles";

type Props = {
  $variant?: AuthorCardsContainerProps["$variant"];
};

export const StyledDiv = styled.div<Props>`
  color: #6f6f6f;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 21px;

  ${props =>
    props?.$variant === "magazines-style"
      ? css`
          color: #222222;
          font-weight: 400;
          font-size: 16px;
          font-family: ${fontBarlow};
          line-height: 26px;
        `
      : null};
`;
