import type { FunctionComponent } from "react";

import blackIconJson from "./listening-icon/black-icon-listening-final.json";
import greyIconJson from "./listening-icon/grey-icon-listening-final.json";
import {
  IconContainer,
  StyledIconHeadphoneSvg,
  StyledIconSpinnerSvg,
  StyledPlayer,
} from "./styles";

export type HeadphoneIconTheme = "black" | "grey";

type Props = {
  isLoading: boolean;
  isPaused: boolean;
  isPlayed: boolean;
  theme?: HeadphoneIconTheme;
};
export const HeadphoneIcon: FunctionComponent<Props> = ({
  isLoading,
  isPaused,
  isPlayed,
  theme = "grey",
}) => {
  if (isLoading) {
    return <StyledIconSpinnerSvg />;
  }

  if (isPlayed) {
    const json = theme === "black" ? blackIconJson : greyIconJson;
    return <StyledPlayer autoplay loop src={json} />;
  }

  if (isPaused) {
    return (
      <IconContainer $isPaused={true} $theme={theme}>
        <StyledIconHeadphoneSvg />
      </IconContainer>
    );
  }

  return (
    <IconContainer>
      <StyledIconHeadphoneSvg />
    </IconContainer>
  );
};

HeadphoneIcon.displayName = "HeadphoneIcon";
