import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  fontBarlowSemiCondensed,
  fontRobotoCondensed,
  StyledBlueSpinningLoadingIcon,
  theme,
} from "@product/scmp-sdk";

import { EntityFollowButton } from "scmp-app/components/entity-follow-button";
import type { TypeMagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { TopicLink } from "scmp-app/components/topic/topic-link";

export const StyledTopicLink = styled(TopicLink)``;

export const PlusTopicLink = styled.div`
  cursor: pointer;
`;

export const Divider = styled.div`
  inline-size: 1px;
  block-size: 15px;
  margin-inline: 12px;

  background: #ffffff;
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)``;

export type StyledProps = {
  $isFollowed: boolean;
  $variant: "banner" | "plus" | "stack" | TypeMagazinesStyleVariant;
};
export const Container = styled.div<StyledProps>`
  ${props => {
    switch (props.$variant) {
      case "banner":
        return css`
          display: flex;
          align-items: center;

          min-block-size: 40px;
          padding-block: 10px;
          padding-inline: 12px;

          background: #ffca05;

          ${StyledTopicLink} {
            margin-inline-end: auto;

            color: #000000;
            font-weight: 700;
            font-size: 16px;
            font-family: ${fontRobotoCondensed};
            line-height: 19px;

            :hover {
              color: #2c4692;
            }

            ${theme.breakpoints.up("tablet")} {
              font-size: 19px;
            }
          }

          ${Divider} {
            flex: none;
          }

          ${Divider}:last-child {
            display: none;
          }

          ${theme.breakpoints.up("tablet")} {
            inline-size: min-content;

            white-space: nowrap;
          }
        `;
      case MagazinesStyleVariant:
        return css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          min-block-size: 33px;
          padding-block: 8px;
          padding-inline: 12px;

          border: 1px solid #000000;
          border-radius: 4px;

          ${StyledTopicLink} {
            color: #000000;
            font-weight: 700;
            font-size: 14px;
            font-family: ${fontBarlowSemiCondensed};
            line-height: 14px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
          }

          ${StyledEntityFollowButton} {
            button {
              padding-block: 0;

              color: #000000;
              font-weight: 400;
              font-size: 14px;
              font-family: ${fontBarlowSemiCondensed};
              letter-spacing: 0.7px;

              border: 0;

              background-color: transparent;
            }
          }

          ${props.$isFollowed
            ? css`
                border: 1px solid #a1a1a1;

                ${StyledTopicLink} {
                  color: #a1a1a1;
                }

                ${StyledEntityFollowButton} {
                  button {
                    color: #a1a1a1;
                  }
                }
              `
            : null}
        `;
      case "plus":
        return css`
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-end;

          ${StyledTopicLink}, ${PlusTopicLink} {
            display: block;

            margin-block-end: 8px;

            font-weight: 700;
            font-size: 16px;
            font-family: ${fontRobotoCondensed};
            line-height: 19px;
          }

          ${Divider} {
            display: none;
          }

          && ${StyledBlueSpinningLoadingIcon} {
            inline-size: 22px;
            block-size: 22px;
            margin: -4px !important;
          }

          ${StyledEntityFollowButton} {
            button {
              min-inline-size: 64px;
              padding-block: 2px;
              padding-inline: 4px;

              font-size: 12px;
              font-family: ${fontRobotoCondensed};
              line-height: 14px;
            }
          }
        `;
      case "stack":
        return css`
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;

          ${StyledTopicLink} {
            color: #000000;
            font-size: 16px;
            font-family: ${fontRobotoCondensed};
            line-height: 16px;
          }

          ${StyledEntityFollowButton} {
            margin-block-start: 12px;
          }

          ${Divider} {
            display: none;
          }
        `;
    }
  }}
`;
