/**
 * @generated SignedSource<<8c21b945fd8cba858c0788e9029dbdd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksPaywallPromiseContent$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostiesArticle">;
  readonly " $fragmentType": "hooksPaywallPromiseContent";
};
export type hooksPaywallPromiseContent$key = {
  readonly " $data"?: hooksPaywallPromiseContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksPaywallPromiseContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksPaywallPromiseContent",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SectionSegment",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Section",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "8777895143c64adb4d2d2f2e75506cab";

export default node;
