import { useSyncedRef } from "@react-hookz/web";
import type { FunctionComponent } from "react";
import { useContext, useEffect, useState } from "react";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

import { ToolbarButton } from "./toolbar-button";

export const ToolbarButtonZoom: FunctionComponent = () => {
  const { currentSlice, instance } = useContext(GalleryContext);
  const latestCurrentSlice = useSyncedRef(currentSlice);
  const [isZoomed, setIsZoomed] = useState(
    currentSlice && currentSlice?.currZoomLevel > currentSlice.zoomLevels.initial,
  );

  useEffect(() => {
    if (!instance) return;

    const handler = () => {
      const slice = latestCurrentSlice.current;
      if (!slice) return;
      setIsZoomed(slice.currZoomLevel > slice.zoomLevels.initial);
    };

    instance.on("zoomPanUpdate", handler);
    return () => instance.off("zoomPanUpdate", handler);
  }, [instance, latestCurrentSlice]);

  if (latestCurrentSlice.current?.zoomLevels.fill === 1) return null;

  return (
    <ToolbarButton
      icon={isZoomed ? "zoom_out" : "zoom_in"}
      onClick={() => {
        instance?.toggleZoom();
      }}
      title="Toggle Zoom"
    />
  );
};

ToolbarButtonZoom.displayName = "ToolbarButtonZoom";
