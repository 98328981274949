import { useIsomorphicLayoutEffect, useSyncedRef } from "@react-hookz/web";
import type { MutableRefObject } from "react";
import { useState } from "react";

import { useArticleListContext } from "scmp-app/components/article/article-list/contexts";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useOptimizelyContext } from "scmp-app/lib/optimizely";

export type PercentageReached = 0 | 20 | 50 | 90 | 100 | undefined;

export const usePercentageIntersect = (
  itemElementReference: MutableRefObject<Element | null> | null,
  entityId: null | string,
) => {
  const [percentageReached, setPercentageReached] = useState<PercentageReached>();
  const latestPercentageReached = useSyncedRef(percentageReached);
  const [isTrackingSent, setIsTrackingSent] = useState({ 50: false, 90: false });
  const { fireEvent } = useOptimizelyContext();

  const { firstArticleEntityId } = useArticleListContext();

  const sendTracking = (percentage: 50 | 90) => {
    setIsTrackingSent(previousState => ({
      ...previousState,
      [percentage]: true,
    }));

    if (percentage === 90 && firstArticleEntityId !== entityId) {
      fireEvent?.({ eventName: "viewed_percentage_100" });
    }

    sendGA4Tracking({
      action: "sys",
      category: "article",
      customized_parameters: {
        article_id: entityId ?? "",
        percentage: percentage === 90 ? 100 : percentage,
      },
      subcategory: "completion",
    });
  };

  useIsomorphicLayoutEffect(() => {
    const reading0PercentageTarget = itemElementReference?.current?.querySelector(
      ".js-reading-0-percent-completion-tracker",
    );

    const reading20PercentageTarget = itemElementReference?.current?.querySelector(
      ".js-reading-20-percent-completion-tracker",
    );

    const reading50PercentageTarget = itemElementReference?.current?.querySelector(
      ".js-reading-50-percent-completion-tracker",
    );

    const reading90PercentageTarget = itemElementReference?.current?.querySelector(
      ".js-reading-90-percent-completion-tracker",
    );

    const reading100PercentageTarget = itemElementReference?.current?.querySelector(
      ".js-reading-100-percent-completion-tracker",
    );

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) return;

          const targetPercentageReached: PercentageReached = (() => {
            switch (entry.target) {
              case reading0PercentageTarget:
                return 0;
              case reading20PercentageTarget:
                return 20;
              case reading50PercentageTarget:
                return 50;
              case reading90PercentageTarget:
                return 90;
              case reading100PercentageTarget:
                return 100;
            }
          })();
          // percentaged can be 0 so should check if it's undefined or not
          if (
            latestPercentageReached.current !== undefined &&
            targetPercentageReached !== undefined &&
            latestPercentageReached.current >= targetPercentageReached
          )
            return;
          setPercentageReached(targetPercentageReached);

          if (
            (targetPercentageReached === 50 || targetPercentageReached === 90) &&
            !isTrackingSent[targetPercentageReached]
          ) {
            sendTracking(targetPercentageReached);
          }
        });
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 1] },
    );

    if (reading0PercentageTarget) observer.observe(reading0PercentageTarget);
    if (reading20PercentageTarget) observer.observe(reading20PercentageTarget);
    if (reading50PercentageTarget) observer.observe(reading50PercentageTarget);
    if (reading90PercentageTarget) observer.observe(reading90PercentageTarget);
    if (reading100PercentageTarget) observer.observe(reading100PercentageTarget);

    return () => {
      observer.disconnect();
    };
  }, [itemElementReference]);

  return { percentageReached, setPercentageReached };
};
