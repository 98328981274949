import styled from "@emotion/styled";
import { fontMerriweather, theme } from "@product/scmp-sdk";

import { TopicLink } from "scmp-app/components/topic/topic-link";

export const StyledTopicLink = styled(TopicLink)`
  padding-block-end: 7px;

  color: #000000;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontMerriweather};
  font-style: italic;
  border-block-end: 1px #000000 solid;

  line-height: normal;

  ${theme.breakpoints.up("tablet")} {
    font-size: 14px;
  }
`;

export const Container = styled.div``;
