import type { Dispatch, FunctionComponent, ReactNode, RefObject, SetStateAction } from "react";
import { createContext, useContext, useRef, useState } from "react";

import type { PercentageReached } from "./hooks";
import { usePercentageIntersect } from "./hooks";

type ContextValue = {
  articleElementReference?: RefObject<HTMLDivElement>;
  percentageReached?: PercentageReached;
  setEntityId?: Dispatch<SetStateAction<null | string>>;
  setIsContentReady?: Dispatch<SetStateAction<boolean>>;
  setPercentageReached?: Dispatch<SetStateAction<PercentageReached>>;
};

type Props = {
  children: ReactNode;
};

const ArticleRenderContext = createContext<ContextValue>({});

export const ArticleRenderContextProvider: FunctionComponent<Props> = ({ children }) => {
  const articleElementReference = useRef<HTMLDivElement>(null);
  const [isContentReady, setIsContentReady] = useState(false);
  const [entityId, setEntityId] = useState<null | string>(null);
  const { percentageReached, setPercentageReached } = usePercentageIntersect(
    isContentReady ? articleElementReference : null,
    entityId,
  );

  return (
    <ArticleRenderContext.Provider
      value={{
        articleElementReference,
        percentageReached,
        setEntityId,
        setIsContentReady,
        setPercentageReached,
      }}
    >
      {children}
    </ArticleRenderContext.Provider>
  );
};

export const useArticleRenderContext = () => useContext(ArticleRenderContext);
