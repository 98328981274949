import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { Button } from "scmp-app/components/common/button";

export const ReadFullArticleButton = styled(Button)`
  flex-direction: row;

  inline-size: 100%;
  margin-inline-end: 12px;
  padding-block: 15px 14px;

  color: #4585ff;
  font-size: 18px;
  font-family: ${fontRoboto};

  border: 1px solid #4585ff;
  border-radius: 2px;

  background: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  color: #4585ff;

  ${ReadFullArticleButton} {
    background-color: transparent;
  }
`;

export const Spinner = styled.div`
  inline-size: 20px;
  margin-inline-start: 2px;

  border-radius: 50%;

  background:
    radial-gradient(farthest-side, #4585ff 94%, #00000000) top/3px 3px no-repeat,
    conic-gradient(#00000000 0%, #4585ff);

  animation: l2 1s infinite linear;
  aspect-ratio: 1;
  mask: radial-gradient(farthest-side, #00000000 calc(100% - 3px), #000000 50%);

  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }
`;
