import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { checkIsMainTopic } from "scmp-app/components/content/helpers";
import { SponsorTag } from "scmp-app/components/sponsor-tag";
import type { StyledProps as SponsorTagStyledProps } from "scmp-app/components/sponsor-tag/styles";
import type { articleSponsorContent$key } from "scmp-app/queries/__generated__/articleSponsorContent.graphql";

import { StyledContentSchemaRender, StyledTooltip } from "./styles";

type Props = {
  className?: string;
  reference: articleSponsorContent$key;
  sponsorTagStyle?: Partial<PropsFromStyledProps<SponsorTagStyledProps>>;
};

export const ArticleSponsor: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  sponsorTagStyle,
}) => {
  const content = useFragment(
    graphql`
      fragment articleSponsorContent on Article {
        topics {
          entityId
          sponsor {
            description {
              json
            }
            ...sponsorTagSponsor
          }
        }
        ...helpersUseContentSponsorContent
        ...helpersCheckIsMainTopicContent
        ...contentSchemaRenderContent
      }
    `,
    reference_,
  );
  const sponsor = getContentSponsor(content);
  if (!content.topics || !sponsor) return null;

  const mainTopic = content.topics.find(topic => checkIsMainTopic(content, topic?.entityId));
  if (!mainTopic) return null;

  const sponsorTagElement = (
    <SponsorTag
      {...sponsorTagStyle}
      className={className}
      reference={sponsor}
      typeFontSize={sponsorTagStyle?.typeFontSize ?? "12px"}
      typeLineHeight={sponsorTagStyle?.typeLineHeight ?? "14px"}
    />
  );
  if (notEmpty(mainTopic.sponsor?.description?.json))
    return (
      <StyledTooltip
        arrow
        placement="bottom-start"
        title={
          <StyledContentSchemaRender
            reference={content}
            schema={mainTopic.sponsor?.description?.json}
          />
        }
      >
        {sponsorTagElement}
      </StyledTooltip>
    );

  return sponsorTagElement;
};

ArticleSponsor.displayName = "ArticleSponsor";
