import { FollowType } from "@product/scmp-sdk";
import { useToggle } from "@react-hookz/web";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import type { defaultAuthorCardAuthor$key } from "scmp-app/queries/__generated__/defaultAuthorCardAuthor.graphql";

import {
  AuthorCardContainer,
  AvatarContainer,
  BioContainer,
  HeaderContainer,
  StyledAuthorBio,
  StyledAuthorName,
  StyledHomeFollowButton,
} from "./styles";

export type Props = {
  reference: defaultAuthorCardAuthor$key;
};

export const DefaultAuthorCard: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const author = useFragment(
    graphql`
      fragment defaultAuthorCardAuthor on Author {
        ...authorAvatarAuthor
        ...authorBioAuthor
        ...authorNameAuthor
        ...authorRoleAuthor
        ...entityFollowButtonBase
        ...followButtonBase
      }
    `,
    reference_,
  );

  const [isExpandBio, setIsExpandBio] = useToggle();

  return (
    <AuthorCardContainer>
      <AvatarContainer>
        <AuthorAvatar height={40} placeholder reference={author} width={40} />
      </AvatarContainer>
      <HeaderContainer>
        <StyledAuthorName reference={author} />
        <StyledHomeFollowButton
          isLoadingEnabled
          reference={author}
          source="Article_bottom"
          type={FollowType.Author}
        />
      </HeaderContainer>
      <BioContainer>
        <StyledAuthorBio $isExpand={isExpandBio} onClick={setIsExpandBio} reference={author} />
      </BioContainer>
    </AuthorCardContainer>
  );
};

DefaultAuthorCard.displayName = "DefaultAuthorCard";
