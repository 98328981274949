import { useAsync, useMountEffect } from "@react-hookz/web";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import type { scmpArticleSpeechConfigQuery } from "scmp-app/queries/__generated__/scmpArticleSpeechConfigQuery.graphql";

export const useArticleSpeechConfig = () => {
  const environment = useRelayEnvironment();

  const [state, actions] = useAsync(async () => {
    const data = await fetchQuery<scmpArticleSpeechConfigQuery>(
      environment,
      graphql`
        query scmpArticleSpeechConfigQuery {
          appConfig(filter: { entityId: "scmp_pwa_article_speech" }) {
            json
          }
        }
      `,
      {},
      {
        fetchPolicy: "network-only",
      },
    ).toPromise();

    if (!data?.appConfig?.json) return;
    return data.appConfig.json as ScmpPwaArticleSpeechAppConfig;
  });

  useMountEffect(() => {
    void actions.execute();
  });

  return { scmpArticleSpeechConfigState: state.result };
};

type ScmpPwaArticleSpeechAppConfig = {
  forceHideByArticleEntityId?: string[];
  forceHideByFlag?: string[];
  forceHideBySectionEntityId?: string[];
  forceHideByTopicEntityId?: string[];
  forceHideByTypeEntityId?: string[];
};
