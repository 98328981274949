/**
 * @generated SignedSource<<6b60240a0f3e5580e8c0de77d3577553>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleDateContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"article20DateContent" | "defaultArticleDateContent">;
  readonly " $fragmentType": "articleDateContent";
};
export type articleDateContent$key = {
  readonly " $data"?: articleDateContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleDateContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleDateContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "defaultArticleDateContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "article20DateContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "26a7f29c950744295a48c26bcf5a2ef2";

export default node;
