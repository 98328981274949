import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";

export const StyledAdSlot = styled(AdSlot)`
  align-items: flex-start;

  block-size: unset;
`;

export const Container = styled.div`
  ${theme.breakpoints.down("desktop")} {
    block-size: 100%;
    min-inline-size: 180px;
    padding: 20px;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;

    background-color: #ffffff;
  }
`;

export const Title = styled.div`
  padding-block-end: 12px;

  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
`;
