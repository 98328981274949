import type { FunctionComponent, MouseEvent, ReactNode } from "react";

import type { Props as BaseLinkProps } from "shared/components/common/base-link";

import { StyledButton, StyledLinkButton, StyledOffsetContainer } from "./styles";

export type LinkButtonProps = BaseLinkProps &
  BaseProps & {
    variant: "link";
  };

type BaseProps = {
  backgroundColor?: string;
  children: ReactNode;
  className?: string;
  target?: string;
};

type NormalButtonProps = BaseProps & {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant: "button";
};

type Props = LinkButtonProps | NormalButtonProps;

export const Button: FunctionComponent<Props> = props => {
  const { backgroundColor = "#fecc17", children, className, variant } = props;
  return (
    <StyledOffsetContainer backgroundColor={backgroundColor}>
      {variant === "button" ? (
        <StyledButton className={className} {...props}>
          {children}
        </StyledButton>
      ) : (
        <StyledLinkButton className={className} {...props}>
          {children}
        </StyledLinkButton>
      )}
    </StyledOffsetContainer>
  );
};

Button.displayName = "Button";
