import { notEmpty, parseDate } from "@product/scmp-sdk";
import { useSyncedRef, useUnmountEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import first from "lodash/first";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { config } from "shared/data";
import { getLoginUrl } from "shared/lib/account";

import { AppBarColorScheme, getAppBarVariant } from "scmp-app/components/app-bar/helpers";
import { useShareContentUnlocked } from "scmp-app/components/article/article-render/hooks/share-content-unlocked";
import {
  checkCanShowInPlusPlatform,
  checkIsContainsPlusQueryString,
  checkIsPlusArticle,
} from "scmp-app/components/article/article-render/plus/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { appAtom } from "scmp-app/lib/app/atoms";
import { useCurrentArticleIds } from "scmp-app/lib/current-item";
import {
  closeAndClearUpPianoMeterModal,
  rosettaAtom,
  setPianoCustomVariable,
  setPianoVariable,
} from "scmp-app/lib/rosetta";
import { useRosettaCampaign } from "scmp-app/lib/rosetta/hooks/rosetta-campaign";
import { usePaywallStrategies } from "scmp-app/lib/rosetta/hooks/rosetta-paywall-strategies";
import { useRosettaPianoExecution } from "scmp-app/lib/rosetta/hooks/rosetta-piano-execution";
import type { pianoIntegrationArticle$key } from "scmp-app/queries/__generated__/pianoIntegrationArticle.graphql";

import { checkArticleIsInArchiveWall, checkArticleIsInVelocityWall } from "./helpers";

export const usePianoIntegration = (article_: pianoIntegrationArticle$key) => {
  const router = useRouter();
  const { currentArticleId } = useCurrentArticleIds();
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const { navigatorInfo, navigatorInfoLoaded } = useAtomValue(appAtom);
  const { isRosettaPianoExecutionReady, shouldSkipPianoExecution } = useRosettaPianoExecution(
    navigatorInfo,
    navigatorInfoLoaded,
  );

  const article = useFragment(
    graphql`
      fragment pianoIntegrationArticle on Article {
        __typename
        contentLock
        createdDate
        entityId
        entityUuid
        headline
        publishedDate
        sponsor
        sponsorType
        urlAlias
        authors {
          name
        }
        sections {
          value {
            entityUuid
            name
          }
        }
        topics {
          name
        }
        types {
          value {
            entityId
            name
          }
        }
        ...helpersAppBarVariantBase
        ...hooksPaywallPromiseContent
        ...helpersIsArchiveWallArticle
        ...helpersIsVelocityWallArticle
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPlusArticle
        ...helpersCheckCanShowInPlusPlatformContent
        ...shareContentUnlockedArticle
      }
    `,
    article_,
  );

  const latestRosettaInstance = useSyncedRef(asyncRosettaState?.result?.instance);
  const { getActiveCampaignSettings } = useRosettaCampaign();
  const { getActivePaywallStrategy } = usePaywallStrategies();

  const [isExecuted, setIsExecuted] = useState(false);
  const isShareContentUnlocked = useShareContentUnlocked(article);

  useEffect(() => {
    const appSettings = asyncRosettaState?.result?.appSettings ?? {};

    if (
      !notEmpty(appSettings) ||
      asyncRosettaState?.status !== "success" ||
      !isRosettaPianoExecutionReady ||
      currentArticleId !== article.entityId
    )
      return;

    const { oldArticleTimeframe = 5, paywallCampaignName } = appSettings;
    const articleUrl = new URL(location.origin);
    articleUrl.pathname = article.urlAlias;

    const handleSetArticleVariables = () => {
      const subscribeUrl = new URL(config.rosetta.subscribeHost);
      subscribeUrl.searchParams.set("destination", articleUrl.toString());

      setPianoCustomVariable("entityUuid", article.entityUuid);
      setPianoCustomVariable("entityId", article.entityId);
      setPianoCustomVariable("headline", article.headline);
      setPianoCustomVariable("loginUrl", getLoginUrl(articleUrl.toString()));
      setPianoCustomVariable("subscribeUrl", subscribeUrl.toString());
      setPianoCustomVariable("theme", AppBarColorScheme[getAppBarVariant(article)]);
      setPianoCustomVariable("paywallCampaign", paywallCampaignName);
      setPianoCustomVariable(
        "topics",
        article.topics?.map(topic => topic?.name.replaceAll(/["#$%&'()*+.:<>?\\{}~]/g, "_")) ?? [],
      );
      setPianoCustomVariable(
        "sections",
        article?.sections?.map(section => section?.value?.map(value => value?.name).join("/")) ??
          [],
      );
    };

    const handleSetGeneralVariables = () => {
      setPianoVariable("setZone", "Web");
      setPianoVariable("setPageURL", articleUrl.toString());

      const activeCampaignSettings = getActiveCampaignSettings();
      const activeArchivePaywallStrategy = getActivePaywallStrategy("archive");

      const isInArchiveWall = checkArticleIsInArchiveWall(
        article,
        activeCampaignSettings?.settings?.paywall?.archive?.articleAgeThresholdDays ??
          activeArchivePaywallStrategy?.settings?.paywall?.archive?.articleAgeThresholdDays ??
          oldArticleTimeframe,
      );

      const isInVelocityWall = checkArticleIsInVelocityWall(article);
      const isScmpPlusArticle =
        checkIsPlusArticle(article) ||
        (checkCanShowInPlusPlatform(article) && checkIsContainsPlusQueryString(router.query));

      const contentUnlock =
        isShareContentUnlocked || !article?.contentLock ? "contentUnlocked" : "";
      const articleType = first(article.types?.flatMap(type => type?.value))?.name ?? "";

      setPianoVariable(
        "setTags",
        [
          // refs: https://project.scmp.tech/product/web/rosetta/-/issues/1084
          ...(article.topics?.map(topic =>
            topic?.name.replaceAll(/["#$%&'()*+.:<>?\\{}~]/g, "_"),
          ) ?? []),
          article.__typename ? `contentType/${article.__typename}` : "",
          contentUnlock,
          article.sponsorType ? `sponsorType/${article.sponsorType}` : "",
          isInArchiveWall ? "category/archive" : "",
          isInVelocityWall ? "category/velocity" : "",
          notEmpty(articleType) ? `articleType/${articleType}` : "",
          isScmpPlusArticle ? "scmp-plus" : "",
        ].filter(notEmpty),
      );
    };

    const handleSetContentVariables = () => {
      const firstSection = first(article.sections);
      setPianoVariable(
        "setContentCreated",
        parseDate(
          notEmpty(article.publishedDate) ? article.publishedDate : (article.createdDate ?? 0),
        ).toISOString(),
      );
      setPianoVariable("setContentAuthor", first(article.authors)?.name);
      setPianoVariable("setContentIsNative", notEmpty(article.sponsor));
      setPianoVariable(
        "setContentSection",
        (firstSection?.value?.map(section => section?.name).slice(-2) ?? []).join("/"),
      );
    };

    handleSetArticleVariables();
    handleSetGeneralVariables();
    handleSetContentVariables();
    if (isExecuted) return;
    closeAndClearUpPianoMeterModal();
    if (shouldSkipPianoExecution) return;
    const isPostiesArticle = checkIsPostiesArticle(article);
    if (isPostiesArticle) return;
    latestRosettaInstance.current?.execute();
    setIsExecuted(true);
  }, [
    article,
    asyncRosettaState?.result?.appSettings,
    asyncRosettaState?.status,
    latestRosettaInstance,
    currentArticleId,
    isExecuted,
    router,
    isShareContentUnlocked,
    shouldSkipPianoExecution,
    isRosettaPianoExecutionReady,
    getActiveCampaignSettings,
    getActivePaywallStrategy,
  ]);

  useUnmountEffect(() => closeAndClearUpPianoMeterModal());
};
