import styled from "@emotion/styled";
import type { FunctionComponent } from "react";

import { StyledTable, TableContainer } from "scmp-app/components/schema-render/common/table/styles";
import { getTableCaptionsAndFilteredChildren } from "scmp-app/components/schema-render/common/table/utils";
import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";

import {
  GenericPostiesArticleTableCaption,
  GenericPostiesArticleTableSummary,
  GenericPostiesArticleTextAndTableContainer,
} from "./styles";

export type Props = ContentSchemaRenderProps & {
  summary?: string;
};

export const Component: FunctionComponent<Props> = ({ children, ...attribs }) => {
  const [captions, filteredChildren] = getTableCaptionsAndFilteredChildren(children);

  const summary = attribs?.summary;

  return (
    <GenericPostiesArticleTextAndTableContainer>
      {summary ? (
        <GenericPostiesArticleTableSummary>{summary}</GenericPostiesArticleTableSummary>
      ) : null}
      {captions ? (
        <GenericPostiesArticleTableCaption>{captions}</GenericPostiesArticleTableCaption>
      ) : null}
      <TableContainer>
        <StyledTable>{filteredChildren}</StyledTable>
      </TableContainer>
    </GenericPostiesArticleTextAndTableContainer>
  );
};

export const GenericPostiesArticleTable = styled(Component)``;
