import styled from "@emotion/styled";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";

import { InlineAdSlot } from ".";
import { InlineOutstreamAdSlot as InlineOutstreamAdSlot_ } from "./outstream";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const InlineSlot = styled(AdSlot)`
  inline-size: fit-content;
`;

export const InlineNativeSlot = styled(AdSlot)``;

export const InlineOutstreamAdSlot = styled(InlineOutstreamAdSlot_)`
  display: flex;
  justify-content: center;
`;

export const StyledInlineAdSlot = styled(InlineAdSlot)`
  max-inline-size: 100vw;
`;
