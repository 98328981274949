import styled from "@emotion/styled";
import { fontAngkor, fontFredoka, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import {
  Item,
  LatestCorrectionDate,
  LatestCorrectionDateContainer,
} from "scmp-app/components/article/article-corrections-widget/styles";
import { ArticleImageContainer } from "scmp-app/components/article/article-image/styles";
import { GenericPostiesLeading } from "scmp-app/components/article/article-leading/generic-posties-article-leading";
import { pageStyle } from "scmp-app/components/article/article-render/article-content/standard-styles";
import { GenericPostiesArticleVocabBox } from "scmp-app/components/article/article-vocab-box/generic-posties-article-vocab-box";
import {
  H2Container as ContentSubHeadlineH2Container,
  H3Container as ContentSubHeadlineH3Container,
} from "scmp-app/components/content/content-sub-headline/styles";

import { PostiesSubHeadline } from "./sub-headline";

export const ListenNow = styled.div`
  display: flex;
  justify-content: center;

  inline-size: 100%;
  margin-block-start: 64.5px;

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const Authors = styled.div`
  font-weight: 600;
`;

export const AuthorsAndPublishedDate = styled.div`
  order: 2;

  display: flex;
  flex-flow: column;
  justify-content: center;

  max-inline-size: 440px;
  margin-inline: auto;

  color: #555555;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 24px;
  text-align: center;
  column-gap: 8px;

  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 100%;

    font-size: 20px;
    line-height: 28px;
  }

  ${theme.breakpoints.up("largeDesktop")} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Correction = styled.div`
  order: 5;

  display: flex;

  margin-block: 32px 32px;
  margin-block-end: 36px;

  :empty {
    display: none;
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 100%;
    margin-block-start: 16px;
    margin-inline: auto;
  }

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;
  }

  ${LatestCorrectionDateContainer} {
    display: flex;
    align-items: center;

    inline-size: fit-content;
    padding-block: 6px;
    padding-inline: 8px;

    background-color: #ecf3ff;
  }

  ${LatestCorrectionDateContainer}:after {
    position: relative;

    margin-block-start: 0;

    border-block-end-color: #1b4191;
  }

  ${LatestCorrectionDate} {
    color: #1b4191;
  }
  ${Item} {
    margin-inline: 4px;

    color: #000000;
  }

  ${LatestCorrectionDate}, ${Item} {
    line-height: normal;
    text-decoration: none;

    ${theme.breakpoints.up("tablet")} {
      font-size: 16px;
    }
  }
`;

export const ContentContainer = styled.div`
  order: 5;

  inline-size: 100%;
  max-inline-size: 440px;
  margin-block-start: 40px;
  margin-inline: auto;

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;

    max-inline-size: 100%;
  }
`;

export const Content = styled.div`
  ${props => pageStyle(props)}
  color: #333333;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontFredoka};
  line-height: 28px;
  letter-spacing: -0.01em;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-end: 16px;

    color: #ff7c1d;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: #0f75bf;
  }

  ${ArticleImageContainer} {
    inset-inline-start: unset;
    inset-inline-end: unset;

    inline-size: 100%;
    margin-inline: auto;
  }

  img {
    border-radius: 12px;

    cursor: default !important;
  }

  ${theme.breakpoints.up("tablet")} {
    font-size: 22px;
    line-height: 32px;
  }

  ${theme.breakpoints.up("desktop")} {
    font-size: 24px;
    line-height: 36px;

    img {
      border-radius: 16px;
    }
  }
`;

export const GenericPostiesArticleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-block-start: 56px;

  ${theme.breakpoints.up("desktop")} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    margin-block-start: 96px;

    column-gap: 24px;

    .ima-ad-player {
      inline-size: 100% !important;
    }
  }
`;

export const Heading1 = styled.h1`
  /** add more specificity to override the pageStyle h1 */
  && {
    font-weight: 400;
    font-size: 48px;
    font-family: ${fontAngkor};
    line-height: 56px;
    letter-spacing: -0.03em;
  }
`;

export const Heading2 = styled.h2`
  /** add more specificity to override the pageStyle h2 */
  && {
    font-weight: 400;
    font-size: 32px;
    font-family: ${fontAngkor};
    line-height: 38px;
    letter-spacing: -0.03em;
  }
`;

export const Heading3 = styled.h3`
  /** add more specificity to override the pageStyle h3 */
  && {
    font-weight: 400;
    font-size: 28px;
    font-family: ${fontAngkor};
    line-height: 36px;
    letter-spacing: -0.01em;
  }
`;

export const Heading4 = styled.h4`
  /** add more specificity to override the pageStyle h4 */
  && {
    font-weight: 600;
    font-size: 24px;
    font-family: ${fontFredoka};
    line-height: 36px;
    letter-spacing: -0.01em;
  }
`;

export const Heading5 = styled.h5`
  /** add more specificity to override the pageStyle h5 */
  && {
    font-weight: 400;
    font-size: 24px;
    font-family: ${fontFredoka};
    line-height: 32px;
    letter-spacing: 0;
  }
`;

export const Heading6 = styled.h6`
  /** add more specificity to override the pageStyle h6 */
  && {
    font-weight: 400;
    font-size: 24px;
    font-family: ${fontFredoka};
    line-height: 32px;
    letter-spacing: 0;
  }
`;

export const Headline = styled.div`
  max-inline-size: 440px;
  margin-inline: auto;

  color: #1b4191;
  font-weight: 400;
  font-size: 36px;
  font-family: ${fontAngkor};
  line-height: 40px;
  letter-spacing: -0.03em;
  text-align: center;

  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 100%;

    font-size: 48px;
    line-height: 56px;
  }
`;

export const MobileTop = styled.div`
  order: 5;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-block-start: 32px;
  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const MobileBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-block-start: 20px;
  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const DesktopLeft = styled.div`
  grid-row: span 1;
  grid-column: span 4;

  position: sticky;

  display: none;

  margin-block-start: 56px;
  ${theme.breakpoints.up("desktop")} {
    grid-column: 1 / span 3;

    order: 5;

    display: block;
  }
`;

export const DesktopRight = styled.div`
  display: none;
  justify-self: end;

  margin-block-start: 56px;
  ${theme.breakpoints.up("desktop")} {
    grid-column: -4 / span 3;

    order: 5;

    display: block;

    & > div:not(:first-child) {
      margin-block-start: 225px;
    }
  }
`;

export const PublishedDate = styled.div`
  font-weight: 400;
`;

export const Right = styled.div`
  position: sticky;

  margin-block-start: 40px;

  ${theme.breakpoints.up("desktop")} {
    grid-column: -4 / span 3;

    margin-block-start: 56px;
  }
`;

export const Section = styled(BaseLink)`
  inline-size: fit-content;
  max-inline-size: 280px;
  margin-inline: auto;
  padding-block: 8px;
  padding-inline: 16px;

  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  font-family: ${fontFredoka};
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;

  border-radius: 8px;

  background: #ff7c1d;

  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 100%;

    font-size: 18px;
  }
`;

export const StyledGenericPostiesArticleVocabBox = styled(GenericPostiesArticleVocabBox)``;

export const StyledGenericPostiesLeading = styled(GenericPostiesLeading)`
  order: 3;

  ${theme.breakpoints.up("desktop")} {
    order: 4;
  }
`;

export const StyledPostiesSubHeadline = styled(PostiesSubHeadline)`
  order: 4;

  max-inline-size: 440px;
  margin-block: 30px 0;
  margin-inline: auto;

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;

    order: 3;

    max-inline-size: 100%;
    margin-block: 44px 0;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    margin-block: 50px 0;
  }

  ul,
  ol {
    padding-inline-start: 0;

    list-style-type: none;
  }

  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #333333;
    font-weight: 500;
    font-size: 20px;
    font-family: ${fontFredoka};
    line-height: 26px;

    ${theme.breakpoints.up("tablet")} {
      font-size: 22px;
      line-height: 28px;
    }

    ${theme.breakpoints.up("mediumDesktop")} {
      font-size: 24px;
      line-height: 32px;
    }
  }

  li {
    &::before {
      content: none !important;
    }
    &:not(:first-of-type) {
      display: none;
    }
  }

  ${ContentSubHeadlineH2Container}, ${ContentSubHeadlineH3Container} {
    > *:not(:first-child) {
      display: none;
    }
  }
`;

export const StyledTopBackgroundImage = styled.img`
  inline-size: 100%;

  object-fit: contain;
`;

export const TopBackgroundContainer = styled.div`
  position: absolute;
  inset-inline-start: -20px;
  z-index: -1;

  inline-size: 100vw;
  margin-block-start: -56px;

  ${theme.breakpoints.up("tablet")} {
    inset-inline-start: -40px;
  }

  ${theme.breakpoints.up("desktop")} {
    inset-inline-start: -60px;

    margin-block-start: -96px;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    inset-inline-start: -80px;
  }

  ${theme.breakpoints.up("xLargeDesktop")} {
    inset-inline-start: calc(-52px - 50vw + 780px);
  }
`;

export const TopBlock = styled.div`
  order: 1;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;

  min-block-size: 300px;

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;
  }
`;

export const FormLink = styled.span`
  color: #0f75bf;
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;

  cursor: pointer;
`;

export const PostiesQuizWrapper = styled.div`
  order: 7;

  margin-block-start: 80px;

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;

    margin-block-start: 72px;
  }
`;

export const DesktopVocabBox = styled.div`
  display: none;

  ${theme.breakpoints.up("mediumDesktop")} {
    display: block;
  }
`;

export const MobileVocabBox = styled.div`
  order: 6;

  display: flex;
  justify-content: center;

  margin-block-start: 40px;

  ${theme.breakpoints.up("desktop")} {
    grid-column: 4 / span 6;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    display: none;
  }
`;
