/**
 * @generated SignedSource<<04bbf7cbc5c507337321418145f3caf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksShowGenericInlineWidgetByArticleArticle$data = {
  readonly entityId: string;
  readonly flag: string | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPostiesArticle" | "hooksPaywallPromiseContent">;
  readonly " $fragmentType": "hooksShowGenericInlineWidgetByArticleArticle";
};
export type hooksShowGenericInlineWidgetByArticleArticle$key = {
  readonly " $data"?: hooksShowGenericInlineWidgetByArticleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksShowGenericInlineWidgetByArticleArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksShowGenericInlineWidgetByArticleArticle",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": [
        (v2/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksPaywallPromiseContent"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flag",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleTypeSegment",
      "kind": "LinkedField",
      "name": "types",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleType",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "dd1e0829bea281c1c6a11a91254b95fc";

export default node;
