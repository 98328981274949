import { FollowButtonOutline, FollowButtonVariant } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import { AuthorBio } from "scmp-app/components/author/author-bio";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import type { styleAuthorCardAuthor$key } from "scmp-app/queries/__generated__/styleAuthorCardAuthor.graphql";

import {
  StyleAuthorCardContainer,
  StyleAuthorInfoContainer,
  StyleAvatarContainer,
  StyledAuthorSocialLinks,
  StyledEntityFollowButton,
  StyledStyleAuthorName,
  StyleNameContainer,
} from "./styles";

export type Props = {
  reference: styleAuthorCardAuthor$key;
};

export const StyleAuthorCard: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const author = useFragment(
    graphql`
      fragment styleAuthorCardAuthor on Author {
        ...authorSocialLinksAuthor
        ...authorAvatarAuthor
        ...authorNameAuthor
        ...authorBioAuthor
        ...entityFollowButtonBase
      }
    `,
    reference_,
  );

  return (
    <StyleAuthorCardContainer>
      <StyleAvatarContainer>
        <AuthorAvatar height={80} placeholder reference={author} width={80} />
      </StyleAvatarContainer>
      <StyleAuthorInfoContainer>
        <StyleNameContainer>
          <StyledStyleAuthorName reference={author} />
          <StyledEntityFollowButton
            outline={FollowButtonOutline.Outlined}
            reference={author}
            source="Article_bottom"
            variant={FollowButtonVariant.Style}
          />
        </StyleNameContainer>
        <AuthorBio reference={author} variant={MagazinesStyleVariant} />
        <StyledAuthorSocialLinks reference={author} variant={MagazinesStyleVariant} />
      </StyleAuthorInfoContainer>
    </StyleAuthorCardContainer>
  );
};

StyleAuthorCard.displayName = "StyleAuthorCard";
