import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

export type ContainerProps = {
  $variant: "dark" | "light";
};

export const Container = styled.div<ContainerProps>`
  inline-size: 100%;
  margin-block: 0 32px;

  color: #6f6f6f;
  font-size: 16px;
  line-height: 19px;

  ${props => {
    if (props.$variant === "dark") {
      return css`
        color: #fff;
      `;
    }
  }}

  ${theme.breakpoints.up("tablet")} {
    margin-block: 12px 40px;
  }
`;
