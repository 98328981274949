import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import {
  Article20CopyLink,
  type Props as Article20CopyLinkProps,
} from "scmp-app/components/article/article-2.0-action-widget/copy-link";
import type { Props as CommentTriggerProps } from "scmp-app/components/article/article-comments-trigger";
import type { Props as ArticlePrintProps } from "scmp-app/components/article/article-print";
import { ArticlePrint } from "scmp-app/components/article/article-print";
import type { Props as ContentBookmarkProps } from "scmp-app/components/content/content-bookmark";
import type { articleActionWidgetArticle$key } from "scmp-app/queries/__generated__/articleActionWidgetArticle.graphql";

import type { ContainerProps, StyledContentBookmarkProps } from "./styles";
import { Container, StyledCommentsTrigger, StyledContentBookmark } from "./styles";

type BookmarkOption = {
  props: Partial<
    ContentBookmarkProps &
      StyledContentBookmarkProps & {
        position: "article_bottom" | "article_header" | "article_side";
      }
  >;
  type: "bookmark";
};

type CommentOption = {
  props: Partial<CommentTriggerProps>;
  type: "comment";
};

type CopyLinkOptions = {
  props: Partial<Article20CopyLinkProps>;
  type: "copy-link";
};

type Options = {
  container: ContainerProps;
  items: (BookmarkOption | CommentOption | CopyLinkOptions | PrintOption)[];
};

type PrintOption = {
  props: Partial<ArticlePrintProps>;
  type: "print";
};

type Variant =
  | "article-2.0"
  | "article-2.0-before-you-go"
  | "default"
  | "magazines-style"
  | "photoEssay"
  | "series";

const optionsMap: Record<Variant, Options> = {
  "article-2.0": {
    container: { $orientation: "row", $variant: "article-2.0" },
    items: [
      { props: { position: "article_header" }, type: "copy-link" },
      { props: { position: "article_header", variant: "article-2.0" }, type: "bookmark" },
      { props: { variant: "article-2.0" }, type: "comment" },
      { props: {}, type: "print" },
    ],
  },
  "article-2.0-before-you-go": {
    container: { $orientation: "row", $variant: "article-2.0" },
    items: [{ props: { position: "article_bottom", variant: "article-2.0" }, type: "bookmark" }],
  },
  default: {
    container: { $orientation: "column" },
    items: [
      { props: { variant: "widgetLight" }, type: "comment" },
      { props: { $variant: "light" }, type: "bookmark" },
    ],
  },

  "magazines-style": {
    container: { $orientation: "row", $variant: "magazines-style" },
    items: [
      { props: { variant: "button" }, type: "comment" },
      { props: { $size: "small", $variant: "light" }, type: "bookmark" },
    ],
  },
  photoEssay: {
    container: { $orientation: "column" },
    items: [
      { props: { variant: "widgetDark" }, type: "comment" },
      { props: { $variant: "dark" }, type: "bookmark" },
    ],
  },
  series: {
    container: { $orientation: "row", $variant: "article-2.0" },
    items: [
      { props: {}, type: "copy-link" },
      { props: { variant: "article-2.0" }, type: "bookmark" },
    ],
  },
};

type Props = {
  className?: string;
  reference: articleActionWidgetArticle$key;
  variant?: Variant;
};

export const ArticleActionWidget: FunctionComponent<Props> = ({
  className,
  reference,
  variant = "default",
}) => {
  const article = useFragment(
    graphql`
      fragment articleActionWidgetArticle on Article {
        ...articleCommentsTriggerArticle
        ...articlePrintArticle
        ...copyLinkArticle20CopyLinkContent
        entityId
      }
    `,
    reference,
  );

  const handleRenderCommentsTrigger = ({ props }: CommentOption) => (
    <StyledCommentsTrigger
      key="comment-trigger"
      reference={article}
      variant="widgetLight"
      {...props}
    />
  );

  const handleRenderContentBookmark = ({ props }: BookmarkOption) => (
    <StyledContentBookmark
      entityId={article.entityId}
      ga4Events={{
        click: {
          action: "click",
          category: "bookmark",
          customized_parameters: {
            action_type: "add",
            article_id: article.entityId,
            trigger_point: props.position ?? "article_side",
          },
          subcategory: "",
        },
      }}
      key="content-bookmark"
      location="side"
      {...props}
    />
  );

  const handleRenderPrint = ({ props }: PrintOption) => (
    <ArticlePrint key="content-print" {...props} reference={article} />
  );

  const handleRenderCopyLink = ({ props }: CopyLinkOptions) => (
    <Article20CopyLink key="copy-link" {...props} reference={article} />
  );

  const { container, items } = optionsMap[variant];

  return (
    <Container {...container} className={className}>
      {items.map(item => {
        switch (item.type) {
          case "bookmark":
            return handleRenderContentBookmark(item);
          case "comment":
            return handleRenderCommentsTrigger(item);
          case "copy-link":
            return handleRenderCopyLink(item);
          case "print":
            return handleRenderPrint(item);
        }
      })}
    </Container>
  );
};
ArticleActionWidget.displayName = "ArticleActionWidget";
