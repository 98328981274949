import type PhotoSwipe from "photoswipe";
import type Slide from "photoswipe/dist/types/slide/slide";
import { createContext } from "react";

type Context = {
  currentSlice: Slide | undefined;
  footerPortalElement: HTMLElement | undefined;
  instance: PhotoSwipe | undefined;
  rootPortalElement: HTMLElement | undefined;
  toolbarPortalElement: HTMLElement | undefined;
};
export const GalleryContext = createContext<Context>({
  currentSlice: undefined,
  footerPortalElement: undefined,
  instance: undefined,
  rootPortalElement: undefined,
  toolbarPortalElement: undefined,
});
