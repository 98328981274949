import type { FunctionComponent } from "react";

import type { Props } from "scmp-app/components/advertisement/ad-slots/outstream-ad-slot";
import { OutstreamAdSlot } from "scmp-app/components/advertisement/ad-slots/outstream-ad-slot";

import { useInlineOutstreamAdSlotDivId } from "./div-id";

export const InlineOutstreamAdSlot: FunctionComponent<Props> = props => {
  const divId = useInlineOutstreamAdSlotDivId();
  return <OutstreamAdSlot {...props} divId={divId} />;
};
InlineOutstreamAdSlot.displayName = "InlineOutstreamAdSlot";
