import styled from "@emotion/styled";
import { fontAngkor, fontFredoka, theme } from "@product/scmp-sdk";

import Line from "scmp-app/components/article/article-additional-info-box/line.svg";
import { ContentSchemaRender } from "scmp-app/components/schema-render/content";

export const BorderedContainer = styled.div`
  border-block-start: 2px solid #555555;

  max-inline-size: 440px;
  & + & {
    margin-block-start: 40px;
  }

  ${theme.breakpoints.up("desktop")} {
    position: relative;

    max-inline-size: initial;
    margin-inline-end: 30px;

    border: 2px solid #555555;
    border-radius: 15px;
    & + & {
      margin-block-start: 500px;
    }
  }
  ${theme.breakpoints.up("largeDesktop")} {
    max-inline-size: 280px;
  }
`;

export const ContentsWrapper = styled.div`
  padding: 20px;
  padding-block-end: 0;
  ${theme.breakpoints.up("desktop")} {
    padding-block: 70px 32px;
    padding-inline: 32px;
  }
`;

export const Title = styled.div`
  padding-block-end: 8px;

  color: #ff7c1d;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontAngkor};
  line-height: 24px;
  text-align: center;
  overflow-wrap: break-word;
  ${theme.breakpoints.up("desktop")} {
    padding-block-end: 0;

    font-size: 28px;
    line-height: 36px;
  }
`;

export const StyledContentSchemaRenderDesc = styled(ContentSchemaRender)`
  color: #777777;
  font-weight: 500;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 22px;
  text-align: center;
  overflow-wrap: break-word;
  ${theme.breakpoints.up("desktop")} {
    font-size: 22px;
    line-height: 27px;
    text-align: start;
  }
  ${theme.breakpoints.up("largeDesktop")} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const StyledImg = styled.img`
  inline-size: 100px;
  block-size: 98px;
`;

export const ImgWrapper = styled.div`
  position: absolute;
  inset-block-start: -47.5px;

  display: none;

  inline-size: 100%;
  ${theme.breakpoints.up("desktop")} {
    display: flex;
    justify-content: center;
  }
`;

export const StyledLineSvg = styled(Line)`
  ${theme.breakpoints.up("desktop")} {
    inline-size: 140px;
  }
  ${theme.breakpoints.up("largeDesktop")} {
    inline-size: 216px;
  }
`;

export const LineWrapper = styled.div`
  display: none;
  ${theme.breakpoints.up("desktop")} {
    display: flex;

    margin-block: 20px;
    margin-inline: 0;
  }
  ${theme.breakpoints.up("largeDesktop")} {
    margin-block: 24px;
    margin-inline: 0;
  }
`;
