import type { FunctionComponent } from "react";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";

import { Container, StyledAdSlot, Title } from "./styles";

type Props = {
  adSlotProps?: Partial<AdSlotProps>;
  className?: string;
};

export const ArticleScmpPollWidget: FunctionComponent<Props> = ({ adSlotProps, className }) => (
  <Container className={className}>
    <Title>scmp poll</Title>
    <StyledAdSlot
      {...adSlotProps}
      adUnit="native4"
      fluidDefaultSize={{ height: 154 }}
      sizes="fluid"
    />
  </Container>
);

ArticleScmpPollWidget.displayName = "ArticleScmpPollWidget";
