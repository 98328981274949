import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { AuthorCardsContainerProps } from "scmp-app/components/article/article-author-details/styles";
import type { authorSocialLinksAuthor$key } from "scmp-app/queries/__generated__/authorSocialLinksAuthor.graphql";

import { SocialLinkType } from "./enums";
import EmailIcon from "./icon-email.svg";
import FacebookIcon from "./icon-facebook.svg";
import InstagramIcon from "./icon-instagram.svg";
import TwitterIcon from "./icon-twitter.svg";
import { AuthorSocialLinkContainer, SocialLink, StyledSocialLinkIconContainer } from "./styles";

export type Props = {
  className?: string;
  reference: authorSocialLinksAuthor$key;
  variant?: AuthorCardsContainerProps["$variant"];
};

const socialLinkSvgMap: Record<SocialLinkType, FunctionComponent> = {
  [SocialLinkType.Email]: EmailIcon,
  [SocialLinkType.Facebook]: FacebookIcon,
  [SocialLinkType.Instagram]: InstagramIcon,
  [SocialLinkType.Twitter]: TwitterIcon,
};

export const AuthorSocialLinks: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  variant,
}) => {
  const { socialLinks } = useFragment(
    graphql`
      fragment authorSocialLinksAuthor on Author {
        socialLinks {
          class
          title
          url
        }
      }
    `,
    reference_,
  );

  const socialLinkKeyMap = useMemo(() => {
    if (!socialLinks) return {};
    return socialLinks.reduce(
      (accumulator, socialLink) => {
        if (socialLink?.class) {
          accumulator[socialLink.class] = socialLink;
        }
        return accumulator;
      },
      {} as Record<string, Exclude<(typeof socialLinks)[0], null>>,
    );
  }, [socialLinks]);

  if (!socialLinks?.length) return null;

  const renderSocialLink = (key: SocialLinkType, index: number) => {
    const socialLink = socialLinkKeyMap[key];
    const SvgComponent = socialLinkSvgMap[key];
    if (!socialLink) return null;

    return (
      <SocialLink
        anchorProps={{
          "aria-label": key,
          rel: "noopener noreferrer",
          target: "_blank",
          title: socialLink?.title ?? "",
        }}
        key={index}
        pathname={socialLink.url}
      >
        <StyledSocialLinkIconContainer $variant={variant}>
          <SvgComponent />
        </StyledSocialLinkIconContainer>
      </SocialLink>
    );
  };
  return (
    <AuthorSocialLinkContainer className={className}>
      {[
        SocialLinkType.Facebook,
        SocialLinkType.Twitter,
        SocialLinkType.Instagram,
        SocialLinkType.Email,
      ]
        .filter(key => socialLinkKeyMap[key])
        .map((key, index) => renderSocialLink(key, index))}
    </AuthorSocialLinkContainer>
  );
};

AuthorSocialLinks.displayName = "AuthorSocialLinks";
