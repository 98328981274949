import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { articleTopicContent$key } from "scmp-app/queries/__generated__/articleTopicContent.graphql";

import { Container, StyledTopicLink } from "./styles";

type Props = {
  className?: string;
  reference: articleTopicContent$key;
};

export const ArticleTopic: FunctionComponent<Props> = ({ className, reference }) => {
  const { topics } = useFragment(
    graphql`
      fragment articleTopicContent on Content {
        topics {
          ...topicLinkTopic
        }
      }
    `,
    reference,
  );
  const mainTopic = first(topics);

  if (!mainTopic) return null;

  return (
    <Container className={className}>
      <StyledTopicLink preferShortName reference={mainTopic} />
    </Container>
  );
};

ArticleTopic.displayName = "ArticleTopic";
