import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type {
  articleVocabBoxArticle$data,
  articleVocabBoxArticle$key,
} from "scmp-app/queries/__generated__/articleVocabBoxArticle.graphql";

import {
  StyledContentSchemaRender,
  StyledTerm,
  StyledTranslation,
  StyledVocab,
  StyledVocabBox,
} from "./styles";

type Props = {
  reference: articleVocabBoxArticle$key;
};

export const ArticleVocabBox: FunctionComponent<Props> = ({ reference }) => {
  const article = useFragment(
    graphql`
      fragment articleVocabBoxArticle on Article {
        glossary {
          term
          translation
          description {
            json
          }
        }
        ...contentSchemaRenderContent
      }
    `,
    reference,
  );

  const renderContent = (glossary: articleVocabBoxArticle$data["glossary"]) => (
    <StyledVocabBox>
      {glossary?.map?.((item, key) => {
        if (!item) return null;
        const { description, term, translation } = item;
        return (
          <StyledVocab key={key}>
            <StyledTerm>{term}</StyledTerm>
            <StyledTranslation>{translation}</StyledTranslation>
            <StyledContentSchemaRender reference={article} schema={description?.json} />
          </StyledVocab>
        );
      })}
    </StyledVocabBox>
  );

  if (!article) return null;
  return renderContent(article.glossary);
};

ArticleVocabBox.displayName = "ArticleVocabBox";
