import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { articlePrintArticle$key } from "scmp-app/queries/__generated__/articlePrintArticle.graphql";

import { StyledContentShareWidget, StyledIconPrint } from "./styles";

export type Props = {
  className?: string;
  reference: articlePrintArticle$key;
};

export const ArticlePrint: FunctionComponent<Props> = ({ className, reference }) => {
  const content = useFragment(
    graphql`
      fragment articlePrintArticle on Article {
        ...contentShareWidgetContent
      }
    `,
    reference,
  );

  return (
    <StyledContentShareWidget
      className={className}
      extraProps={{
        overrideIcons: {
          print: StyledIconPrint,
        },
      }}
      position="article_adhesive"
      reference={content}
      style={{
        /* stylelint-disable */
        backgroundColor: "transparent",
        mobilePadding: 0,
        padding: 0,
        size: 36,
        /* stylelint-enable */
      }}
      types={["print"]}
    />
  );
};

ArticlePrint.displayName = "ArticlePrint";
