import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type {
  genericPostiesArticleVocabBoxArticle$data,
  genericPostiesArticleVocabBoxArticle$key,
} from "scmp-app/queries/__generated__/genericPostiesArticleVocabBoxArticle.graphql";

import {
  Container,
  StyledContentSchemaRender,
  StyledGenericPostiesArticleNumberIcon,
  StyledLineSvg,
  StyledPig,
  StyledTerm,
  StyledTermAndTranslation,
  StyledText,
  StyledTranslation,
  StyledVocab,
  StyledVocabBox,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: genericPostiesArticleVocabBoxArticle$key;
};

export const GenericPostiesArticleVocabBox: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const article = useFragment(
    graphql`
      fragment genericPostiesArticleVocabBoxArticle on Article {
        glossary {
          term
          translation
          description {
            json
          }
        }
        ...contentSchemaRenderContent
      }
    `,
    reference,
  );

  const renderContent = (glossary: genericPostiesArticleVocabBoxArticle$data["glossary"]) => (
    <Container className={className}>
      <StyledPig />
      <Title>Say It</Title>
      <StyledLineSvg />
      <StyledVocabBox>
        {glossary?.map?.((item, key) => {
          if (!item) return null;
          const { description, term, translation } = item;
          return (
            <StyledVocab key={key}>
              <StyledGenericPostiesArticleNumberIcon number={key + 1} />
              <StyledText>
                <StyledTermAndTranslation>
                  <StyledTerm>{term}</StyledTerm>
                  <StyledTranslation>{translation}</StyledTranslation>
                </StyledTermAndTranslation>
              </StyledText>
              <StyledContentSchemaRender reference={article} schema={description?.json} />
            </StyledVocab>
          );
        })}
      </StyledVocabBox>
    </Container>
  );
  if (!article || !notEmpty(article.glossary)) return null;
  return renderContent(article.glossary);
};

GenericPostiesArticleVocabBox.displayName = "GenericPostiesArticleVocabBox";
