/**
 * @generated SignedSource<<f7ce4132b48b607cc662749520ef989a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentBodyContent$data = {
  readonly body: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
  readonly " $fragmentType": "contentBodyContent";
};
export type contentBodyContent$key = {
  readonly " $data"?: contentBodyContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentBodyContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "customContents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentBodyContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "customContents",
          "variableName": "customContents"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "bodyType"
        }
      ],
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "body",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "json",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "9f76f2924135361c2b5b59e606727796";

export default node;
