import { type FunctionComponent, startTransition, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentReadMoreContext } from "scmp-app/components/content/content-read-more/contexts";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { yieldToMain } from "scmp-app/lib/yield";
import type { contentReadMoreButtonContent$key } from "scmp-app/queries/__generated__/contentReadMoreButtonContent.graphql";

import { AppButton } from "./app-button";
import { Container, ReadFullArticleButton, Spinner } from "./styles";

export type Props = {
  className?: string;
  reference: contentReadMoreButtonContent$key;
};

export const ContentReadMoreButton: FunctionComponent<Props> = ({ reference, ...attribs }) => {
  const content = useFragment(
    graphql`
      fragment contentReadMoreButtonContent on Content {
        ...appButtonContent
      }
    `,
    reference,
  );

  const [isReadMoreLoading, setIsReadMoreLoading] = useState(false);
  const { isReadMoreClicked, setIsReadMoreClicked } = useContentReadMoreContext();
  if (isReadMoreClicked) return null;

  return (
    <Container {...attribs}>
      <ReadFullArticleButton
        noStyle
        onClick={async () => {
          await yieldToMain({ priority: "user-blocking" });
          setIsReadMoreLoading(true);
          startTransition(() => {
            setIsReadMoreClicked?.(true);
            setIsReadMoreLoading(false);
          });

          await yieldToMain({ priority: "background" });
          sendGA4Tracking({
            action: "click",
            category: "expand",
            customized_parameters: {
              action_type: "read_full_article",
            },
            subcategory: "",
          });
        }}
      >
        {isReadMoreLoading ? (
          <>
            Loading...
            <Spinner />
          </>
        ) : (
          "READ FULL ARTICLE"
        )}
      </ReadFullArticleButton>
      <AppButton reference={content} />
    </Container>
  );
};

ContentReadMoreButton.displayName = "ContentReadMoreButton";
