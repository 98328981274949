import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import BulletPoint from "./bullet-point.svg";

export const StyledSubHeadlineText = styled.div``;

export const StyledBulletPoint = styled(BulletPoint)`
  order: -1;

  margin-inline-end: 12px;
  ${theme.breakpoints.up("tablet")} {
    margin-inline-end: 16px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-inline-end: 20px;
  }

  ${theme.breakpoints.up("largeDesktop")} {
    margin-inline-end: 24px;
  }
`;

export const BulletPointWrapper = styled.div`
  display: flex;
  > ${StyledSubHeadlineText}:empty + ${StyledBulletPoint} {
    display: none;
  }
`;
