import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme, transientOptions } from "@product/scmp-sdk";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { ArticleActionWidget } from "scmp-app/components/article/article-action-widget";
import { ArticleAdditionalInfoBox } from "scmp-app/components/article/article-additional-info-box";
import { ArticleAuthorBlock } from "scmp-app/components/article/article-author-block";
import { ArticleAuthorAvatars } from "scmp-app/components/article/article-author-block/author-avatars";
import { AuthorNames } from "scmp-app/components/article/article-author-block/author-names";
import { ArticleAuthorsDetails } from "scmp-app/components/article/article-author-details";
import { ArticleBeforeYouGoWidget } from "scmp-app/components/article/article-before-you-go-widget";
import { ArticleCarouselWidget } from "scmp-app/components/article/article-carousel-widget";
import { ArticleCommentsTrigger } from "scmp-app/components/article/article-comments-trigger";
import { ArticleCorrectionsWidget } from "scmp-app/components/article/article-corrections-widget";
import { ArticleDate } from "scmp-app/components/article/article-date";
import { ArticleFurtherReadingWidget } from "scmp-app/components/article/article-further-reading-widget";
import { ArticleGeoLocationWidget } from "scmp-app/components/article/article-geo-location-widget";
import { ArticleImageGallery } from "scmp-app/components/article/article-image-gallery";
import { ArticleLeading } from "scmp-app/components/article/article-leading";
import { ArticleMoreOnThisList } from "scmp-app/components/article/article-more-on-this-list";
import { ArticlePaywallContainer } from "scmp-app/components/article/article-paywall-container";
import { ArticleReadingTime } from "scmp-app/components/article/article-reading-time";
import { ArticleRelatedTopics } from "scmp-app/components/article/article-related-topics";
import { ArticleScmpPollWidget } from "scmp-app/components/article/article-scmp-poll";
import { ArticleSections } from "scmp-app/components/article/article-sections";
import { ArticleSeries } from "scmp-app/components/article/article-series";
import { ArticleShareWidget } from "scmp-app/components/article/article-share-widget";
import { ArticleSpeechInlineWidget } from "scmp-app/components/article/article-speech";
import { ArticleSponsor } from "scmp-app/components/article/article-sponsor";
import { ArticleTopic } from "scmp-app/components/article/article-topic";
import { ArticleTopicFollowWidget } from "scmp-app/components/article/article-topic-follow-widget";
import { ArticleTopicWidget } from "scmp-app/components/article/article-topic-widget";
import { ArticleTopics } from "scmp-app/components/article/article-topics";
import { ArticleVocabBox } from "scmp-app/components/article/article-vocab-box";
import { ContentActionBar } from "scmp-app/components/content/content-action-bar";
import { ContentBody } from "scmp-app/components/content/content-body";
import { ContentHeadline } from "scmp-app/components/content/content-headline";
import { ContentLiveTag } from "scmp-app/components/content/content-live-tag";
import { ContentSubHeadline } from "scmp-app/components/content/content-sub-headline";

import { ArticleRenderType } from ".";

export const ContentReadMoreButtonContainer = styled.div`
  display: block;
  ${theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

type ArticleRenderContainerProps = {
  $renderType: ArticleRenderType;
};
export const ArticleRenderContainer = styled.div<ArticleRenderContainerProps>`
  ${props =>
    props.$renderType === ArticleRenderType.Style
      ? css`
          & > *:first-child {
            padding-block: 24px 56px;
          }

          ${theme.breakpoints.up("tablet")} {
            & > *:first-child {
              padding-block: 8px 64px;
            }
          }

          ${theme.breakpoints.up("desktop")} {
            & > *:first-child {
              padding-block: 64px;
            }
          }
        `
      : css`
          & > *:first-child {
            padding-block: 40px;
          }
        `}
`;

export const PrimaryContentBody = styled(ContentBody)``;
export const StyledAdSlot = styled(AdSlot)``;
export const StyledArticleActionBar = styled(ContentActionBar)``;
export const StyledArticleActionWidget = styled(ArticleActionWidget)``;
export const StyledArticleAdditionalInfoBox = styled(ArticleAdditionalInfoBox)``;
export const StyledArticleAuthorAvatars = styled(ArticleAuthorAvatars)``;
export const StyledArticleAuthorBlock = styled(ArticleAuthorBlock)``;
export const StyledArticleAuthorNames = styled(AuthorNames)``;
export const StyledArticleAuthorsDetails = styled(ArticleAuthorsDetails)``;
export const StyledArticleBeforeYouGoWidget = styled(ArticleBeforeYouGoWidget)``;
export const StyledArticleCarouselWidget = styled(ArticleCarouselWidget)``;
export const StyledArticleCommentsTrigger = styled(ArticleCommentsTrigger)``;
export const StyledArticleCorrectionsWidget = styled(ArticleCorrectionsWidget)``;
export const StyledArticleDate = styled(ArticleDate)``;
export const StyledArticleFurtherReadingWidget = styled(ArticleFurtherReadingWidget)``;
export const StyledArticleGeoLocationWidget = styled(ArticleGeoLocationWidget)``;
export const StyledArticleImageGallery = styled(ArticleImageGallery)``;
export const StyledArticleLeading = styled(ArticleLeading)``;
export const StyledArticleLiveTag = styled(ContentLiveTag)``;
export const StyledArticleMoreOnThisList = styled(ArticleMoreOnThisList)``;
export const StyledArticlePaywallContainer = styled(ArticlePaywallContainer)``;
export const StyledArticleReadingTime = styled(ArticleReadingTime)``;
export const StyledArticleRelatedTopics = styled(ArticleRelatedTopics)``;
export const StyledArticleScmpPollWidget = styled(ArticleScmpPollWidget)``;
export const StyledArticleSections = styled(ArticleSections)``;
export const StyledArticleSeries = styled(ArticleSeries)``;
export const StyledArticleShareWidget = styled(ArticleShareWidget)``;
export const StyledArticleSponsor = styled(ArticleSponsor)``;
export const StyledArticleTopic = styled(ArticleTopic)``;
export const StyledArticleTopicFollowWidget = styled(ArticleTopicFollowWidget)``;
export const StyledArticleTopics = styled(ArticleTopics)``;
export const StyledArticleTopicWidget = styled(ArticleTopicWidget)``;
export const StyledArticleVocabBox = styled(ArticleVocabBox)``;

type SecondlyContentBodyProps = {
  $isShow: boolean;
};
export const SecondlyContentBody = styled(ContentBody, {
  ...transientOptions,
})<SecondlyContentBodyProps>(
  props => css`
    display: none;
    ${theme.breakpoints.up("tablet")} {
      display: block;
    }

    ${props.$isShow && "display: block;"}
  `,
);

export const StyledContentHeadline = styled(ContentHeadline)``;
export const StyledContentSubHeadline = styled(ContentSubHeadline)``;
export const StyledArticleSpeechInlineWidget = styled(ArticleSpeechInlineWidget)``;

export const StyledCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  ${StyledArticleCommentsTrigger} {
    flex-grow: 1;
  }

  ${theme.breakpoints.up("tablet")} {
    display: block;
  }
`;

export type InlinePaywallOverlayerProps = {
  $height?: "normal" | "small";
  $variant?: "dark" | "light";
};
export const InlinePaywallOverlayer = styled.div<InlinePaywallOverlayerProps>`
  position: absolute;
  inset-block-end: 0;

  ${props =>
    props.$height === "normal"
      ? css`
          block-size: 200px;
        `
      : css`
          block-size: 110px;
        `}

  ${props =>
    props.$variant === "dark"
      ? css`
          inset-inline: -120px;

          inline-size: auto;

          background: linear-gradient(180deg, rgba(28, 30, 34, 0), #1c1e22 98%);
        `
      : css`
          inline-size: 100%;

          background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #ffffff 98%);
        `}
`;
