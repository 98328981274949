import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, theme } from "@product/scmp-sdk";

import {
  StyledImage as AuthorAvatarImage,
  StyledPlaceholder as AuthorPlaceholder,
} from "scmp-app/components/author/author-avatar/styles";
import { AuthorName } from "scmp-app/components/author/author-name";
import { AuthorSocialLinks } from "scmp-app/components/author/author-social-links";
import { EntityFollowButton } from "scmp-app/components/entity-follow-button";

export const StyledEntityFollowButton = styled(EntityFollowButton)``;

export const StyledAuthorName = styled(AuthorName)`
  line-height: 16px;
`;

export const StyledAuthorSocialLinks = styled(AuthorSocialLinks)`
  margin-block-start: 12px;
`;

export const StyleAuthorCardContainer = styled.div`
  display: flex;

  inline-size: 295px;

  text-align: start;

  &:first-child {
    position: relative;

    border-block-start: 1px solid #222222;
    &::before {
      position: absolute;
      inset-inline-start: 0;
      content: " ";

      inline-size: 80px;
      block-size: 0;

      border-block-start: 4px solid #222222;
    }
  }

  ${theme.breakpoints.up("tablet")} {
    inline-size: 582px;
  }

  ${theme.breakpoints.up("desktop")} {
    inline-size: 660px;
  }
`;

export const StyleAuthorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-block-start: 32px;
`;

export const StyleAvatarContainer = styled.div`
  margin-inline-end: 20px;
  padding-block-start: 40px;

  ${AuthorAvatarImage}, ${AuthorPlaceholder} {
    inline-size: 80px;
    block-size: 80px;
  }

  ${AuthorAvatarImage} {
    filter: none !important;
  }
`;

export const StyleNameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-block-end: 12px;
`;

export const StyledStyleAuthorName = styled(AuthorName)`
  display: flex;
  align-items: center;

  margin-block: 8px 8px;
  margin-inline-end: 16px;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: uppercase;
`;
