import dynamic from "next/dynamic";

import type { Props as GenericArticleProps } from "./generic-article";
import type { Props as InfographicArticleProps } from "./infographic-article";
import type { Props as LiveArticleProps } from "./live-article";
import type { Props as MorningStudioArticleProps } from "./morning-studio-article";
import type { Props as NewsletterArticleProps } from "./newsletter-article";
import type { Props as PhotoEssayAndLongReadArticleProps } from "./photo-essay-and-long-read-article";
import type { Props as PlusColumnsArticleProps } from "./plus/plus-columns-article";
import type { Props as PlusGenericArticleProps } from "./plus/plus-generic-article";
import type { Props as PostMagazineArticleProps } from "./post-magazine-article";
import type { Props as SeriesArticleProps } from "./series-article";
import type { Props as StyleArticleProps } from "./style-article";

export const DynamicStyleArticle = dynamic<StyleArticleProps>(() =>
  import("./style-article").then(module => module.StyleArticle),
);

export const DynamicPostMagazineArticle = dynamic<PostMagazineArticleProps>(() =>
  import("./post-magazine-article").then(module => module.PostMagazineArticle),
);

export const DynamicGenericArticle = dynamic<GenericArticleProps>(() =>
  import("./generic-article").then(module => module.GenericArticle),
);

export const DynamicInfographicArticle = dynamic<InfographicArticleProps>(() =>
  import("./infographic-article").then(module => module.InfographicArticle),
);

export const DynamicLiveArticle = dynamic<LiveArticleProps>(() =>
  import("./live-article").then(module => module.LiveArticle),
);

export const DynamicPhotoEssayAndLongReadArticle = dynamic<PhotoEssayAndLongReadArticleProps>(() =>
  import("./photo-essay-and-long-read-article").then(module => module.PhotoEssayAndLongReadArticle),
);

export const DynamicMorningStudioArticle = dynamic<MorningStudioArticleProps>(() =>
  import("./morning-studio-article").then(module => module.MorningStudioArticle),
);

export const DynamicNewsletterArticle = dynamic<NewsletterArticleProps>(() =>
  import("./newsletter-article").then(module => module.NewsletterArticle),
);

export const DynamicPlusGenericArticle = dynamic<PlusGenericArticleProps>(() =>
  import("./plus/plus-generic-article").then(module => module.PlusGenericArticle),
);

export const DynamicPlusColumnsArticle = dynamic<PlusColumnsArticleProps>(() =>
  import("./plus/plus-columns-article").then(module => module.PlusColumnsArticle),
);

export const DynamicSeriesArticle = dynamic<SeriesArticleProps>(() =>
  import("./series-article").then(module => module.SeriesArticle),
);
