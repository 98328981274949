/**
 * @generated SignedSource<<c5386b3d0f677f60bc4a62ad02fb2102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type plusAuthorCardAuthor$data = {
  readonly areaOfExpertise: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"authorAvatarAuthor" | "authorBioAuthor" | "authorNameAuthor">;
  readonly " $fragmentType": "plusAuthorCardAuthor";
};
export type plusAuthorCardAuthor$key = {
  readonly " $data"?: plusAuthorCardAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"plusAuthorCardAuthor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "plusAuthorCardAuthor",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorAvatarAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorNameAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorBioAuthor"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "areaOfExpertise",
      "storageKey": null
    }
  ],
  "type": "Author",
  "abstractKey": null
};

(node as any).hash = "026b55a7ed523b37f38ea39c91299a86";

export default node;
