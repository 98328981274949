import { forwardRef, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { articleSeriesCarouselContent$key } from "scmp-app/queries/__generated__/articleSeriesCarouselContent.graphql";

import { Container, Counter, CoverImage, CurrentText, Headline, StyledEntityLink } from "./styles";

type Props = ContentItemRenderVariantProps & {
  className?: string;
  isCurrent?: boolean;
  onClick?: () => void;
  reference: articleSeriesCarouselContent$key;
};

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, isCurrent = false, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment articleSeriesCarouselContent on Content {
          entityId
          ... on Article {
            seriesContainer {
              series {
                ... on Content {
                  entityId
                }
              }
            }
          }
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize80x80: true, withHeadline: true)
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content);

    const counter = useMemo(() => {
      const total = content.seriesContainer?.series?.length;
      const current = content.seriesContainer?.series?.findIndex(
        node => node?.entityId === content.entityId,
      );
      if (total === undefined || current === undefined) return null;
      return `[ ${current + 1} of ${total} ]`;
    }, [content.entityId, content.seriesContainer?.series]);

    return (
      <Container className={className} ref={reference}>
        <Headline>
          <StyledEntityLink onClick={onClick} reference={content}>
            {providers.headline({ preferSocialHeadline: true })}
          </StyledEntityLink>
        </Headline>
        <CoverImage>
          <StyledEntityLink onClick={onClick} reference={content}>
            {providers.coverImage({ withCaption: false })}
          </StyledEntityLink>
        </CoverImage>
        <Counter>
          <span>{counter}</span>
          {isCurrent && <CurrentText>Now reading</CurrentText>}
        </Counter>
      </Container>
    );
  },
);

Component.displayName = "ArticleSeriesCarousel";

export const ContentItemArticleSeriesCarousel = withHighlightedHeadline(Component);
