/**
 * @generated SignedSource<<99964f0a67f5d3438d7a59b3ef15d7f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleAdditionalInfoBoxArticleContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
  readonly " $fragmentType": "articleAdditionalInfoBoxArticleContent";
};
export type articleAdditionalInfoBoxArticleContent$key = {
  readonly " $data"?: articleAdditionalInfoBoxArticleContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleAdditionalInfoBoxArticleContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleAdditionalInfoBoxArticleContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "726f388d6a30fbc687eb40b5f9cafacf";

export default node;
