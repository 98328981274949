import type { FunctionComponent } from "react";

import { Container, IconWrapper, StyledIcon } from "./styles";

type Props = {
  className?: string;
  isMinify?: boolean;
};

export const EmptyButton: FunctionComponent<Props> = ({ className, isMinify = false }) => (
  <Container $isMinify={isMinify} className={className}>
    <IconWrapper>
      <StyledIcon />
    </IconWrapper>
  </Container>
);

EmptyButton.displayName = "EmptyButton";
