import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { AuthorCardsContainerProps } from "scmp-app/components/article/article-author-details/styles";
import type { authorBioAuthor$key } from "scmp-app/queries/__generated__/authorBioAuthor.graphql";

import { StyledDiv } from "./styles";

export type Props = {
  className?: string;
  onClick?: () => void;
  reference: authorBioAuthor$key;
  variant?: AuthorCardsContainerProps["$variant"];
};

export const AuthorBio: FunctionComponent<Props> = ({
  className,
  onClick,
  reference: reference_,
  variant,
}) => {
  const author = useFragment(
    graphql`
      fragment authorBioAuthor on Author {
        bio
      }
    `,
    reference_,
  );

  return (
    <StyledDiv $variant={variant} className={className} onClick={onClick}>
      {author.bio}
    </StyledDiv>
  );
};

AuthorBio.displayName = "AuthorBio";
