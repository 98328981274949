import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { articlePrintHeadlineMessageArticle$key } from "scmp-app/queries/__generated__/articlePrintHeadlineMessageArticle.graphql";

import type { ContainerProps } from "./styles";
import { Container } from "./styles";

export type Props = {
  reference: articlePrintHeadlineMessageArticle$key;
  variant?: ContainerProps["$variant"];
};

export const ArticlePrintHeadlineMessage: FunctionComponent<Props> = ({
  reference: reference_,
  variant = "light",
}) => {
  const { printHeadline } = useFragment(
    graphql`
      fragment articlePrintHeadlineMessageArticle on Article {
        printHeadline
      }
    `,
    reference_,
  );

  return printHeadline ? (
    <Container $variant={variant}>
      This article appeared in the South China Morning Post print edition as: {printHeadline}
    </Container>
  ) : null;
};

ArticlePrintHeadlineMessage.displayName = "ArticlePrintHeadlineMessage";
