/**
 * @generated SignedSource<<3aa09ae899ef5a4a00e4fa154f0b06af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleLeadingImagesContent$data = {
  readonly headline: string | null | undefined;
  readonly images: ReadonlyArray<{
    readonly isSlideshow: boolean | null | undefined;
    readonly size1020x680: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1200x800: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1280x720: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size1320w: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size660w: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly size768x768: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly sizeLandscape250x99: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly title: string | null | undefined;
    readonly url: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "articleLeadingImagesContent";
};
export type articleLeadingImagesContent$key = {
  readonly " $data"?: articleLeadingImagesContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleLeadingImagesContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleLeadingImagesContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSlideshow",
          "storageKey": null
        },
        {
          "alias": "size660w",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "660w"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"660w\"})"
        },
        {
          "alias": "size1320w",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "1320w"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"1320w\"})"
        },
        {
          "alias": "sizeLandscape250x99",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "landscape_250_99"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"landscape_250_99\"})"
        },
        {
          "alias": "size1280x720",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "wide_landscape"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"wide_landscape\"})"
        },
        {
          "alias": "size1200x800",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "1200x800"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"1200x800\"})"
        },
        {
          "alias": "size1020x680",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "1020x680"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"1020x680\"})"
        },
        {
          "alias": "size768x768",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "768x768"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "style(filter:{\"style\":\"768x768\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "13186c2c939f77b430e40c5e2fa0727e";

export default node;
