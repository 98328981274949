/**
 * @generated SignedSource<<8fba96f93fc15ccddca7a2a339425cd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type genericPostiesArticleVocabBoxArticle$data = {
  readonly glossary: ReadonlyArray<{
    readonly description: {
      readonly json: Schema | null | undefined;
    } | null | undefined;
    readonly term: string;
    readonly translation: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent">;
  readonly " $fragmentType": "genericPostiesArticleVocabBoxArticle";
};
export type genericPostiesArticleVocabBoxArticle$key = {
  readonly " $data"?: genericPostiesArticleVocabBoxArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"genericPostiesArticleVocabBoxArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "genericPostiesArticleVocabBoxArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Glossary",
      "kind": "LinkedField",
      "name": "glossary",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "term",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "translation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "json",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "a87c3348d4ab317cd0b20a074e318f47";

export default node;
