import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import { StyledImage as AuthorAvatarImage } from "scmp-app/components/author/author-avatar/styles";
import { EntityLink } from "scmp-app/components/entity-link";

type AuthorAvatarProps = {
  $zIndex: number;
};

export const StyledAuthorAvatar = styled(AuthorAvatar, { ...transientOptions })<AuthorAvatarProps>`
  position: relative;
  z-index: ${props => props.$zIndex};

  display: inline-block;

  inline-size: 60px;
  block-size: 60px;
  box-sizing: content-box;

  ${AuthorAvatarImage} {
    padding: 1px;
  }
`;

type ContainerProps = {
  $overlap?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${StyledAuthorAvatar}:not(:first-child) {
    margin-inline-start: ${props => (props.$overlap ? "-16px" : "2px")};
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  line-height: 0;
`;
