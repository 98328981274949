import type { ShareButtonProps } from "@product/scmp-sdk";
import { findFirstNonEmptyString } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { copyLinkArticle20CopyLinkContent$key } from "scmp-app/queries/__generated__/copyLinkArticle20CopyLinkContent.graphql";

import { StyledShareButton } from "./styles";

export type Props = Pick<ShareButtonProps, "position"> & {
  className?: string;
  reference: copyLinkArticle20CopyLinkContent$key;
};

export const Article20CopyLink: FunctionComponent<Props> = ({ className, position, reference }) => {
  const content = useFragment(
    graphql`
      fragment copyLinkArticle20CopyLinkContent on Content {
        entityId
        urlAlias
        shortUrl
        headline
        socialHeadline
      }
    `,
    reference,
  );

  return (
    <StyledShareButton
      className={className}
      position={position}
      relativeUrl={content.urlAlias}
      shortUrl={content.shortUrl ?? ""}
      title={findFirstNonEmptyString(content.socialHeadline, content.headline)}
      type="copy-link"
      utm={{
        campaign: content.entityId,
        medium: "share_widget",
      }}
    />
  );
};

Article20CopyLink.displayName = "Article20CopyLink";
