import type { ShareButtonProps } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import type { popupArticle20ShareMenuContent$key } from "scmp-app/queries/__generated__/popupArticle20ShareMenuContent.graphql";

import { Header, StyledArticleShareWidget, StyledPaper } from "./styles";

export type Props = Pick<ShareButtonProps, "position"> & {
  className?: string;
  reference: popupArticle20ShareMenuContent$key;
};

export const Article20ShareMenuPopup = forwardRef<HTMLDivElement, Props>(
  ({ className, position, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment popupArticle20ShareMenuContent on Content {
          ...articleShareWidgetArticle
        }
      `,
      reference_,
    );

    return (
      <StyledPaper className={className} ref={reference}>
        <Header>Share</Header>
        <StyledArticleShareWidget position={position} reference={content} />
      </StyledPaper>
    );
  },
);

Article20ShareMenuPopup.displayName = "Article20ShareMenuPopup";
