import classnames from "classnames";
import { forwardRef } from "react";

import { Loading } from "scmp-app/components/common/loading";

import { ArchiveWallContainerClass, PianoMeteringContainerClass } from "./consts";
import { Container, LoadingContainer, Wrapper } from "./styles";

type Props = {
  className?: string;
  isShowInlinePaywall?: boolean;
};

/**
 * This component is for showing inline paywall, ArchiveWallContainerClass is the class config in piano
 * It will replace the content in all element with class ArchiveWallContainerClass, so we need to remove the class once loaded the content
 * isShowPaywall will be undefined in the beginning and set the value (true / false) after piano executed
 */
export const ArticlePaywallContainer = forwardRef<HTMLDivElement, Props>(
  ({ className, isShowInlinePaywall }, domReference) => (
    <Container
      $isShowPaywall={isShowInlinePaywall}
      className={classnames(className, PianoMeteringContainerClass)}
    >
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
      <Wrapper
        className={isShowInlinePaywall === undefined ? ArchiveWallContainerClass : ""}
        ref={domReference}
      />
    </Container>
  ),
);

ArticlePaywallContainer.displayName = "ArticlePaywallContainer";
