/**
 * @generated SignedSource<<10784d528384e38b4b560cf140af7dd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shareContentUnlockedArticle$data = {
  readonly entityId: string;
  readonly " $fragmentType": "shareContentUnlockedArticle";
};
export type shareContentUnlockedArticle$key = {
  readonly " $data"?: shareContentUnlockedArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"shareContentUnlockedArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "shareContentUnlockedArticle"
};

(node as any).hash = "358c13585c970d13a89c3dbba8f41530";

export default node;
