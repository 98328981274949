import { useAtomValue } from "jotai";
import type { FunctionComponent, ReactNode } from "react";
import { useEffect, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { articlePianoPaywallContent$key } from "scmp-app/queries/__generated__/articlePianoPaywallContent.graphql";

import { usePaywallPromise } from "./hooks";

type Props = {
  children?: ReactNode;
  reference: articlePianoPaywallContent$key;
};

export const PianoPaywall: FunctionComponent<Props> = ({ children, reference }) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const content = useFragment(
    graphql`
      fragment articlePianoPaywallContent on Content {
        ...hooksPaywallPromiseContent
      }
    `,
    reference,
  );

  const paywallState = usePaywallPromise(content);

  const isShowInlinePaywall = useMemo(
    () => paywallState === "requiresInlinePaywall",
    [paywallState],
  );

  useEffect(() => {
    if (!isShowInlinePaywall || asyncRosettaState?.status === "loading") return;
    // TODO: handle show inline paywall offer at here
    // Mainly for tracking
  }, [asyncRosettaState?.status, isShowInlinePaywall]);

  if (!children || isShowInlinePaywall) return null;
  return <>{children}</>;
};

PianoPaywall.displayName = "PianoPaywall";
