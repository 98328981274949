import { notEmpty } from "@product/scmp-sdk";
import take from "lodash/take";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AuthorAvatarProps } from "scmp-app/components/author/author-avatar";
import type { authorAvatarsArticle$key } from "scmp-app/queries/__generated__/authorAvatarsArticle.graphql";

import { Container, StyledAuthorAvatar, StyledEntityLink } from "./styles";

type Props = Partial<
  Pick<AuthorAvatarProps, "height" | "onClick" | "placeholder" | "width" | "withoutLink">
> & {
  className?: string;
  firstNthAuthor?: number;
  overlap?: boolean;
  reference: authorAvatarsArticle$key;
};

export const ArticleAuthorAvatars: FunctionComponent<Props> = ({
  className,
  firstNthAuthor = 5,
  overlap = true,
  reference,
  ...avatarPops
}) => {
  const article = useFragment(
    graphql`
      fragment authorAvatarsArticle on Article {
        authors {
          entityId
          ...entityLink
          ...authorAvatarAuthor
          ...entityFollowButtonBase
        }
      }
    `,
    reference,
  );

  const authors = firstNthAuthor ? take(article?.authors, firstNthAuthor) : article?.authors;

  return (
    <Container $overlap={overlap} className={className}>
      {authors?.filter(notEmpty)?.map((author, index, array) => (
        <StyledEntityLink key={author.entityId} reference={author}>
          <StyledAuthorAvatar
            {...avatarPops}
            $zIndex={array.length - index}
            height={60}
            reference={author}
            width={60}
          />
        </StyledEntityLink>
      ))}
    </Container>
  );
};

ArticleAuthorAvatars.displayName = "ArticleAuthorAvatars";
