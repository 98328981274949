import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemArticleSeriesCarousel } from "scmp-app/components/content/content-item-render/variants/article-series-carousel";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { carouselArticleSeriesCarouselArticle$key } from "scmp-app/queries/__generated__/carouselArticleSeriesCarouselArticle.graphql";

import {
  CarouselContainer,
  Container,
  HeaderContainer,
  Headline,
  StyledEntityLink,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: carouselArticleSeriesCarouselArticle$key;
};

export const ArticleSeriesCarousel: FunctionComponent<Props> = ({ className, reference }) => {
  const article = useFragment(
    graphql`
      fragment carouselArticleSeriesCarouselArticle on Article {
        entityId
        seriesContainer {
          entityId
          headline
          socialHeadline
          urlAlias
          series {
            ... on Content {
              entityId
              urlAlias
              ...articleSeriesCarouselContent
            }
          }
          ...entityLink
        }
      }
    `,
    reference,
  );

  const items = useMemo(
    () => article.seriesContainer?.series?.filter(notEmpty) ?? [],
    [article.seriesContainer?.series],
  );

  const handleClickTracking = useCallback((articleId?: string, url?: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: articleId,
        destination_url: url,
        page_type: "article",
        widget_name: "series_recirculation",
      },
      subcategory: "widget",
    });
  }, []);

  return (
    <Container className={className}>
      <HeaderContainer>
        <Title>SCMP Series</Title>
        <Headline>
          {article.seriesContainer && (
            <StyledEntityLink
              onClick={() =>
                handleClickTracking(
                  article.seriesContainer?.entityId,
                  article.seriesContainer?.urlAlias,
                )
              }
              reference={article.seriesContainer}
            >
              {findFirstNonEmptyString(
                article.seriesContainer.socialHeadline,
                article.seriesContainer.headline,
              )}
            </StyledEntityLink>
          )}
        </Headline>
      </HeaderContainer>
      <CarouselContainer>
        {items.map(item => (
          <ContentItemArticleSeriesCarousel
            isCurrent={article.entityId === item.entityId}
            key={item.entityId}
            onClick={() => handleClickTracking(item?.entityId, item?.urlAlias)}
            reference={item}
          />
        ))}
      </CarouselContainer>
    </Container>
  );
};

ArticleSeriesCarousel.displayName = "ArticleSeriesCarousel";
