/**
 * @generated SignedSource<<5630434c4c054e5842a473cda4d51d23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksPostiesArticleArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"genericPostiesArticleContent" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "hooksPostiesArticleArticle";
};
export type hooksPostiesArticleArticle$key = {
  readonly " $data"?: hooksPostiesArticleArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksPostiesArticleArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "customContents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksPostiesArticleArticle",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "bodyType",
          "variableName": "bodyType"
        },
        {
          "kind": "Variable",
          "name": "customContents",
          "variableName": "customContents"
        }
      ],
      "kind": "FragmentSpread",
      "name": "genericPostiesArticleContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "bedc809269df6681b09c0a143d66a30a";

export default node;
