import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { useFetchUserTypes } from "scmp-app/lib/hooks";
import { rosettaAtom } from "scmp-app/lib/rosetta";

export const useMostLikelyToSubscribe = () => {
  const { userTypes } = useFetchUserTypes();
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const pianoIsLikelyToSubscribe = asyncRosettaState?.result?.instance?.rulesEngine.getOutcome(
    "user.isLikelyToSubscribe",
  );

  const isMostLikelyToSubscribeUser = useMemo(
    () => userTypes.map(element => element.id).includes("018") || pianoIsLikelyToSubscribe,
    [pianoIsLikelyToSubscribe, userTypes],
  );

  return {
    isMostLikelyToSubscribeUser,
  };
};
