import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
`;

export const SlotContent = styled.div`
  line-height: 0;

  > div[id$="__container__"] {
    inline-size: 100%;

    text-align: center;
  }
`;
