import styled from "@emotion/styled";

export const Container = styled.div``;

export const Header = styled.div`
  padding-block: 8px 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14.06px;
`;

export const Divider = styled.div`
  inline-size: 100%;
  block-size: 1px;

  background: #333333;
`;

export const AccordionLocationMenu = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const AccordionLocationContainer = styled.div`
  ${AccordionLocationMenu}:not(:last-child) {
    border-block-end: 1px solid #cccccc;
  }
`;
