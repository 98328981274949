import styled from "@emotion/styled";
import { fontFredoka } from "@product/scmp-sdk";

import { shouldForwardProp } from "scmp-app/components/schema-render/content/styles";

export const GenericPostiesArticleTextAndTableContainer = styled.div`
  font-family: ${fontFredoka};
`;

export const GenericPostiesArticleTableSummary = styled("div", { shouldForwardProp })`
  margin-block-end: 24px;

  color: #333333;
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
`;

export const GenericPostiesArticleTableCaption = styled("div", { shouldForwardProp })`
  margin-block-end: 24px;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
`;

export const GenericPostiesArticleTableThead = styled("thead", { shouldForwardProp })`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  background-color: #c5eafb;
`;

export const GenericPostiesArticleTableTr = styled("tr", { shouldForwardProp })`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  &:nth-child(2n) {
    background-color: #f5f7db;
  }
`;
