import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { AuthorCardsContainerProps } from "scmp-app/components/article/article-author-details/styles";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import type { authorCardAuthor$key } from "scmp-app/queries/__generated__/authorCardAuthor.graphql";

import { DefaultAuthorCard } from "./default-author-card";
import { PlusAuthorCard } from "./plus-author-card";
import { StyleAuthorCard } from "./style-author-card";

export type Props = {
  reference: authorCardAuthor$key;
  variant: AuthorCardsContainerProps["$variant"];
};

export const AuthorCard: FunctionComponent<Props> = ({ reference: reference_, variant }) => {
  const author = useFragment(
    graphql`
      fragment authorCardAuthor on Author {
        ...styleAuthorCardAuthor
        ...defaultAuthorCardAuthor
        ...plusAuthorCardAuthor
      }
    `,
    reference_,
  );

  switch (variant) {
    case MagazinesStyleVariant:
      return <StyleAuthorCard reference={author} />;
    case "plus":
      return <PlusAuthorCard reference={author} />;

    default:
      return <DefaultAuthorCard reference={author} />;
  }
};

AuthorCard.displayName = "AuthorCard";
