import { findFirstNonEmptyString } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useContext } from "react";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

import { CaptionContainer, CaptionLabel } from "./styles";

export const FooterCaption: FunctionComponent = () => {
  const { currentSlice } = useContext(GalleryContext);

  return (
    <CaptionContainer>
      <CaptionLabel>
        {findFirstNonEmptyString(currentSlice?.data?.caption as string, currentSlice?.data?.alt)}
      </CaptionLabel>
    </CaptionContainer>
  );
};

FooterCaption.displayName = "FooterCaption";
