/**
 * @generated SignedSource<<35b4ddf98815a9a98966ad2748f93cdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type geoLocationMainLocationArticle$data = {
  readonly entityId: string;
  readonly entityUuid: string;
  readonly firstTopic: {
    readonly contents: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId: string;
          readonly " $fragmentSpreads": FragmentRefs<"articleWidgetContent">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "geoLocationMainLocationArticle";
};
export type geoLocationMainLocationArticle$key = {
  readonly " $data"?: geoLocationMainLocationArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"geoLocationMainLocationArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "geoLocationMainLocationArticle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityUuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "firstTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": {
                "direction": "DESC",
                "field": "PUBLISHED_DATE"
              }
            }
          ],
          "concreteType": "ContentConnection",
          "kind": "LinkedField",
          "name": "contents",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "articleWidgetContent"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "contents(first:4,orderBy:{\"direction\":\"DESC\",\"field\":\"PUBLISHED_DATE\"})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "fb1ab7eb20a5f899d379bb093439e841";

export default node;
