import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontAngkor, fontFredoka, theme } from "@product/scmp-sdk";

import { Button } from "scmp-app/components/posties/button";

import PlayIcon from "./icon-play.svg";
import LineImg from "./img-line.svg";

export const StyledLineImg = styled(LineImg)`
  display: none;

  inline-size: 100%;
  ${theme.breakpoints.up("desktop")} {
    display: block;
  }
`;

export const StyledPlayIcon = styled(PlayIcon)`
  margin-inline-end: 6px;
`;

type StyledButtonProps = {
  $isDisabled: boolean;
};
export const StyledButton = styled(Button)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 160px;
  ${theme.breakpoints.up("desktop")} {
    inline-size: 151px;
  }

  ${props =>
    props.$isDisabled &&
    css`
      cursor: initial;
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  text-align: center;

  ${theme.breakpoints.up("desktop")} {
    gap: 24px;

    text-align: start;
  }
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  max-inline-size: 440px;
  padding-block: 40px 16px;
  padding-inline: 17px;

  border: 2px solid #555555;
  border-radius: 15px;

  background: #fafafa;

  ${theme.breakpoints.up("desktop")} {
    inline-size: 192px;
    padding-block: 70px 32px;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    inline-size: 280px;
  }

  ${TextContainer} {
    padding-block-end: 16px;

    ${theme.breakpoints.up("desktop")} {
      padding-block-end: 24px;
    }
  }
`;

export const Pippa = styled.div``;

export const Title = styled.div`
  color: #ff7c1d;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontAngkor};
  line-height: 20px;
  letter-spacing: -0.2px;
  text-transform: uppercase;

  ${theme.breakpoints.up("desktop")} {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
`;

export const Description = styled.div`
  color: #777777;
  font-weight: 500;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 20px;

  ${theme.breakpoints.up("desktop")} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PippaImage = styled.img`
  position: absolute;
  inset-block-start: -38.5px;
  inset-inline-start: 50%;

  inline-size: 100px;
  max-inline-size: 60px;

  transform: translateX(-50%);
  ${theme.breakpoints.up("desktop")} {
    inset-block-start: -54.5px;

    max-inline-size: none;
  }
`;
