import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { fontRoboto } from "@product/scmp-sdk";

import { ArticleShareWidget } from "scmp-app/components/article/article-share-widget";

export const StyledArticleShareWidget = styled(ArticleShareWidget)`
  gap: 16px;

  padding-inline: 20px;

  svg {
    path:last-child {
      fill: #000000;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  inline-size: 200px;
  margin-block: 8px;
  padding-block: 20px;

  border: 1px solid #000000;
  border-radius: 4px;

  background-color: #ffffff;
`;

export const Header = styled.div`
  margin-block: 4px 12px;
  padding-inline: 20px;

  color: #000000;
  font-weight: 700;
  font-size: 20px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;
`;
