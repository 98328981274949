/**
 * @generated SignedSource<<cfaae081292f7e880e6fe72753a9c7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articlePrintHeadlineMessageArticle$data = {
  readonly printHeadline: string | null | undefined;
  readonly " $fragmentType": "articlePrintHeadlineMessageArticle";
};
export type articlePrintHeadlineMessageArticle$key = {
  readonly " $data"?: articlePrintHeadlineMessageArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlePrintHeadlineMessageArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articlePrintHeadlineMessageArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printHeadline",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "cb944b1577351d00c33f13dab41eb87f";

export default node;
