import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Fade from "@mui/material/Fade";
import { fontRoboto } from "@product/scmp-sdk";

import { ContentSchemaRender } from "scmp-app/components/schema-render/content";

type Props = {
  $isToggle: boolean;
};

export const LatestCorrectionDateContainer = styled.div<Props>`
  cursor: pointer;
  &:after {
    position: absolute;
    content: "";

    border-inline-end: 5px solid transparent;
    border-block-end: 5px solid #4585ff;
    border-inline-start: 5px solid transparent;

    margin-block-start: 8px;
    margin-inline-start: 7px;

    transform: rotate(180deg);
    ${props => {
      if (!props.$isToggle) return;
      return css`
        transform: rotate(0deg);
      `;
    }}
  }
`;

export const StyledFade = styled(Fade)``;

export const LatestCorrectionDate = styled.u`
  color: #4585ff;

  cursor: pointer;
`;

export const List = styled.ul<Props>`
  display: ${props => (props.$isToggle ? "block" : "none")};

  padding-block: 12px;
  padding-inline: 24px 16px;

  background-color: #ecf3ff;
`;

export const Item = styled.li`
  position: relative;

  &:before {
    position: absolute;
    inset-inline-start: -12px;
    content: "•";

    padding-inline-end: 5px;
  }
`;
export const CorrectionDate = styled.b``;

export const StyledContentSchemaRender = styled(ContentSchemaRender)`
  display: inline;

  margin-inline-start: 5px;
`;

export type ContainerProps = {
  $variant: "dark" | "light";
};

export const Container = styled.div<ContainerProps>`
  font-size: 16px;
  font-family: ${fontRoboto};
  line-height: 19px;

  ${props =>
    props.$variant === "dark" &&
    css`
      ${List} {
        color: #ffffff;

        background-color: #000000;
      }

      ${CorrectionDate} {
        color: #ffffff;
      }
    `}

  ${List} {
    list-style: none;
  }

  ${Item}:not(:last-child) {
    margin-block-end: 12px;
  }
`;
