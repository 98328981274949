import { useDeepCompareMemo } from "@react-hookz/web";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content/index";

import { type Props as InlineAdSlotProps } from ".";
import { InlineAdSlotSchemaType, isInlineAdSlotSchema } from "./schemas";
import { StyledInlineAdSlot } from "./styles";

export type InlineAdSlotRenderProps = Partial<Record<string, InlineAdSlotProps["slot"]>>;

export const useInlineAdSlotRender = (entityId: string, inlineAdSlots: InlineAdSlotRenderProps) => {
  const inlineAdSlotRenders = useDeepCompareMemo(
    () => ({
      [InlineAdSlotSchemaType]: ({ schemaNode }: ContentSchemaRenderProps) => {
        const isInlineAdSlotNode = isInlineAdSlotSchema(schemaNode);
        if (!isInlineAdSlotNode) return null;

        const matchedAdSlot = inlineAdSlots[schemaNode.attribs?.tag];
        if (!matchedAdSlot) return null;
        return <StyledInlineAdSlot entityId={entityId} slot={matchedAdSlot} />;
      },
    }),
    [inlineAdSlots],
  );

  return { inlineAdSlotRenders };
};
