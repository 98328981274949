/**
 * @generated SignedSource<<75deb90f9e1a89e194f35a4cd2a75ede>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleSpeechPlayerPortalArticle$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleSpeechInlineWidgetArticle">;
  readonly " $fragmentType": "articleSpeechPlayerPortalArticle";
};
export type articleSpeechPlayerPortalArticle$key = {
  readonly " $data"?: articleSpeechPlayerPortalArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSpeechPlayerPortalArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleSpeechPlayerPortalArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSpeechInlineWidgetArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "88109fec692c187c8d3e01081ee1232f";

export default node;
