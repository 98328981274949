import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BeforeYouGoActionWidgets } from "./before-you-go-action-widget";

export const StyledBeforeYouGoActionWidgets = styled(BeforeYouGoActionWidgets)`
  margin-block-start: 16px;
`;

export const Title = styled.div`
  margin-block-end: 12px;

  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  inline-size: 100%;
  block-size: 1px;
  margin-block: 16px;

  background: #333333;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  padding: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  background-color: #ffffff;

  ${theme.breakpoints.down("desktop")} {
    inline-size: 260px;
    block-size: 100%;
    min-inline-size: 260px;
  }
`;
