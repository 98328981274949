import addDays from "date-fns/addDays";
import { graphql, readInlineData } from "react-relay";

import { useArticlePaywallUnlockContext } from "scmp-app/components/article/article-paywall-unlock/contexts";
import { useScmpWidgetConfig } from "scmp-app/lib/app/hooks";
import type { shareContentUnlockedArticle$key } from "scmp-app/queries/__generated__/shareContentUnlockedArticle.graphql";

export const useShareContentUnlocked = (article_: shareContentUnlockedArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment shareContentUnlockedArticle on Article @inline {
        entityId
      }
    `,
    article_,
  );
  const { scmpWidgetConfigState } = useScmpWidgetConfig();
  const { shareContentUnlocked } = useArticlePaywallUnlockContext();

  if (shareContentUnlocked) {
    const shareArticleExpiresDay =
      Number.parseInt(scmpWidgetConfigState.result?.share_article_expires_day ?? "") ?? 0;

    return (
      shareContentUnlocked?.entityId === article.entityId &&
      addDays(new Date(shareContentUnlocked?.shareTime), shareArticleExpiresDay) > new Date()
    );
  }

  return false;
};
