// cspell:  ignore Artilce
import isString from "lodash/isString";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { graphql, useFragment } from "react-relay";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useIntersection } from "scmp-app/lib/hooks";
import type { appButtonContent$key } from "scmp-app/queries/__generated__/appButtonContent.graphql";

import { StyledButton, StyledSmartphoneIcon } from "./styles";

type Props = {
  reference: appButtonContent$key;
};

export const AppButton: FunctionComponent<Props> = ({ reference, ...attribs }) => {
  const content = useFragment(
    graphql`
      fragment appButtonContent on Content {
        urlAlias
      }
    `,
    reference,
  );

  const { captureIntersectionElement, intersection } = useIntersection({
    threshold: 0.45,
  });

  const [isSendImpression, setIsSendImpression] = useState(false);
  const router = useRouter();
  const getDevice = () => {
    if (isIOS) return "ios";
    if (isAndroid) return "android";

    return "";
  };

  useEffect(() => {
    if (!intersection?.isIntersecting || isSendImpression) return;
    setIsSendImpression(true);
  }, [intersection, isSendImpression]);

  return (
    <StyledButton
      noStyle
      ref={captureIntersectionElement}
      {...attribs}
      onClick={() => {
        const oneLinkArticle = new URL("https://scmp.onelink.me/3586748601/11c774a");
        oneLinkArticle.searchParams.set("deep_link_value", content.urlAlias);
        if (isString(router?.query?.share)) {
          oneLinkArticle.searchParams.set("share", router.query.share);
        }
        window.location.href = oneLinkArticle.toString();

        sendGA4Tracking({
          action: "click",
          category: "appdl",
          customized_parameters: {
            click_type: "redirect",
            os: getDevice(),
            page_type: "article",
            position: "read_full_article",
          },
          subcategory: "",
        });
      }}
    >
      App
      <StyledSmartphoneIcon />
    </StyledButton>
  );
};

AppButton.displayName = "AppButton";
