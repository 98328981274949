import styled from "@emotion/styled";

import { ContentSchemaRender } from "scmp-app/components/schema-render/content";

export const StyledVocabBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-inline-size: 564px;
  padding-block: 40px 32px;
  padding-inline: 16px;

  border: 2px solid #000000;
`;

export const StyledVocab = styled.div``;

export const StyledTerm = styled.div`
  display: inline;

  color: rgb(48, 48, 48);
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  background-color: rgb(159, 255, 216);
`;

export const StyledTranslation = styled.div`
  font-size: 18px;
  line-height: 30px;
`;

export const StyledContentSchemaRender = styled(ContentSchemaRender)`
  font-size: 16px;
  line-height: 22px;
`;
