import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { setFullViewportWidthStyles } from "shared/lib/styles";

type ContainerProps = {
  $isShowPaywall?: boolean;
};

export const LoadingContainer = styled.div`
  position: absolute;

  display: flex;
  place-content: center center;
  align-items: center;

  inline-size: 100%;
  block-size: 100%;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;

  display: none;

  min-block-size: 530px;

  ${props =>
    props.$isShowPaywall === true &&
    css`
      display: flex;

      ${LoadingContainer} {
        display: none;
      }
    `}

  ${theme.breakpoints.only("mobile")} {
    ${setFullViewportWidthStyles()}
  }
`;

export const Wrapper = styled.div`
  inline-size: 100%;
  block-size: 100%;
`;
