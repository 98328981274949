import { theme } from "@product/scmp-sdk";
import classnames from "classnames";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";
import { memo, useCallback, useEffect } from "react";

import { config } from "shared/data";

import {
  getClassNameFromSize,
  getSizeFromRenderedInfo,
} from "scmp-app/components/advertisement/ad-slots/helpers";
import {
  useAdProfile,
  useAdUnitPath,
  useUserRoleTarget,
} from "scmp-app/components/advertisement/ad-slots/hooks";
import type { OutstreamAdUnit, Targeting } from "scmp-app/components/advertisement/ad-slots/types";
import * as aps from "scmp-app/components/advertisement/amazon-publisher-service";
import * as gpt from "scmp-app/components/advertisement/google-publisher-tag/hooks";
import { magnitePrebidJSAtom } from "scmp-app/components/advertisement/magnite-demand-manager/atoms";
import * as md from "scmp-app/components/advertisement/magnite-demand-manager/hooks";

import { SlotContent } from "./styles";

export type Props = {
  adUnit: OutstreamAdUnit;
  className?: string;
  divId?: string;
  targeting?: Targeting;
  zone?: null | string;
};
const Component: FunctionComponent<Props> = ({ adUnit, className, divId, targeting, zone }) => {
  const adUnitPath = useAdUnitPath(adUnit, zone);
  const userRoleTarget = useUserRoleTarget();
  const { adProfile } = useAdProfile();

  const outstreamSizeMappingBuilder = useCallback(
    (builder: googletag.SizeMappingBuilder) =>
      builder
        .addSize(
          [theme.breakpoints.values.xLargeDesktop, 0],
          [
            [1, 1],
            [640, 360],
            [640, 480],
          ],
        )
        .addSize(
          [theme.breakpoints.values.mediumDesktop, 0],
          [
            [1, 1],
            [480, 360],
            [480, 270],
          ],
        )
        .addSize(
          [theme.breakpoints.values.desktop, 0],
          [
            [1, 1],
            [320, 180],
          ],
        )
        .addSize(
          [theme.breakpoints.values.tablet, 0],
          [
            [1, 1],
            [480, 360],
            [480, 270],
          ],
        )
        .addSize(
          [0, 0],
          [
            [1, 1],
            [320, 180],
          ],
        )
        .build(),
    [],
  );

  const slot = gpt.useDefineSlot(
    adUnitPath,
    [
      [1, 1],
      [640, 480],
      [480, 360],
      [640, 360],
      [320, 180],
    ],
    {
      ...targeting,
      ...userRoleTarget,
      adProfile,
      ...md.useTargeting(adUnit),
    },
    { divId, sizeMappingBuilder: outstreamSizeMappingBuilder },
  );
  const slotInfo = gpt.useRenderSlotInfo(slot);

  const { isReady: isMagnitePrebidJSReady } = useAtomValue(magnitePrebidJSAtom);
  const { handleFetchBids: handleFetchMdBids } = md.useFetchBids(slot);

  useEffect(() => {
    if (!isMagnitePrebidJSReady || !slot) return;

    let hasCancel = false;

    const getFailsafeTimeoutPromise = () =>
      new Promise<void>(resolve => {
        setTimeout(resolve, config.advertisement.failsafeTimeout);
      });

    const apsResponse = aps.fetchBids([
      {
        mediaType: "multi-format",
        multiFormatProperties: {
          display: { sizes: [[300, 250]] },
          video: { sizes: [[640, 480]] },
        },
        slotID: slot.getSlotElementId(),
        slotName: slot.getAdUnitPath(),
      },
    ]);

    const run = async () => {
      await Promise.race([
        Promise.all([apsResponse.result, handleFetchMdBids()]),
        getFailsafeTimeoutPromise(),
      ]);
      if (!hasCancel) {
        googletag.cmd.push(function () {
          googletag.pubads().refresh([slot]);
        });
      }
    };
    void run();

    return () => {
      hasCancel = true;
    };
  }, [handleFetchMdBids, isMagnitePrebidJSReady, slot]);

  return (
    <SlotContent
      className={classnames(className, getClassNameFromSize(getSizeFromRenderedInfo(slotInfo)))}
      id={slot?.getSlotElementId()}
    />
  );
};

Component.displayName = "Slot";
export const Slot = memo(Component);
