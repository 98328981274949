import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";

import type { articleGeoLocationWidget$key } from "scmp-app/queries/__generated__/articleGeoLocationWidget.graphql";

import { GeoLocationContinentList } from "./geo-location-continent-list";
import { GeoLocationMainLocationArticle } from "./geo-location-main-location-article";
import { Container, Header, OuterContainer } from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
  reference: articleGeoLocationWidget$key;
};

export const ArticleGeoLocationWidget: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment articleGeoLocationWidget on Article {
        topics {
          types
        }
        ...geoLocationMainLocationArticle
      }
    `,
    reference_,
  );

  const hasMainLocation =
    data.topics?.some(topic => topic?.types?.includes("place_locations")) ?? false;

  if (!hasMainLocation) return null;

  return (
    <OuterContainer className={className}>
      <BaseLinkContextProvider
        customQueryParameters={{
          module: "location_widget",
          pgtype: "article",
        }}
      >
        <Container>
          <Header>discover more stories from</Header>
          <GeoLocationMainLocationArticle reference={data} />
          <GeoLocationContinentList />
        </Container>
      </BaseLinkContextProvider>
    </OuterContainer>
  );
};

ArticleGeoLocationWidget.displayName = "ArticleGeoLocationWidget";
