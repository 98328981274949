import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, theme } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

const textStyle = css`
  color: #2c4692;
  font-size: 14px;
  font-family: ${fontMerriweather};
  font-style: italic;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;

  ${theme.breakpoints.up("tablet")} {
    font-size: 15px;
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;

  max-inline-size: 1208px;
  padding: 20px;

  border-radius: 8px;

  background-color: rgba(44, 70, 146, 0.1);
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  align-items: flex-start;

  ${theme.breakpoints.up("tablet")} {
    flex-flow: row nowrap;
  }
`;

export const Title = styled.div`
  font-weight: 400;
  white-space: nowrap;

  border-block-end: 1px dashed #2c4692;

  ${textStyle}
`;

export const Headline = styled.div`
  ${StyledEntityLink} {
    font-weight: 700;

    ${textStyle}
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;

  overflow: auto hidden;
`;
