import { useMemo } from "react";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content/index";

import { isTrackViewedPercentageSchema, TrackViewedPercentageSchemaType } from "./schemas";
import { TrackPercentageBlock } from "./styles";

export const useTrackViewedPercentageRender = () => {
  const trackViewedPercentageRenders = useMemo(
    () => ({
      [TrackViewedPercentageSchemaType]: ({ schemaNode }: ContentSchemaRenderProps) => {
        if (!isTrackViewedPercentageSchema(schemaNode)) return null;

        return (
          <TrackPercentageBlock
            className={`js-reading-${schemaNode.attribs.percentage}-percent-completion-tracker`}
          />
        );
      },
    }),
    [],
  );

  return {
    trackViewedPercentageRenders,
  };
};
