/**
 * @generated SignedSource<<92578461b4dedd35b86afd0e6967325f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleTopicContent$data = {
  readonly topics: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"topicLinkTopic">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "articleTopicContent";
};
export type articleTopicContent$key = {
  readonly " $data"?: articleTopicContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleTopicContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleTopicContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicLinkTopic"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "ecb229edb6be9bcf56dc6d59c4b86d81";

export default node;
