import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

import {
  H1Container as ContentHeadlineH1Container,
  H2Container as ContentHeadlineH2Container,
} from "scmp-app/components/content/content-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;

  column-gap: 12px;
`;

export const Headline = styled.div`
  flex: 1 1 auto;

  font-size: 13px;
  line-height: 140%;

  ${ContentHeadlineH1Container}, ${ContentHeadlineH2Container} {
    padding: 0;

    color: #000000;
    font-weight: 700;
    font-size: 13px;
    font-family: ${fontMerriweather};
    font-style: normal;
    line-height: 140%;
  }

  && ${StyledEntityLink} {
    text-decoration: none;
  }
`;

export const CoverImage = styled.div`
  min-inline-size: 90px;
  max-inline-size: 90px;
  min-block-size: 60px;
  max-block-size: 60px;
`;
