import { findFirstNonEmptyString } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { Share } from "scmp-app/components/article/article-2.0-action-widget/share";
import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";
import type { beforeYouGoActionWidgetContent$key } from "scmp-app/queries/__generated__/beforeYouGoActionWidgetContent.graphql";

import { Container, StyledIconCopy, StyledShareButton } from "./styles";

type Props = {
  className?: string;
  provider: DefaultComponentsProvider;
  reference: beforeYouGoActionWidgetContent$key;
};

export const BeforeYouGoActionWidgets: FunctionComponent<Props> = ({
  className,
  provider,
  reference,
}) => {
  const article = useFragment(
    graphql`
      fragment beforeYouGoActionWidgetContent on Content {
        ...shareArticle20Content
        ...articleShareWidgetArticle
        entityId
        urlAlias
        shortUrl
        headline
        socialHeadline
      }
    `,
    reference,
  );

  return (
    <Container className={className}>
      <Share position="article_bottom" reference={article} />
      <StyledShareButton
        extraProps={{
          copyLink: {
            tooltipPlacement: "top",
            variant: "tooltip",
          },
          overrideIcons: {
            "copy-link": StyledIconCopy,
          },
        }}
        position="article_bottom"
        relativeUrl={article.urlAlias}
        shortUrl={article.shortUrl}
        style={{
          /* stylelint-disable */
          backgroundColor: "transparent",
          color: "#000000",
          mobilePadding: 0,
          padding: 0,
          size: 36,
          /* stylelint-enable */
        }}
        title={findFirstNonEmptyString(article.socialHeadline, article.headline)}
        type="copy-link"
        utm={{
          campaign: article.entityId,
          medium: "share_widget",
        }}
      />
      {provider.actionWidget({ variant: "article-2.0-before-you-go" })}
    </Container>
  );
};
