// This component should support image size styles so possibly some of the styles will not be used.

import { findFirstNonEmptyString } from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { ResponsiveVariants } from "shared/lib/styles";

import type { Props as ArticleImageProps } from "scmp-app/components/article/article-image";
import { ArticleImage } from "scmp-app/components/article/article-image";
import type { Props as BaseImageProps } from "scmp-app/components/common/base-image";
import { useResponsiveImage } from "scmp-app/components/common/base-image/hooks";
import { useImageUtils } from "scmp-app/lib/hooks";
import type { articleLeadingImagesContent$key } from "scmp-app/queries/__generated__/articleLeadingImagesContent.graphql";

import { ArticleLeadingImageContainer } from "./styles";

export type Props = Pick<
  ArticleImageProps,
  "responsiveDescriptionVariants" | "responsiveImageVariants" | "skipGallery"
> & {
  className?: string;
  imageProps: Omit<BaseImageProps, "$aspectRatio">;
  reference: articleLeadingImagesContent$key;
  resizeImageWidth?: number;
  responsiveVariants: ResponsiveVariants<
    | "size660w"
    | "size768x768"
    | "size1020x680"
    | "size1200x800"
    | "size1280x720"
    | "size1320w"
    | "sizeLandscape250x99"
  >;
  showTitle?: boolean;
  toggleTitle?: boolean;
};

export const ArticleLeadingImages: FunctionComponent<Props> = ({
  className,
  imageProps,
  reference: reference_,
  resizeImageWidth,
  responsiveVariants,
  showTitle = true,
  toggleTitle = false,
  ...articleImageProps
}) => {
  const content = useFragment(
    graphql`
      fragment articleLeadingImagesContent on Content {
        headline
        images {
          title
          url
          isSlideshow
          size660w: style(filter: { style: "660w" }) {
            url
          }
          size1320w: style(filter: { style: "1320w" }) {
            url
          }
          sizeLandscape250x99: style(filter: { style: "landscape_250_99" }) {
            url
          }
          size1280x720: style(filter: { style: "wide_landscape" }) {
            url
          }
          size1200x800: style(filter: { style: "1200x800" }) {
            url
          }
          size1020x680: style(filter: { style: "1020x680" }) {
            url
          }
          size768x768: style(filter: { style: "768x768" }) {
            url
          }
        }
      }
    `,
    reference_,
  );
  const { getResizedImageUrl } = useImageUtils();

  // TODO: support images (Carousel)
  const mainImage = content.images?.find(image => image?.isSlideshow) ?? first(content.images);

  const variantSpecs = {
    size660w: { aspectRatio: "auto", url: mainImage?.size660w?.url, width: 1320 },
    size768x768: { aspectRatio: "1/1", url: mainImage?.size768x768?.url, width: 768 },
    size1020x680: { aspectRatio: "3/2", url: mainImage?.size1020x680?.url, width: 1020 },
    size1200x800: { aspectRatio: "3/2", url: mainImage?.size1200x800?.url, width: 1200 },
    size1280x720: { aspectRatio: "16/9", url: mainImage?.size1280x720?.url, width: 1280 },
    size1320w: { aspectRatio: "auto", url: mainImage?.size1320w?.url, width: 1320 },
    sizeLandscape250x99: {
      aspectRatio: "250/99",
      url: mainImage?.sizeLandscape250x99?.url,
      width: 1024,
    },
  };
  const mapVariant = (viewportSize: "desktopUp" | "mobileUp" | "tabletUp") => ({
    aspectRatio: variantSpecs[responsiveVariants[viewportSize]].aspectRatio,
    url: variantSpecs[responsiveVariants[viewportSize]].url ?? "",
    width: variantSpecs[responsiveVariants[viewportSize]].width,
  });

  const responsiveVariant = {
    desktopUp: mapVariant("desktopUp"),
    mobileUp: mapVariant("mobileUp"),
    tabletUp: mapVariant("tabletUp"),
  };

  const { bindResponsiveAspectRatio } = useResponsiveImage(responsiveVariant);
  if (!mainImage) return null;

  const renderImage = () => {
    let imageUrl = mainImage[responsiveVariants.mobileUp]?.url ?? mainImage.url;

    imageUrl = resizeImageWidth ? getResizedImageUrl(imageUrl, resizeImageWidth) : imageUrl;
    return (
      <ArticleImage
        imageProps={{
          alt: findFirstNonEmptyString(mainImage?.title, content.headline),
          ...imageProps,
          $objectFit: "cover",
          responsiveVariant,
          src: imageUrl,
          ...bindResponsiveAspectRatio,
        }}
        title={showTitle ? (mainImage.title ?? "") : ""}
        toggleTitle={toggleTitle}
        {...articleImageProps}
      />
    );
  };
  return (
    <ArticleLeadingImageContainer className={className}>
      {renderImage()}
    </ArticleLeadingImageContainer>
  );
};

ArticleLeadingImages.displayName = "ArticleLeadingImages";
