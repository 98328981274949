/**
 * @generated SignedSource<<baae5098fb214add711098277f5eef01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type authorCardAuthor$data = {
  readonly " $fragmentSpreads": FragmentRefs<"defaultAuthorCardAuthor" | "plusAuthorCardAuthor" | "styleAuthorCardAuthor">;
  readonly " $fragmentType": "authorCardAuthor";
};
export type authorCardAuthor$key = {
  readonly " $data"?: authorCardAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"authorCardAuthor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "authorCardAuthor",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "styleAuthorCardAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "defaultAuthorCardAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "plusAuthorCardAuthor"
    }
  ],
  "type": "Author",
  "abstractKey": null
};

(node as any).hash = "b1f41a4545cca38f11d44d60050f9053";

export default node;
