import styled from "@emotion/styled";

import { Tooltip, tooltipClasses } from "scmp-app/components/common/tooltip";
import { ContentSchemaRender } from "scmp-app/components/schema-render/content";

export const StyledTooltip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    inline-size: 300px;
    padding: 20px;

    color: #222222;

    border: 1px solid #cccccc;
    border-radius: 4px;

    background-color: #f6f6f6;
  }

  & .${tooltipClasses.arrow} {
    color: #f6f6f6;
    font-size: 20px;
    &::before {
      border: 1px solid #cccccc;
    }
  }
`;

export const StyledContentSchemaRender = styled(ContentSchemaRender)`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;
