import { usePrevious } from "@react-hookz/web";
import type PhotoSwipe from "photoswipe";
import type Slide from "photoswipe/dist/types/slide/slide";
import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import type { GalleryProps } from "react-photoswipe-gallery";
import { Gallery as PhotoswipeGallery } from "react-photoswipe-gallery";

import { GalleryContext } from "./contexts";

type Props = GalleryProps & {
  className?: string;
};

export const Gallery: FunctionComponent<Props> = ({
  children,
  className,
  onOpen,
  uiElements,
  ...props
}) => {
  const [instance, setInstance] = useState<PhotoSwipe | undefined>();
  const [rootPortalElement, setRootPortalElement] = useState<HTMLDivElement | undefined>();
  const [toolbarPortalElement, setToolbarPortalElement] = useState<HTMLElement | undefined>();
  const [footerPortalElement, setFooterPortalElement] = useState<HTMLElement | undefined>();
  const [currentSlice, setCurrentSlice] = useState<Slide | undefined>();
  const previousClassName = usePrevious(className);

  useEffect(() => {
    if (!rootPortalElement) return;
    if (previousClassName) {
      rootPortalElement.classList.remove(...previousClassName.split(" "));
    }
    if (className) {
      rootPortalElement.classList.add(...className.split(" "));
    }
  }, [rootPortalElement, className, previousClassName]);

  const context = useMemo(
    () => ({
      currentSlice,
      footerPortalElement,
      instance,
      rootPortalElement,
      toolbarPortalElement,
    }),
    [currentSlice, instance, footerPortalElement, rootPortalElement, toolbarPortalElement],
  );

  return (
    <GalleryContext.Provider value={context}>
      <PhotoswipeGallery
        {...props}
        onBeforeOpen={instance => {
          instance.addFilter("itemData", item => {
            if (item.element?.parentElement?.tagName === "PICTURE") {
              const element = item.element as HTMLImageElement;
              const source = [...(element.parentElement?.children ?? [])].find(
                source =>
                  source.tagName === "SOURCE" &&
                  (source as HTMLSourceElement).srcset === (element.currentSrc ?? element.src),
              ) as HTMLSourceElement;

              if (source) {
                const [a, b] = (source.dataset.aspectRatio ?? "1/1")
                  .split("/")
                  .filter(item => !!item)
                  .map(item => Number.parseFloat(item.trim()));

                item.src = source.srcset;
                item.w = Number.parseInt(source.dataset.width ?? "0", 10);
                item.h = item.w / (a / b);
              }
            }
            return item;
          });
        }}
        onOpen={instance => {
          setInstance(instance);
          setRootPortalElement(instance.element);
          setCurrentSlice(instance.currSlide);

          instance.on("destroy", () => {
            setInstance(undefined);
            setFooterPortalElement(undefined);
            setToolbarPortalElement(undefined);
            setCurrentSlice(undefined);
            setRootPortalElement(undefined);
          });

          instance.on("change", () => {
            setCurrentSlice(instance.currSlide);
          });

          onOpen?.(instance);
        }}
        uiElements={[
          ...(uiElements ?? []),
          {
            appendTo: "bar",
            isButton: false,
            name: "toolbar-portal",
            onInit: element => {
              setToolbarPortalElement(element);
            },
            order: 8,
          },
          {
            appendTo: "root",
            isButton: false,
            name: "footer-portal",
            onInit: element => {
              setFooterPortalElement(element);
            },
            order: 9,
          },
        ]}
      >
        {children}
      </PhotoswipeGallery>
    </GalleryContext.Provider>
  );
};
