/**
 * @generated SignedSource<<37a5911be015114cff273d41222bce0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleBeforeYouGoWidget$data = {
  readonly " $fragmentSpreads": FragmentRefs<"beforeYouGoActionWidgetContent">;
  readonly " $fragmentType": "articleBeforeYouGoWidget";
};
export type articleBeforeYouGoWidget$key = {
  readonly " $data"?: articleBeforeYouGoWidget$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleBeforeYouGoWidget">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleBeforeYouGoWidget",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "beforeYouGoActionWidgetContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "e135abbda8a9e46fb65125c36bbd5a48";

export default node;
