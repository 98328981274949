import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { ArticleSpeechInlineWidgetProps } from "scmp-app/components/article/article-speech/article-speech-inline-widget";
import { ArticleSpeechInlineWidget } from "scmp-app/components/article/article-speech/article-speech-inline-widget";
import { HeaderArticleSpeech } from "scmp-app/components/header/header-article-speech";
import { useCurrentArticleIds } from "scmp-app/lib/current-item";
import type { articleSpeechPlayerPortalArticle$key } from "scmp-app/queries/__generated__/articleSpeechPlayerPortalArticle.graphql";

import { Container } from "./styles";

type Props = Omit<ArticleSpeechInlineWidgetProps, "reference"> & {
  reference: articleSpeechPlayerPortalArticle$key;
};

export const ArticleSpeechPlayerPortal: FunctionComponent<Props> = ({ reference, ...props }) => {
  const article = useFragment(
    graphql`
      fragment articleSpeechPlayerPortalArticle on Article {
        entityId
        ...articleSpeechInlineWidgetArticle
      }
    `,
    reference,
  );

  const { currentArticleId } = useCurrentArticleIds();

  const shouldShow = article.entityId === currentArticleId;

  return (
    <HeaderArticleSpeech>
      <Container $shouldShow={shouldShow}>
        <ArticleSpeechInlineWidget {...props} reference={article} withMinifyText={false} />
      </Container>
    </HeaderArticleSpeech>
  );
};
ArticleSpeechPlayerPortal.displayName = "ArticleSpeechPlayerPortal";
