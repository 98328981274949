import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, fontRoboto, theme, transientOptions } from "@product/scmp-sdk";

import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";

import { CaptionLabel } from "./footer/styles";
import { Gallery } from "./gallery";

import "photoswipe/dist/photoswipe.css";

export type StyledGalleryProps = {
  $variant?: string;
};

export const StyledGallery = styled(Gallery, { ...transientOptions })<StyledGalleryProps>`
  font-size: 24px;
  font-family: ${fontRoboto};

  ${props =>
    props.$variant === MagazinesStyleVariant
      ? css`
          ${CaptionLabel} {
            color: #ffffff;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            font-family: ${fontBarlowSemiCondensed};
            line-height: 20px;

            ${theme.breakpoints.up("tablet")} {
              font-size: 16px;
              line-height: 22px;
            }
          }
        `
      : null}

  .pswp__hide-on-close {
    opacity: 1 !important;
  }
  :hover {
    .pswp__hide-on-close {
      opacity: 1 !important;
    }
  }

  .pswp__top-bar {
    block-size: 44px;

    background-color: rgba(0, 0, 0, 0.3);
  }

  .pswp__counter {
    display: flex;
    justify-content: center;
    align-items: center;

    inline-size: 48px;
    block-size: 44px;
    margin: 0;
  }

  .pswp__preloader {
    block-size: 44px;
  }

  .pswp__toolbar-portal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pswp__footer-portal {
    position: absolute;
    inset-block-end: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    inline-size: 100%;
  }
`;

export const ArrowForwardSVGString = `
<svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.3" height="32" width="32" fill="#000000"/>
  <path
    fill="var(--pswp-icon-color)"
    d="m16,8l-1.41,1.41l5.58,5.59l-12.17,0l0,2l12.17,0l-5.58,5.59l1.41,1.41l8,-8l-8,-8z"
  />
</svg>`;

export const ArrowBackwardSVGString = `
<svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.3" height="32" width="32" fill="#000000"/>
  <path
    fill="var(--pswp-icon-color)"
    d="m24,15l-12.17,0l5.59,-5.59l-1.42,-1.41l-8,8l8,8l1.41,-1.41l-5.58,-5.59l12.17,0l0,-2z"
  />
</svg>`;
