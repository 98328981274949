import type { Currency, PaywallType } from "@product/rosetta-sdk";
import { Paywall } from "@product/rosetta-sdk";
import { useAtomValue } from "jotai";
import { useCallback } from "react";

import { rosettaAtom } from "scmp-app/lib/rosetta";

export const usePaywallStrategies = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const getActivePaywallStrategy = useCallback(
    (paywallType: PaywallType) => {
      if (typeof window === "undefined" || !asyncRosettaState?.result?.instance) {
        return null;
      }

      const paywallStrategies =
        asyncRosettaState?.result.instance?.getRosettaAppConfig()?.paywall?.strategies ?? [];

      const userCurrency = (asyncRosettaState?.result?.instance?.rulesEngine.getOutcome(
        "guest.currency",
      ) || "HKD") as Currency;

      return Paywall.strategies.getApplicablePaywallStrategy({
        filterContext: {
          article: { paywall: paywallType },
          user: { currency: userCurrency, type: asyncRosettaState?.result?.v2?.user?.scmpUserType },
        },
        paywallStrategyConfigs: paywallStrategies,
      });
    },
    [asyncRosettaState],
  );

  return {
    getActivePaywallStrategy,
  };
};
