/**
 * @generated SignedSource<<0a196df75a20678e7cc539270b90a490>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type article20DateContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hooksArticleDateUtilsContent">;
  readonly " $fragmentType": "article20DateContent";
};
export type article20DateContent$key = {
  readonly " $data"?: article20DateContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"article20DateContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "article20DateContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksArticleDateUtilsContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "79b9fab96c1adf8a66e990171d1ca67f";

export default node;
