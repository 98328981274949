/**
 * @generated SignedSource<<ce2d6d181fa213b2883227197aa3efb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleSponsorContent$data = {
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
    readonly sponsor: {
      readonly description: {
        readonly json: Schema | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"sponsorTagSponsor">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentSchemaRenderContent" | "helpersCheckIsMainTopicContent" | "helpersUseContentSponsorContent">;
  readonly " $fragmentType": "articleSponsorContent";
};
export type articleSponsorContent$key = {
  readonly " $data"?: articleSponsorContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSponsorContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "sponsorTagSponsor"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Topic",
    "kind": "LinkedField",
    "name": "topics",
    "plural": true,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleSponsorContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Sponsor",
          "kind": "LinkedField",
          "name": "sponsor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormattedValue",
              "kind": "LinkedField",
              "name": "description",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersUseContentSponsorContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topics",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Sponsor",
                  "kind": "LinkedField",
                  "name": "sponsor",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sponsorType",
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsMainTopicContent",
              "selections": (v2/*: any*/),
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsMainTopicContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "644c521335626ed1cc11704dfbe0edec";

export default node;
