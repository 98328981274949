import type { FunctionComponent, ReactNode } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type { InlineAdSlotRenderProps } from "scmp-app/components/schema-render/common/inline-ad-slot/hooks";
import { useInlineAdSlotRender } from "scmp-app/components/schema-render/common/inline-ad-slot/hooks";
import { useTrackViewedPercentageRender } from "scmp-app/components/schema-render/common/track-viewed-percentage/hooks";
import { ContentSchemaRender } from "scmp-app/components/schema-render/content";
import type { Props as ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import type { contentBodyContent$key } from "scmp-app/queries/__generated__/contentBodyContent.graphql";

import { ContentBodyContainer } from "./styles";

export type Props = Pick<
  ContentSchemaRenderProps,
  "advertising" | "extraComponentMap" | "extraRenderFunctionMap"
> & {
  children?: ReactNode;
  className?: string;
  inlineAdSlots?: InlineAdSlotRenderProps;
  preprocessSchema?: (schema: Schema) => Schema;
  reference: contentBodyContent$key;
};

export const ContentBody: FunctionComponent<Props> = ({
  advertising,
  children,
  className,
  extraComponentMap = {},
  extraRenderFunctionMap = {},
  inlineAdSlots = {},
  preprocessSchema = schema => schema,
  reference: reference_,
  ...attribs
}) => {
  const content = useFragment(
    graphql`
      fragment contentBodyContent on Content
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        entityId
        body(customContents: $customContents, type: $bodyType) {
          json
        }
        ...contentSchemaRenderContent
      }
    `,
    reference_,
  );

  const { inlineAdSlotRenders } = useInlineAdSlotRender(content.entityId, inlineAdSlots);
  const { trackViewedPercentageRenders } = useTrackViewedPercentageRender();

  const schema = useMemo(() => {
    const bodyJson = [...(content?.body?.json ?? [])];
    return bodyJson ? preprocessSchema(bodyJson) : null;
  }, [content?.body?.json, preprocessSchema]);

  if (!content.body?.json || schema?.length === 0) return null;
  return (
    <ContentBodyContainer className={className} {...attribs}>
      <ContentSchemaRender
        advertising={advertising}
        extraComponentMap={{
          ...inlineAdSlotRenders,
          ...trackViewedPercentageRenders,
          ...extraComponentMap,
        }}
        extraRenderFunctionMap={extraRenderFunctionMap}
        reference={content}
        schema={schema}
      />
      {children}
    </ContentBodyContainer>
  );
};

ContentBody.displayName = "ContentBody";
