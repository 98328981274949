/**
 * @generated SignedSource<<5d2387b31cceaa231bee02ca6e2fa91d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articlePianoPaywallContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hooksPaywallPromiseContent">;
  readonly " $fragmentType": "articlePianoPaywallContent";
};
export type articlePianoPaywallContent$key = {
  readonly " $data"?: articlePianoPaywallContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlePianoPaywallContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articlePianoPaywallContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksPaywallPromiseContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "223a79f9295186564b37f6898afb2172";

export default node;
