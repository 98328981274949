/**
 * @generated SignedSource<<6e72ed152fc017962267bb662a7b6cc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleShareWidgetArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentsTriggerArticle" | "articleSpeechInlineWidgetArticle" | "contentShareWidgetContent">;
  readonly " $fragmentType": "articleShareWidgetArticle";
};
export type articleShareWidgetArticle$key = {
  readonly " $data"?: articleShareWidgetArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleShareWidgetArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleShareWidgetArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentShareWidgetContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleCommentsTriggerArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSpeechInlineWidgetArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "c8f01e283d5c7a851a8d422fdd4676af";

export default node;
