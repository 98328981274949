import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { articleSponsorType } from "shared/data/article";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { Props as EntityLinkProps } from "scmp-app/components/entity-link";
import type { articleWidgetContent$key } from "scmp-app/queries/__generated__/articleWidgetContent.graphql";

import { Container, CoverImage, Headline, StyledEntityLink } from "./styles";

type Props = ContentItemRenderVariantProps & {
  className?: string;
  query?: EntityLinkProps["query"];
  reference: articleWidgetContent$key;
  withCoverImage?: boolean;
};

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, query, reference: reference_, withCoverImage }, reference) => {
    const content = useFragment(
      graphql`
        fragment articleWidgetContent on Content {
          entityId
          urlAlias
          ... on Article {
            sponsorType
          }
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(withCoverImage: true, withCoverImageSize540x360: true, withHeadline: true)
        }
      `,
      reference_,
    );

    const providers = useContentItemProviders(content);

    const isSponsoredContent = articleSponsorType.has(content.sponsorType ?? "");

    if (isSponsoredContent) return null;

    return (
      <Container
        className={className}
        onClick={() => onClick?.(content.entityId, content.urlAlias)}
        ref={reference}
      >
        <Headline>
          <StyledEntityLink query={query} reference={content}>
            {providers.headline({ preferSocialHeadline: true })}
          </StyledEntityLink>
        </Headline>
        {withCoverImage && (
          <CoverImage>
            <StyledEntityLink query={query} reference={content}>
              {providers.coverImage({
                responsiveVariants: {
                  desktopUp: "size540x360",
                  mobileUp: "size540x360",
                  tabletUp: "size540x360",
                },
                withCaption: false,
              })}
            </StyledEntityLink>
          </CoverImage>
        )}
      </Container>
    );
  },
);

Component.displayName = "ArticleWidget";

export const ContentItemArticleWidget = withHighlightedHeadline(Component);
