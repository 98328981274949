/**
 * @generated SignedSource<<fba46c6aff0c51e1702162c93e21e53d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articlePrintArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"contentShareWidgetContent">;
  readonly " $fragmentType": "articlePrintArticle";
};
export type articlePrintArticle$key = {
  readonly " $data"?: articlePrintArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlePrintArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articlePrintArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentShareWidgetContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "ccb520d423a9ad67e5b3906469176204";

export default node;
