import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { StyledDiv as AuthorBio } from "scmp-app/components/author/author-bio/styles";
import { StyledEntityLink as AuthorName } from "scmp-app/components/author/author-name/styles";
import { Container as AuthorRole } from "scmp-app/components/author/author-role/styles";
import { SocialLink } from "scmp-app/components/author/author-social-links/styles";

import { AuthorCardContainer } from "./author-card/default-author-card/styles";
import { StyleAuthorCardContainer as PlusAuthorCardContainer } from "./author-card/plus-author-card/styles";
export type AuthorCardsContainerProps = {
  $variant?: "dark" | "light" | "magazines-style" | "plus";
};
export const AuthorCardsContainer = styled.div<AuthorCardsContainerProps>`
  ${AuthorCardContainer} + ${AuthorCardContainer} {
    margin-block-start: 0;
    padding-block-start: 16px;
    border-block-start: 1px solid #cccccc;

    ${theme.breakpoints.up("tablet")} {
      margin-block-start: 16px;
    }
  }

  ${AuthorBio}, ${AuthorName}, ${SocialLink}, ${AuthorRole} {
    ${props =>
      props.$variant === "dark"
        ? css`
            color: #ffffff;

            fill: #ffffff;
          `
        : null}
  }
`;

export const PlusAuthorCardsContainer = styled.div`
  padding: 20px;

  border-radius: 4px;

  background: #c8ced566;

  ${PlusAuthorCardContainer} {
    &:not(:last-child) {
      margin-block-end: 16px;
      padding-block-end: 16px;
      border-block-end: 1px solid #b2b2b2;
    }
  }
`;
