import { findFirstNonEmptyString } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { Dispatch, SetStateAction } from "react";
import { forwardRef, useEffect, useMemo, useTransition } from "react";
import { graphql, useFragment } from "react-relay";

import { ZIndexLayer } from "shared/lib/styles";

import { useArticleRenderContext } from "scmp-app/components/article/article-render/contexts";
import { useEnableGenericInlineWidgetByUserSubscription } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/hooks";
import { useArticleSpeechPlayerController } from "scmp-app/components/article/article-speech/article-speech-player/hooks";
import { useArticleSpeechContext } from "scmp-app/components/article/article-speech/contexts";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { buttonArticleSpeechInlineWidgetGenericButtonArticle$key } from "scmp-app/queries/__generated__/buttonArticleSpeechInlineWidgetGenericButtonArticle.graphql";

import { NumberOfCharacterShow } from "./consts";
import type { HeadphoneIconTheme } from "./headphone-icon";
import { HeadphoneIcon } from "./headphone-icon";
import {
  MainContainer,
  MinifyContainer,
  PausedContainer,
  RegularContainer,
  StyledArrowRightIcon,
  StyledCloseIcon,
  Text,
} from "./styles";

export type Props = {
  entityId: string;
  extendClick?: () => void;
  headphoneIconTheme?: HeadphoneIconTheme;
  isMinify?: boolean;
  keepMinifyText?: boolean;
  reference: buttonArticleSpeechInlineWidgetGenericButtonArticle$key;
  setIsTooltipActive: Dispatch<SetStateAction<boolean>>;
  withMinifyCleanUpButton?: boolean;
  withMinifyText?: boolean;
  zIndex?: string;
};

export const ArticleSpeechInlineWidgetGenericButton = forwardRef<HTMLDivElement, Props>(
  (
    {
      entityId,
      headphoneIconTheme,
      isMinify = false,
      keepMinifyText = false,
      withMinifyCleanUpButton = false,
      withMinifyText = true,
      zIndex = `${ZIndexLayer.Drawer - 1}`,
      ...props
    },
    reference,
  ) => {
    const article = useFragment(
      graphql`
        fragment buttonArticleSpeechInlineWidgetGenericButtonArticle on Article {
          entityId
          socialHeadline
          headline
        }
      `,
      props.reference,
    );

    const { articleSpeechifyPlayer, articleSpeechifyPlayerState } = useArticleSpeechContext();
    const {
      handleCheckIsCurrentArticleLinkedToWidget,
      handleCurrentPlayerUnmountAndCleanUp,
      handleInitNewPlayerAndPlay,
    } = useArticleSpeechPlayerController();
    const { articleElementReference } = useArticleRenderContext();
    const { isWidgetEnabledByUser } = useEnableGenericInlineWidgetByUserSubscription();
    const { percentageReached, setPercentageReached } = useArticleRenderContext();
    const [isLoading, startTransition] = useTransition();

    const isWidgetUnmounted = useMemo(
      () => articleSpeechifyPlayerState === "unmounted",
      [articleSpeechifyPlayerState],
    );

    const isWidgetLoading = useMemo(
      () =>
        (articleSpeechifyPlayerState === "loading" &&
          handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current)) ||
        isLoading,
      [
        articleElementReference,
        articleSpeechifyPlayerState,
        handleCheckIsCurrentArticleLinkedToWidget,
        isLoading,
      ],
    );

    const isWidgetPlaying = useMemo(
      () =>
        articleSpeechifyPlayerState === "playing" &&
        handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current),
      [
        articleElementReference,
        articleSpeechifyPlayerState,
        handleCheckIsCurrentArticleLinkedToWidget,
      ],
    );

    const isWidgetPaused = useMemo(
      () =>
        articleSpeechifyPlayerState === "paused" &&
        handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current),
      [
        articleElementReference,
        articleSpeechifyPlayerState,
        handleCheckIsCurrentArticleLinkedToWidget,
      ],
    );

    const asyncRosettaState = useAtomValue(rosettaAtom);
    const subscriberPhase = asyncRosettaState?.result?.tracking?.ga4.subscriberPhase;

    const handleAnalyticsEventsAndTriggerToolTip = () => {
      sendGA4Tracking({
        action: "imp",
        category: "subs",
        customized_parameters: {
          article_id: article.entityId,
          call_to_action: "subscribe",
          subscriber_phase: subscriberPhase,
          trigger_point: "text_to_speech",
        },
        subcategory: "entry_point",
      });

      props.setIsTooltipActive(true);
    };

    const handleTriggerArticleSpeechPlayer = async () => {
      if (
        !isWidgetUnmounted &&
        handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current)
      )
        return;

      if (articleSpeechifyPlayer.current) await handleCurrentPlayerUnmountAndCleanUp();

      props.extendClick?.();

      await handleInitNewPlayerAndPlay({
        entityId,
        extendedEvents: {
          play: [
            () => {
              setPercentageReached?.(undefined);
            },
          ],
        },
        ga4ActionType: isMinify ? "sidebar_button" : "article_button",
        playerConfig: {
          bottomCTASettings: {
            customText: `${findFirstNonEmptyString(article.socialHeadline, article.headline).slice(
              0,
              NumberOfCharacterShow,
            )}...`,
            linkEnabled: false,
          },
          rootElement: articleElementReference?.current ?? undefined,
        },
        zIndex: String(zIndex),
      });
    };

    const handlePlayClick = () => {
      if (isWidgetLoading || isWidgetPlaying || isWidgetPaused) return;
      startTransition(() => {
        if (isWidgetEnabledByUser) {
          void handleTriggerArticleSpeechPlayer();
        } else {
          handleAnalyticsEventsAndTriggerToolTip();
        }
      });
    };

    useEffect(() => {
      if (
        !handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current) ||
        !percentageReached
      )
        return;
      articleSpeechifyPlayer.current?.minimizeWidget();
    }, [
      articleElementReference,
      articleSpeechifyPlayer,
      handleCheckIsCurrentArticleLinkedToWidget,
      percentageReached,
    ]);

    const handleRenderHeadphoneIcon = () => (
      <HeadphoneIcon
        isLoading={isWidgetLoading}
        isPaused={isWidgetPaused}
        isPlayed={isWidgetPlaying}
        theme={headphoneIconTheme}
      />
    );

    const handleRenderMinifyUI = () => {
      const renderText = () => {
        if (
          !withMinifyText ||
          (!keepMinifyText && (isWidgetLoading || isWidgetPlaying || isWidgetPaused))
        )
          return null;
        if (isWidgetPlaying) return <Text>Listening...</Text>;
        if (isWidgetPaused) return <Text>Paused...</Text>;
        return <Text>Listen</Text>;
      };

      const renderCleanUpButton = () => {
        if (
          !withMinifyCleanUpButton ||
          isWidgetLoading ||
          isWidgetUnmounted ||
          !handleCheckIsCurrentArticleLinkedToWidget(articleElementReference?.current)
        )
          return null;
        return (
          <StyledCloseIcon
            onClick={event => {
              event.stopPropagation();
              void handleCurrentPlayerUnmountAndCleanUp?.();
            }}
          />
        );
      };

      return (
        <MinifyContainer $withMinifyText={withMinifyText} onClick={() => handlePlayClick()}>
          {renderText()}
          {handleRenderHeadphoneIcon()}
          {renderCleanUpButton()}
        </MinifyContainer>
      );
    };

    const handleRenderRegularUI = () => {
      if (isWidgetLoading) {
        return <PausedContainer>{handleRenderHeadphoneIcon()}</PausedContainer>;
      }

      const renderText = () => {
        if (isWidgetPlaying) return <Text>Listening...</Text>;
        if (isWidgetPaused) return <Text>Paused...</Text>;
        return <Text>Listen to this article</Text>;
      };

      const renderIcon = () => {
        if (isWidgetPlaying || isWidgetPaused) {
          return (
            <StyledCloseIcon
              onClick={() => {
                void handleCurrentPlayerUnmountAndCleanUp?.();
              }}
            />
          );
        }
        return <StyledArrowRightIcon />;
      };

      return (
        <RegularContainer onClick={() => handlePlayClick()}>
          {handleRenderHeadphoneIcon()}
          {renderText()}
          {renderIcon()}
        </RegularContainer>
      );
    };

    return (
      <MainContainer {...props} $isGreyedOut={isWidgetPlaying || isWidgetPaused} ref={reference}>
        {isMinify ? handleRenderMinifyUI() : handleRenderRegularUI()}
      </MainContainer>
    );
  },
);

ArticleSpeechInlineWidgetGenericButton.displayName = "ArticleSpeechInlineWidgetGenericButton";
