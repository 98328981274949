import { useAtomValue } from "jotai";
import intersection from "lodash/intersection";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useArticleSpeechConfig } from "scmp-app/lib/app/hooks/scmp-article-speech-config";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { hooksShowGenericInlineWidgetByArticleArticle$key } from "scmp-app/queries/__generated__/hooksShowGenericInlineWidgetByArticleArticle.graphql";

export const useShowGenericInlineWidgetByArticle = (
  reference: hooksShowGenericInlineWidgetByArticleArticle$key,
) => {
  const article = useFragment(
    graphql`
      fragment hooksShowGenericInlineWidgetByArticleArticle on Article {
        ...helpersCheckIsPostiesArticle
        ...hooksPaywallPromiseContent
        entityId
        flag
        sections {
          value {
            entityId
          }
        }
        types {
          value {
            entityId
          }
        }
        topics {
          entityId
        }
      }
    `,
    reference,
  );

  const sanitizedSectionEntityIds = useMemo(
    () =>
      article.sections?.flatMap(
        section => section?.value?.map(element => element?.entityId ?? "") ?? [],
      ) ?? [],
    [article.sections],
  );

  const sanitizedTypeEntityIds = useMemo(
    () =>
      article.types?.flatMap(type => type?.value?.map(element => element?.entityId ?? "") ?? []) ??
      [],
    [article.types],
  );

  const sanitizedTopicEntityIds = useMemo(
    () => article.topics?.map(topic => topic?.entityId) ?? [],
    [article.topics],
  );

  const { scmpArticleSpeechConfigState } = useArticleSpeechConfig();

  const isForceHideSCMPArticleSpeechWidget = useMemo(() => {
    if (!scmpArticleSpeechConfigState) return true;

    const hasFlag = article.flag
      ? (scmpArticleSpeechConfigState.forceHideByFlag ?? []).includes(article.flag)
      : false;

    const hasArticleEntityId = (
      scmpArticleSpeechConfigState.forceHideByArticleEntityId ?? []
    ).includes(article.entityId);

    const hasSectionEntityId =
      intersection(
        scmpArticleSpeechConfigState.forceHideBySectionEntityId ?? [],
        sanitizedSectionEntityIds,
      ).length > 0;

    const hasTypeEntityId =
      intersection(
        scmpArticleSpeechConfigState.forceHideByTypeEntityId ?? [],
        sanitizedTypeEntityIds,
      ).length > 0;

    const hasTopicEntityId =
      intersection(
        scmpArticleSpeechConfigState.forceHideByTopicEntityId ?? [],
        sanitizedTopicEntityIds,
      ).length > 0;

    return (
      hasArticleEntityId || hasSectionEntityId || hasTypeEntityId || hasTopicEntityId || hasFlag
    );
  }, [
    article.entityId,
    article.flag,
    sanitizedSectionEntityIds,
    sanitizedTopicEntityIds,
    sanitizedTypeEntityIds,
    scmpArticleSpeechConfigState,
  ]);

  return {
    isWidgetVisibleByType: !isForceHideSCMPArticleSpeechWidget,
    scmpArticleSpeechConfigState,
  };
};

export const useEnableGenericInlineWidgetByUserSubscription = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const hasIpAccess =
    asyncRosettaState?.result?.instance?.rulesEngine.getFact("hasIpAccess") ?? false;

  const isWidgetEnabledByUser = useMemo(() => {
    if (!asyncRosettaState?.result?.instance) return;

    /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
    return (
      asyncRosettaState?.result?.user?.isCorpSubscriber ||
      asyncRosettaState?.result?.user?.isAccessGrantedUser ||
      asyncRosettaState?.result?.user?.isFreeSubscriber ||
      asyncRosettaState?.result?.user?.isInternalStaff ||
      asyncRosettaState?.result?.isScmpSubscriber ||
      asyncRosettaState?.result?.user?.isSiteLicenseSubscriber ||
      hasIpAccess
    );
    /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
  }, [asyncRosettaState?.result, hasIpAccess]);

  return {
    isWidgetEnabledByUser,
  };
};
