import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { usePlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/hooks";
import type { plusAuthorCardAuthor$key } from "scmp-app/queries/__generated__/plusAuthorCardAuthor.graphql";

import {
  Expertise,
  StyleAuthorCardContainer,
  StyleAuthorInfoContainer,
  StyleAvatarContainer,
  StyledAuthorAvatar,
  StyledAuthorBio,
  StyledStyleAuthorName,
  StyleNameContainer,
} from "./styles";

export type Props = {
  reference: plusAuthorCardAuthor$key;
};

export const PlusAuthorCard: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const { handleOpen } = usePlusDetailedListingPopup();
  const author = useFragment(
    graphql`
      fragment plusAuthorCardAuthor on Author {
        ...authorAvatarAuthor
        ...authorNameAuthor
        ...authorBioAuthor
        areaOfExpertise
      }
    `,
    reference_,
  );

  const handleAuthorClick = (entityUuid: string) => {
    handleOpen({ entityUuid, type: "author" });
  };

  return (
    <StyleAuthorCardContainer>
      <StyleAvatarContainer>
        <StyledAuthorAvatar
          height={120}
          onClick={handleAuthorClick}
          placeholder
          reference={author}
          width={120}
          withoutLink
        />
      </StyleAvatarContainer>
      <StyleAuthorInfoContainer>
        <StyleNameContainer>
          <StyledStyleAuthorName onClick={handleAuthorClick} reference={author} withoutLink />
          {!!author.areaOfExpertise?.length && (
            <>
              <span>|</span>
              <Expertise>{author.areaOfExpertise.join(", ").trim()}</Expertise>
            </>
          )}
        </StyleNameContainer>
        <StyledAuthorBio reference={author} />
      </StyleAuthorInfoContainer>
    </StyleAuthorCardContainer>
  );
};

PlusAuthorCard.displayName = "PlusAuthorCard";
