/**
 * @generated SignedSource<<7a808e543df8201423dbc3a1ec7ce0bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type blockquoteQuoteGenericPostiesArticleContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"contentShareWidgetContent">;
  readonly " $fragmentType": "blockquoteQuoteGenericPostiesArticleContent";
};
export type blockquoteQuoteGenericPostiesArticleContent$key = {
  readonly " $data"?: blockquoteQuoteGenericPostiesArticleContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"blockquoteQuoteGenericPostiesArticleContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "blockquoteQuoteGenericPostiesArticleContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentShareWidgetContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "db8ec5663268b0dca0b1b468d0afc216";

export default node;
