import { FollowButtonDisplay, FollowButtonOutline, FollowButtonSize } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { usePlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/hooks";
import type { articleTopicsContent$key } from "scmp-app/queries/__generated__/articleTopicsContent.graphql";

import type { ArticleTopicsContainerProps } from "./styles";
import {
  ArticleTopicsContainer,
  Item,
  Label,
  MainItem,
  PlusTopicLink,
  RelatedTopics,
  RelatedTopicsContainer,
  RelatedTopicsLabel,
  StyledEntityFollowButton,
  StyledTopicLink,
  Topics,
  TopicSeparator,
} from "./styles";

export type Props = {
  className?: string;
  label?: string;
  reference: articleTopicsContent$key;
  variant?: ArticleTopicsContainerProps["$variant"];
  withFollow?: boolean;
  withSeparator?: boolean;
};

export const ArticleTopics: FunctionComponent<Props> = ({
  className,
  label,
  reference: reference_,
  variant = "light",
  withFollow = true,
  withSeparator = true,
}) => {
  const { handleOpen } = usePlusDetailedListingPopup();
  const { topics } = useFragment(
    graphql`
      fragment articleTopicsContent on Content {
        topics {
          ...topicLinkTopic
          ...entityFollowButtonBase
          entityId
          entityUuid
          name
        }
      }
    `,
    reference_,
  );
  if (!topics || topics.length === 0) return null;
  const [mainTopic, ...otherTopics] = topics;

  const renderSeparator = (index: number) => {
    if (index === otherTopics.length - 1 || variant === "plus") return null;
    return <TopicSeparator>|</TopicSeparator>;
  };

  const renderTopicLink = (topic: (typeof topics)[number]) => {
    if (!topic) return null;

    switch (variant) {
      case "plus":
        return (
          <PlusTopicLink
            onClick={() => {
              handleOpen({ entityUuid: topic.entityUuid, type: "topic" });
            }}
          >
            {topic.name}
          </PlusTopicLink>
        );

      default:
        return <StyledTopicLink reference={topic} />;
    }
  };

  const renderArticleTopics = () => (
    <ArticleTopicsContainer $variant={variant} className={className}>
      {label && <Label>{label}</Label>}
      <Topics>
        {mainTopic && (
          <MainItem>
            {renderTopicLink(mainTopic)}

            {withFollow && (
              <StyledEntityFollowButton
                display={FollowButtonDisplay.CircleGrayIcon}
                outline={FollowButtonOutline.Plain}
                reference={mainTopic}
                size={FollowButtonSize.Compact}
                source="Article_topic_footer"
              />
            )}
          </MainItem>
        )}

        {otherTopics.map(
          (topic, index) =>
            topic && (
              <Item key={topic.entityId}>
                {renderTopicLink(topic)}
                {withFollow && (
                  <StyledEntityFollowButton
                    display={FollowButtonDisplay.CircleGrayIcon}
                    outline={FollowButtonOutline.Plain}
                    reference={topic}
                    size={FollowButtonSize.Compact}
                    source="Article_topic_footer"
                  />
                )}
                {withSeparator && renderSeparator(index)}
              </Item>
            ),
        )}
      </Topics>
    </ArticleTopicsContainer>
  );

  switch (variant) {
    case "plus":
      return (
        <RelatedTopicsContainer>
          <RelatedTopicsLabel>Related Topics:</RelatedTopicsLabel>
          <RelatedTopics>{renderArticleTopics()}</RelatedTopics>
        </RelatedTopicsContainer>
      );

    default:
      return renderArticleTopics();
  }
};

ArticleTopics.displayName = "ArticleTopics";
