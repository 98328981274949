import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { authorRoleAuthor$key } from "scmp-app/queries/__generated__/authorRoleAuthor.graphql";

import { Container, Separator } from "./styles";

export type Props = {
  className?: string;
  reference: authorRoleAuthor$key;
  withoutLink?: boolean;
};

export const AuthorRole: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const author = useFragment(
    graphql`
      fragment authorRoleAuthor on Author {
        role {
          text
        }
      }
    `,
    reference_,
  );

  if (!author.role?.text) return null;

  const spittedRoleText = author.role?.text.split(",") ?? [];
  return spittedRoleText.map((roleText, index) => (
    <Container className={className} key={index}>
      {`${roleText}`}
      {index !== spittedRoleText.length - 1 && <Separator>, </Separator>}
    </Container>
  ));
};

AuthorRole.displayName = "AuthorRole";
