import take from "lodash/take";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { usePlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/hooks";
import type { plusArticleAuthorBlockArticle$key } from "scmp-app/queries/__generated__/plusArticleAuthorBlockArticle.graphql";

import {
  AvatarContainer,
  Container,
  StyledArticleDate,
  StyledAuthorAvatar,
  StyledAuthorNames,
} from "./styles";

export type Props = {
  className?: string;
  firstNthAuthor?: number;
  reference: plusArticleAuthorBlockArticle$key;
};

export const PlusArticleAuthorBlock: FunctionComponent<Props> = ({
  className,
  firstNthAuthor,
  reference: reference_,
}) => {
  const { handleOpen } = usePlusDetailedListingPopup();
  const article = useFragment(
    graphql`
      fragment plusArticleAuthorBlockArticle on Article {
        ...articleDateContent
        ...authorNamesArticle
        authors {
          entityId
          ...authorAvatarAuthor
        }
      }
    `,
    reference_,
  );

  if (!article) return null;

  const authors = firstNthAuthor ? take(article?.authors, firstNthAuthor) : article?.authors;

  return (
    <Container className={className}>
      <AvatarContainer>
        {authors?.map(
          (author, index) =>
            author && (
              <StyledAuthorAvatar
                $zIndex={index}
                height={100}
                key={author.entityId}
                onClick={entityUuid => handleOpen({ entityUuid, type: "author" })}
                reference={author}
                width={100}
                withoutLink
              />
            ),
        )}
      </AvatarContainer>
      <StyledAuthorNames firstNthAuthor={firstNthAuthor} reference={article} variant="plus" />
      <StyledArticleDate reference={article} />
    </Container>
  );
};

PlusArticleAuthorBlock.displayName = "PlusArticleAuthorBlock";
