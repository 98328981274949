import { type FunctionComponent } from "react";

import type { Props as ArticleSpeechInlineWidgetGenericProps } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic";

import { Container, StyledArticleSpeechInlineWidgetGeneric } from "./styles";

type Props = ArticleSpeechInlineWidgetGenericProps;

export const ArticleSpeechInlineWidgetArticle20: FunctionComponent<Props> = ({ ...props }) => (
  <Container>
    <StyledArticleSpeechInlineWidgetGeneric {...props} />
  </Container>
);

ArticleSpeechInlineWidgetArticle20.displayName = "ArticleSpeechInlineWidgetArticle20";
