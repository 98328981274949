import type {
  EmbeddedSpeechifyExperience,
  SpeechifyPlayerConfig,
} from "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs";
import type { Dispatch, MutableRefObject, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useRef, useState } from "react";

type ArticleSpeechifyPlayerStates = "loading" | "paused" | "playing" | "unmounted";

type ContextValue = {
  articleEntityId: string;
  articleSpeechifyPlayer: MutableRefObject<OrUndefined<EmbeddedSpeechifyExperience>>;
  articleSpeechifyPlayerState: ArticleSpeechifyPlayerStates;
  currentRootElement?: HTMLElement;
  setArticleEntityId?: Dispatch<SetStateAction<string>>;
  setArticleSpeechifyPlayerState?: Dispatch<SetStateAction<ArticleSpeechifyPlayerStates>>;
  setCurrentRootElement?: Dispatch<SetStateAction<OrUndefined<HTMLElement>>>;
  setSpeechifyModule?: Dispatch<SetStateAction<OrUndefined<SpeechifyModule>>>;
  speechifyModule: OrUndefined<SpeechifyModule>;
};

type SpeechifyModule = {
  EmbeddedSpeechifyExperience: typeof EmbeddedSpeechifyExperience;
  makeSpeechifyExperience(config: Partial<SpeechifyPlayerConfig>): EmbeddedSpeechifyExperience;
};

const ArticleSpeechContext = createContext<ContextValue>({
  articleEntityId: "",
  articleSpeechifyPlayer: { current: undefined },
  articleSpeechifyPlayerState: "unmounted",
  currentRootElement: undefined,
  speechifyModule: undefined,
});

type Props = {
  children: ReactNode;
};

export const ArticleSpeechContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const articleSpeechifyPlayer = useRef<OrUndefined<EmbeddedSpeechifyExperience>>();

  const [speechifyModule, setSpeechifyModule] = useState<SpeechifyModule>();

  const [articleSpeechifyPlayerState, setArticleSpeechifyPlayerState] =
    useState<ArticleSpeechifyPlayerStates>("unmounted");

  const [currentRootElement, setCurrentRootElement] = useState<HTMLElement>();

  const [articleEntityId, setArticleEntityId] = useState<string>("");

  return (
    <ArticleSpeechContext.Provider
      value={{
        articleEntityId,
        articleSpeechifyPlayer,
        articleSpeechifyPlayerState,
        currentRootElement,
        setArticleEntityId,
        setArticleSpeechifyPlayerState,
        setCurrentRootElement,
        setSpeechifyModule,
        speechifyModule,
      }}
    >
      {children}
    </ArticleSpeechContext.Provider>
  );
};

ArticleSpeechContextProvider.displayName = "ArticleSpeechContextProvider";

export const useArticleSpeechContext = () => useContext(ArticleSpeechContext);
