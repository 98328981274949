/**
 * @generated SignedSource<<5fb850a59ec7f24e1ce92f2989d79011>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type popupArticle20ShareMenuContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articleShareWidgetArticle">;
  readonly " $fragmentType": "popupArticle20ShareMenuContent";
};
export type popupArticle20ShareMenuContent$key = {
  readonly " $data"?: popupArticle20ShareMenuContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"popupArticle20ShareMenuContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "popupArticle20ShareMenuContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleShareWidgetArticle"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "17ad91ff560983d823f1cd8fbc6cfc94";

export default node;
