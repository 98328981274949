import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { articleDateContent$key } from "scmp-app/queries/__generated__/articleDateContent.graphql";

import { Article20Date } from "./article-2.0-date";
import { DefaultArticleDate } from "./default-article-date";

export type Props = {
  className?: string;
  reference: articleDateContent$key;
  variant?: "article-2.0" | "default";
};

export const ArticleDate: FunctionComponent<Props> = ({
  className,
  reference,
  variant = "default",
  ...attribs
}) => {
  const content = useFragment(
    graphql`
      fragment articleDateContent on Content {
        ...defaultArticleDateContent
        ...article20DateContent
      }
    `,
    reference,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ComponentMap: Record<typeof variant, FunctionComponent<any>> = {
    "article-2.0": Article20Date,
    default: DefaultArticleDate,
  };
  const Component = ComponentMap[variant];

  if (!content) return null;

  return <Component className={className} reference={content} {...attribs} />;
};

ArticleDate.displayName = "ArticleDate";
