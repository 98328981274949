import { useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";

import type { OutstreamAdUnit, Targeting } from "scmp-app/components/advertisement/ad-slots/types";

import { Slot } from "./slot";
import { Container } from "./styles";

export type Props = {
  adUnit: OutstreamAdUnit;
  breakpoint?: string;
  className?: string;
  divId?: string;
  isDisabled?: boolean;
  targeting?: Targeting;
  zone?: null | string;
};
export const OutstreamAdSlot: FunctionComponent<Props> = ({
  breakpoint = "",
  isDisabled,
  ...slotProps
}) => {
  const isBreakpoint = useResponsive(breakpoint, false);
  if (!isBreakpoint || isDisabled) return null;

  return (
    <Container>
      <Slot {...slotProps} />
    </Container>
  );
};

OutstreamAdSlot.displayName = "OutstreamAdSlot";
