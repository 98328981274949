import styled from "@emotion/styled";
import { ShareButton } from "@product/scmp-sdk";

export const StyledShareButton = styled(ShareButton)`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;

  inline-size: 100%;

  svg {
    :hover {
      border-radius: 50%;

      background-color: #dddddd;
    }
  }

  > span {
    padding: 0;

    text-align: start;
  }
`;
