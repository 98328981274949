import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { ZIndexLayer } from "shared/lib/styles";

import { ArticleSpeechInlineWidget } from "scmp-app/components/article/article-speech";
import { IconWrapper } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/empty-button/styles";
import { ContentShareWidget } from "scmp-app/components/content/content-share-widget";
import { StyledShareButton } from "scmp-app/components/content/content-share-widget/styles";

type ContainerProps = {
  $isLegacy: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  inline-size: min-content;

  ${props =>
    props.$isLegacy &&
    css`
      ${StyledShareButton} {
        inline-size: 30px;
        block-size: 30px;

        div {
          inline-size: 30px;
          block-size: 30px;
        }

        svg {
          inline-size: 30px;
          block-size: 30px;
        }
      }
    `}
`;

export const StyledArticleSpeechInlineWidget = styled(ArticleSpeechInlineWidget)`
  margin-block-end: 28px;

  border-block-end: 1px solid #aaaaaa;

  ${IconWrapper} {
    margin-block-end: 8px;
  }
`;

export type StyledContentShareWidgetProps = {
  $direction: "column" | "row";
  $outlineColor?: string;
};
export const StyledContentShareWidget = styled(ContentShareWidget, {
  ...transientOptions,
})<StyledContentShareWidgetProps>(
  props => css`
    position: relative;

    display: flex;
    flex-flow: ${props.$direction};
    gap: 16px;
    justify-content: flex-start;
    align-items: center;

    > [role="tooltip"] {
      z-index: ${ZIndexLayer.AppBar - 1};
    }

    ${props.$outlineColor &&
    css`
      button > * {
        border: 1px solid ${props.$outlineColor};
      }
    `}
  `,
);
