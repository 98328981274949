/**
 * @generated SignedSource<<5eaec19fbf108ebda2b43daacf6809f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleGeoLocationWidget$data = {
  readonly topics: ReadonlyArray<{
    readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"geoLocationMainLocationArticle">;
  readonly " $fragmentType": "articleGeoLocationWidget";
};
export type articleGeoLocationWidget$key = {
  readonly " $data"?: articleGeoLocationWidget$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleGeoLocationWidget">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleGeoLocationWidget",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "types",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "geoLocationMainLocationArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "1445783f2e8701bef72ec9fc906b6a57";

export default node;
