import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

export const OuterContainer = styled.div`
  block-size: 640px;

  ${props => props.theme.breakpoints.down("desktop")} {
    block-size: unset;
  }
`;

export const Container = styled.div`
  inline-size: 300px;
  block-size: 100%;
  min-inline-size: 295px;
  padding: 20px;

  font-family: ${fontRobotoCondensed};

  border: 1px solid #cccccc;
  border-radius: 8px;

  background: #ffffff;

  ${props => props.theme.breakpoints.up("desktop")} {
    block-size: unset;
  }
`;
export const Header = styled.div`
  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
`;
