/**
 * @generated SignedSource<<04e1eb3064cbd835ac3ecc5dac9b6118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleRelatedTopicsContent$data = {
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityFollowButtonBase" | "topicLinkTopic">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "articleRelatedTopicsContent";
};
export type articleRelatedTopicsContent$key = {
  readonly " $data"?: articleRelatedTopicsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleRelatedTopicsContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleRelatedTopicsContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "topicLinkTopic"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityFollowButtonBase"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "6777dd70f1f1e9889f512860cfc1f1ef";

export default node;
