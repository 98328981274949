/**
 * @generated SignedSource<<4b9202c2807bfae7d08a8b3de06ad0bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type buttonArticleSpeechInlineWidgetGenericButtonArticle$data = {
  readonly entityId: string;
  readonly headline: string | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly " $fragmentType": "buttonArticleSpeechInlineWidgetGenericButtonArticle";
};
export type buttonArticleSpeechInlineWidgetGenericButtonArticle$key = {
  readonly " $data"?: buttonArticleSpeechInlineWidgetGenericButtonArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"buttonArticleSpeechInlineWidgetGenericButtonArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "buttonArticleSpeechInlineWidgetGenericButtonArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "d4a0266c1c1330f9b16018105bd86b4b";

export default node;
