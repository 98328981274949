import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { PianoPaywall } from "scmp-app/components/article/article-piano-paywall";
import { ContentReadMoreChecker } from "scmp-app/components/content/content-read-more/content-read-more-checker";
import type { globalCheckerContent$key } from "scmp-app/queries/__generated__/globalCheckerContent.graphql";

type Props = {
  children?: ReactNode;
  reference: globalCheckerContent$key;
};

export const GlobalChecker: FunctionComponent<Props> = ({ children, reference }) => {
  const content = useFragment(
    graphql`
      fragment globalCheckerContent on Content {
        ...articlePianoPaywallContent
      }
    `,
    reference,
  );

  return (
    <PianoPaywall reference={content}>
      <ContentReadMoreChecker>{children}</ContentReadMoreChecker>
    </PianoPaywall>
  );
};

GlobalChecker.displayName = "GlobalChecker";
