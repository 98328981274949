import { defineTrackViewedPercentageSchema } from "scmp-app/components/schema-render/common/track-viewed-percentage/schemas";

export const NumberOfReadableElementInPaywall = 3;

export const PlusCustomContents = [
  defineTrackViewedPercentageSchema({
    percentage: "0",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "20",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "50",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "90",
  }),
  defineTrackViewedPercentageSchema({
    percentage: "100",
  }),
];
