import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

export const Container = styled.div`
  color: #666666;
  font-weight: 400;
  font-size: 11px;
  font-family: ${fontRoboto};
  font-variant-numeric: lining-nums proportional-nums;

  ${theme.breakpoints.up("tablet")} {
    font-size: 14px;
  }
`;

export const PublishedDate = styled.time``;

export const UpdatedDate = styled.time`
  ::before {
    content: "|";

    margin-inline: 4px;
  }
`;
