/**
 * @generated SignedSource<<84d64fd774cface57f7844ac91186c12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contextsArticleListContextProviderArticle$data = {
  readonly entityId: string;
  readonly " $fragmentType": "contextsArticleListContextProviderArticle";
};
export type contextsArticleListContextProviderArticle$key = {
  readonly " $data"?: contextsArticleListContextProviderArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"contextsArticleListContextProviderArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "contextsArticleListContextProviderArticle"
};

(node as any).hash = "ef2b4cd6acab2e36c0ebc725409ed6e1";

export default node;
