import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";

export const useTracking = () => {
  const trackClick = (urlAlias: string, entityId: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: entityId,
        destination_url: urlAlias,
        page_type: "article",
        widget_name: "read_more",
      },
      subcategory: "widget",
    });
  };

  return {
    trackClick,
  };
};
