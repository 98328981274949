import type { ButtonTypeMap } from "@mui/base/Button";
import classnames from "classnames";
import type { HTMLProps } from "react";
import { forwardRef } from "react";

import { ButtonStyled } from "./styles";

export type Props = ButtonTypeMap["props"] &
  Omit<HTMLProps<HTMLButtonElement>, "as" | "ref"> & {
    className?: string;
    icon?: boolean;
    noStyle?: boolean;
    text?: boolean;
  };

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, className, icon, noStyle, text, ...props }, reference) => (
    <ButtonStyled
      ref={reference}
      {...props}
      className={classnames(
        {
          default: !text && !noStyle,
          icon,
          text,
        },
        className,
      )}
    >
      {children}
    </ButtonStyled>
  ),
);

Button.displayName = "Button";
