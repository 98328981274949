import type { FunctionComponent, ReactNode } from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

export const ToolbarPortal: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { toolbarPortalElement } = useContext(GalleryContext);
  return toolbarPortalElement ? createPortal(children, toolbarPortalElement) : null;
};

ToolbarPortal.displayName = "ToolbarPortal";
