/**
 * @generated SignedSource<<d9434bab71f09d4d2747160b4d7b5ea5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type appButtonContent$data = {
  readonly urlAlias: string;
  readonly " $fragmentType": "appButtonContent";
};
export type appButtonContent$key = {
  readonly " $data"?: appButtonContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"appButtonContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appButtonContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "07d65b88e455f61932851ce7ac5cb7ed";

export default node;
