import { useRouter } from "next/router";
import { graphql, useFragment } from "react-relay";

import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";
import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import { PostiesFormDrawerContextProvider } from "scmp-app/components/posties/form-drawer/contexts";
import type { hooksPostiesArticleArticle$key } from "scmp-app/queries/__generated__/hooksPostiesArticleArticle.graphql";

import { GenericPostiesArticle } from "./generic-posties-article";

export const usePostiesArticle = (
  provider: DefaultComponentsProvider,
  article_: hooksPostiesArticleArticle$key,
) => {
  const article = useFragment(
    graphql`
      fragment hooksPostiesArticleArticle on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        ...genericPostiesArticleContent
          @arguments(customContents: $customContents, bodyType: $bodyType)
        ...helpersGetArticleTypeArticle
      }
    `,
    article_,
  );

  const handleRenderPostiesArticle = () => {
    switch (getArticleType(article)) {
      default:
        return (
          <PostiesFormDrawerContextProvider>
            <GenericPostiesArticle provider={provider} reference={article} />
          </PostiesFormDrawerContextProvider>
        );
    }
  };

  return {
    handleRenderPostiesArticle,
  };
};

export const useArticleFormLink = () => {
  const { asPath, replace } = useRouter();

  const toggleByUrl = (hash: string) => {
    const url = new URL(location.origin + asPath);
    url.hash = hash;
    void replace(url.toString(), undefined, { scroll: false, shallow: true });
  };

  return {
    toggleByUrl,
  };
};
