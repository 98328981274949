import type { SharePositionType, SocialShareType } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as ContentShareWidgetProps } from "scmp-app/components/content/content-share-widget";
import type { articleShareWidgetArticle$key } from "scmp-app/queries/__generated__/articleShareWidgetArticle.graphql";

import type { StyledContentShareWidgetProps } from "./styles";
import { Container, StyledArticleSpeechInlineWidget, StyledContentShareWidget } from "./styles";

type ButtonStyle = {
  backgroundColor?: string;
  color?: string;
  mobilePadding?: number;
  padding?: number;
  size?: number;
  svgTransform?: string;
};

type Options = {
  $props: Partial<ContentShareWidgetProps> & StyledContentShareWidgetProps;
  buttonStyle?: ButtonStyle;
  hasArticleSpeech?: boolean;
  types: SocialShareType[];
};

type Variants =
  | "default"
  | "defaultMobile"
  | "legacyLiveArticle"
  | "legacyShare"
  | "legacyShareMobile"
  | "legacyShareWithSpeech"
  | "magazines-style";

const optionsMap: Record<Variants, Options> = {
  default: {
    $props: { $direction: "column", showLabel: true },
    types: ["facebook", "twitter", "whatsapp", "telegram", "email"],
  },
  defaultMobile: {
    $props: {
      $direction: "column",
      extraProps: {
        copyLink: {
          variant: "label",
        },
      },
      showLabel: true,
    },
    types: ["facebook", "twitter", "whatsapp", "telegram", "email", "copy-link"],
  },
  legacyLiveArticle: {
    $props: { $direction: "column" },
    types: ["facebook", "twitter", "whatsapp", "telegram", "copy-link", "email", "print"],
  },
  legacyShare: {
    $props: { $direction: "row" },
    types: ["facebook", "twitter", "copy-link", "email"],
  },
  legacyShareMobile: {
    $props: { $direction: "row" },
    types: ["facebook", "twitter"],
  },
  legacyShareWithSpeech: {
    $props: { $direction: "column" },
    hasArticleSpeech: true,
    types: ["facebook", "twitter", "whatsapp", "telegram", "copy-link", "email", "print"],
  },
  "magazines-style": {
    $props: { $direction: "row" },
    buttonStyle: { size: 30 },
    types: ["facebook", "twitter", "copy-link", "email"],
  },
};

type Props = {
  className?: string;
  onClick?: () => void;
  position?: SharePositionType;
  reference: articleShareWidgetArticle$key;
  variant?: Variants;
};

export const ArticleShareWidget: FunctionComponent<Props> = ({
  className,
  onClick,
  position = "article_adhesive",
  reference,
  variant = "default",
}) => {
  const content = useFragment<articleShareWidgetArticle$key>(
    graphql`
      fragment articleShareWidgetArticle on Article {
        ...contentShareWidgetContent
        ...articleCommentsTriggerArticle
        ...articleSpeechInlineWidgetArticle
      }
    `,
    reference,
  );

  const { $props, buttonStyle, hasArticleSpeech, types } = optionsMap[variant];

  const tooltipPlacement = $props.$direction === "column" ? "right" : "top";

  return (
    <Container $isLegacy={variant.includes("legacy")} className={className} onClick={onClick}>
      {hasArticleSpeech && (
        <StyledArticleSpeechInlineWidget reference={content} variant="generic-minify" />
      )}
      <StyledContentShareWidget
        extraProps={{
          copyLink: {
            tooltipPlacement,
            variant: "tooltip",
          },
        }}
        position={position}
        reference={content}
        style={buttonStyle}
        types={types}
        {...$props}
      />
    </Container>
  );
};

ArticleShareWidget.displayName = "ArticleShareWidget";
