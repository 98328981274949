import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext } from "react";

import type { ShareContentUnlocked } from "scmp-app/pages/article/types";

type ContextValue = {
  shareContentUnlocked?: ShareContentUnlocked;
};

const ArticlePaywallUnlockContext = createContext<ContextValue>({});

export type Props = {
  children?: ReactNode;
  shareContentUnlocked?: ShareContentUnlocked;
};

export const ArticlePaywallUnlockProvider: FunctionComponent<Props> = ({
  children,
  shareContentUnlocked,
}) => (
  <ArticlePaywallUnlockContext.Provider
    value={{
      shareContentUnlocked,
    }}
  >
    {children}
  </ArticlePaywallUnlockContext.Provider>
);

ArticlePaywallUnlockProvider.displayName = "ArticlePaywallUnlockProvider";

export const useArticlePaywallUnlockContext = () => useContext(ArticlePaywallUnlockContext);
