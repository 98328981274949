/**
 * @generated SignedSource<<b50f2c13bc49e706f4840618f4fc64c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type globalCheckerContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"articlePianoPaywallContent">;
  readonly " $fragmentType": "globalCheckerContent";
};
export type globalCheckerContent$key = {
  readonly " $data"?: globalCheckerContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"globalCheckerContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "globalCheckerContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articlePianoPaywallContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "c27f67d1076edb6b3ad21ac2100c70f1";

export default node;
