import { theme, useResponsive } from "@product/scmp-sdk";
import { useThrottledCallback } from "@react-hookz/web";
import type {
  Destructor,
  PlayerState,
  SpeechifyExperienceEvent,
  SpeechifyPlayerConfig,
} from "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs";
import { useSetAtom } from "jotai";
import { useCallback, useRef } from "react";

import { ZIndexLayer } from "shared/lib/styles";

import { onlyOnePlayingPlayerAtom } from "scmp-app/components/article/article-list/hooks";
import { SpeechSkipClassName } from "scmp-app/components/article/article-speech/consts";
import { useArticleSpeechContext } from "scmp-app/components/article/article-speech/contexts";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";

import type { ExtendedEvents } from "./types";

const useBuildSpeechifyEventHandler = () => {
  const { setArticleSpeechifyPlayerState } = useArticleSpeechContext();
  const setOnlyOnePlayingPlayerAtom = useSetAtom(onlyOnePlayingPlayerAtom);

  const hasTriggeredThreeSeconds = useRef(false);
  const triggeredPercentage = useRef([-1]);

  const sendProgressTrackingEvent = useThrottledCallback(
    ({ entityId, playerState }: { entityId: string; playerState: PlayerState | undefined }) => {
      if (playerState?.audioLengthSeconds && playerState?.progressFraction) {
        sendGA4Tracking({
          action: "sys",
          category: "tts",
          customized_parameters: {
            action_type: "progress",
            article_id: entityId,
            audio_length: Math.ceil(playerState?.audioLengthSeconds),
            progress_fraction: playerState?.progressFraction,
          },
          subcategory: "",
        });
      }
    },
    [sendGA4Tracking],
    1000,
    true,
  );

  const handleCallingExtendedEventFunctions = (
    event: SpeechifyExperienceEvent["type"],
    extendedEvents?: ExtendedEvents,
  ) => {
    if (!extendedEvents) return;

    (extendedEvents[event] ?? []).forEach(function_ => function_());
  };

  const speechifyEventHandler = useCallback(
    (entityId: string, extendedEvents?: ExtendedEvents) =>
      (event: SpeechifyExperienceEvent, playerState: PlayerState | undefined) => {
        switch (event.type) {
          case "end-scrubbing":
            if (playerState?.progressFraction) {
              triggeredPercentage.current = triggeredPercentage?.current.filter(
                item => item < playerState?.progressFraction * 100,
              );
            }
            break;
          case "pause":
            setArticleSpeechifyPlayerState?.("paused");
            if (playerState?.audioLengthSeconds && playerState?.progressFraction) {
              sendGA4Tracking({
                action: "sys",
                category: "tts",
                customized_parameters: {
                  action_type: "pause",
                  article_id: entityId,
                  audio_length: Math.ceil(playerState?.audioLengthSeconds),
                  progress_fraction: playerState?.progressFraction,
                },
                subcategory: "",
              });

              sendGA4Tracking({
                action: "click",
                category: "tts",
                customized_parameters: {
                  action_type: "pause",
                  article_id: entityId,
                  audio_length: Math.ceil(playerState?.audioLengthSeconds),
                  progress_fraction: playerState?.progressFraction,
                },
                subcategory: "",
              });
            }

            break;
          case "play":
            setArticleSpeechifyPlayerState?.("playing");
            break;
          case "progress":
            const percentageElapsed = Math.round((playerState?.progressFraction ?? 0) * 100);
            if (percentageElapsed > 0 && percentageElapsed % 10 === 0) {
              const alreadySent = triggeredPercentage?.current.includes(percentageElapsed);

              if (!alreadySent) {
                triggeredPercentage?.current.push(percentageElapsed);
                sendProgressTrackingEvent({
                  entityId,
                  playerState,
                });
              }
            }

            if (!hasTriggeredThreeSeconds.current && playerState?.audioLengthSeconds) {
              const targetProgressFraction = 3 / playerState.audioLengthSeconds;
              if (playerState?.progressFraction >= targetProgressFraction) {
                hasTriggeredThreeSeconds.current = true;

                sendGA4Tracking({
                  action: "sys",
                  category: "tts",
                  customized_parameters: {
                    action_type: "3s",
                    article_id: entityId,
                    audio_length: Math.ceil(playerState?.audioLengthSeconds),
                    progress_fraction: playerState?.progressFraction,
                  },
                  subcategory: "",
                });
              }
            }

            break;
          case "progress-finished":
            setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: false }));

            if (playerState?.audioLengthSeconds && playerState?.progressFraction) {
              sendGA4Tracking({
                action: "sys",
                category: "tts",
                customized_parameters: {
                  action_type: "finished",
                  article_id: entityId,
                  audio_length: Math.ceil(playerState?.audioLengthSeconds),
                  progress_fraction: playerState?.progressFraction,
                },
                subcategory: "",
              });
            }

            triggeredPercentage.current = [-1];

            break;
          case "replay":
            setArticleSpeechifyPlayerState?.("playing");
            break;
          case "resume":
            setArticleSpeechifyPlayerState?.("playing");

            if (playerState?.audioLengthSeconds && playerState?.progressFraction) {
              sendGA4Tracking({
                action: "click",
                category: "tts",
                customized_parameters: {
                  action_type: "resume",
                  article_id: entityId,
                  audio_length: Math.ceil(playerState?.audioLengthSeconds),
                  progress_fraction: playerState?.progressFraction,
                },
                subcategory: "",
              });
              sendGA4Tracking({
                action: "sys",
                category: "tts",
                customized_parameters: {
                  action_type: "play",
                  article_id: entityId,
                  audio_length: Math.ceil(playerState?.audioLengthSeconds),
                  progress_fraction: playerState?.progressFraction,
                },
                subcategory: "",
              });
            }

            break;
          case "speed-changed":
            if (playerState?.progressFraction && playerState?.speedWPM) {
              sendGA4Tracking({
                action: "click",
                category: "tts",
                customized_parameters: {
                  article_id: entityId,
                  progress_fraction: playerState?.progressFraction,
                  speed: playerState?.speedWPM,
                },
                subcategory: "speedchanged",
              });
            }

            break;
          case "unmount":
            setOnlyOnePlayingPlayerAtom(state => ({ ...state, stopYoutubeAutoplay: false }));
            break;
          case "voice-changed":
            if (playerState?.progressFraction) {
              sendGA4Tracking({
                action: "click",
                category: "tts",
                customized_parameters: {
                  article_id: entityId,
                  progress_fraction: playerState?.progressFraction,
                  voice: playerState?.voiceDisplayName ?? "",
                },
                subcategory: "voicechanged",
              });
            }
            break;
        }

        handleCallingExtendedEventFunctions(event.type, extendedEvents);
      },
    [sendProgressTrackingEvent, setArticleSpeechifyPlayerState, setOnlyOnePlayingPlayerAtom],
  );

  return {
    speechifyEventHandler,
  };
};

export const useArticleSpeechPlayerController = () => {
  const {
    articleEntityId,
    articleSpeechifyPlayer,
    currentRootElement,
    setArticleEntityId,
    setArticleSpeechifyPlayerState,
    setCurrentRootElement,
    setSpeechifyModule,
    speechifyModule,
  } = useArticleSpeechContext();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);
  const setOnlyOnePlayingPlayerAtom = useSetAtom(onlyOnePlayingPlayerAtom);
  const { speechifyEventHandler } = useBuildSpeechifyEventHandler();

  const handleSpeechifyEventsCleanUp = useRef<OrUndefined<Destructor>>();

  const handleInitSpeechifyModule = () => {
    const run = async () => {
      if (speechifyModule) return;

      const sModule = await import(
        /* webpackIgnore: true */ "https://storage.googleapis.com/speechify-api-cdn/speechifyapi.min.mjs"
      );

      setSpeechifyModule?.(sModule);
    };

    void run();
  };

  const handleInitNewPlayerAndPlay = async ({
    entityId,
    extendedEvents,
    ga4ActionType,
    playerConfig,
    zIndex = `${ZIndexLayer.Drawer - 1}`,
  }: {
    entityId: string;
    extendedEvents?: ExtendedEvents;
    ga4ActionType?: "article_button" | "sidebar_button" | undefined;
    playerConfig: Partial<SpeechifyPlayerConfig>;
    zIndex?: string;
  }) => {
    if (!speechifyModule || !playerConfig.rootElement || articleSpeechifyPlayer.current) return;

    setArticleSpeechifyPlayerState?.("loading");

    setCurrentRootElement?.(playerConfig.rootElement);

    const speechifyWidget = speechifyModule.makeSpeechifyExperience({
      customStyles: {
        playButton: {
          background: "radial-gradient(#ffffff 50%, transparent 50%)",
        },
        primaryColor: "#4585FF",
        widget: {
          borderRadius: "8px",
          bottom: isMobile ? "20px" : "40px",
          zIndex,
        },
      },
      enableAutoScroll: false,
      enableHighlighting: false,
      ignoreElements(element) {
        return (
          element.tagName === "NOSCRIPT" ||
          element.tagName === "STYLE" ||
          element.tagName === "SCRIPT" ||
          element.classList.contains(SpeechSkipClassName)
        );
      },
      ...playerConfig,
      showFeedbackForm: false,
    });

    setArticleEntityId?.(entityId);

    await speechifyWidget.mount();

    handleSpeechifyEventsCleanUp.current = speechifyWidget.addEventListener(
      speechifyEventHandler(entityId, extendedEvents),
    );

    articleSpeechifyPlayer.current = speechifyWidget;
    setOnlyOnePlayingPlayerAtom(state => ({
      ...state,
      articleSpeechifyPlayer: speechifyWidget,
    }));

    setArticleSpeechifyPlayerState?.("playing");

    const playerState = await speechifyWidget.getPlayerState();
    if (ga4ActionType) {
      sendGA4Tracking({
        action: "click",
        category: "tts",
        customized_parameters: {
          action_type: ga4ActionType,
          article_id: entityId,
          audio_length: Math.ceil(playerState?.audioLengthSeconds),
          progress_fraction: playerState?.progressFraction,
        },
        subcategory: "",
      });
    }
    sendGA4Tracking({
      action: "sys",
      category: "tts",
      customized_parameters: {
        action_type: "play",
        article_id: entityId,
        audio_length: Math.ceil(playerState?.audioLengthSeconds),
        progress_fraction: playerState?.progressFraction,
      },
      subcategory: "",
    });

    speechifyWidget.play();
  };

  const handleCurrentPlayerUnmountAndCleanUp = async () => {
    if (!articleSpeechifyPlayer.current) return;
    const playerState = await articleSpeechifyPlayer.current.getPlayerState();
    sendGA4Tracking({
      action: "click",
      category: "tts",
      customized_parameters: {
        action_type: "close",
        article_id: articleEntityId,
        audio_length: Math.ceil(playerState?.audioLengthSeconds),
        progress_fraction: playerState?.progressFraction,
      },
      subcategory: "",
    });

    setArticleSpeechifyPlayerState?.("unmounted");
    handleSpeechifyEventsCleanUp.current?.();
    await articleSpeechifyPlayer.current?.unmount();
    articleSpeechifyPlayer.current = undefined;
  };

  const handleCheckIsCurrentArticleLinkedToWidget = useCallback(
    (rootElement: HTMLElement | null | undefined) => {
      if (!rootElement) return false;

      return currentRootElement === rootElement;
    },
    [currentRootElement],
  );

  return {
    handleCheckIsCurrentArticleLinkedToWidget,
    handleCurrentPlayerUnmountAndCleanUp,
    handleInitNewPlayerAndPlay,
    handleInitSpeechifyModule,
  };
};
