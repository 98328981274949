import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { paywall } from "shared/data";

import type { helpersIsArchiveWallArticle$key } from "scmp-app/queries/__generated__/helpersIsArchiveWallArticle.graphql";
import type { helpersIsVelocityWallArticle$key } from "scmp-app/queries/__generated__/helpersIsVelocityWallArticle.graphql";

export const checkArticleIsInArchiveWall = (
  article_: helpersIsArchiveWallArticle$key,
  articleTimeFrame: number,
) => {
  const article = readInlineData(
    graphql`
      fragment helpersIsArchiveWallArticle on Article @inline {
        ...helpersIsVelocityWallArticle
        createdDate
        publishedDate
        paywallTypes {
          entityId
        }
      }
    `,
    article_,
  );
  const isArchivedArticleDate = Date.now() - 1000 * 60 * 60 * 24 * articleTimeFrame;
  const articleDate = notEmpty(article.publishedDate) ? article.publishedDate : article.createdDate;
  // If either date is empty, it should not in archive wall
  if (!notEmpty(isArchivedArticleDate) || !notEmpty(articleDate)) return false;

  const paywallTypesHasNotArchived = notEmpty(
    article.paywallTypes?.find?.(
      paywallType => paywallType?.entityId === paywall.notArchive.entityId,
    ),
  );

  const isInVelocityWall = checkArticleIsInVelocityWall(article);
  return !paywallTypesHasNotArchived && !isInVelocityWall && isArchivedArticleDate > articleDate;
};

export const checkArticleIsInVelocityWall = (article_: helpersIsVelocityWallArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersIsVelocityWallArticle on Article @inline {
        paywallTypes {
          entityId
        }
      }
    `,
    article_,
  );
  const paywallTypesHasNotVelocity = notEmpty(
    article.paywallTypes?.find?.(
      paywallType => paywallType?.entityId === paywall.notVelocity.entityId,
    ),
  );

  const paywallTypesHasVelocity = notEmpty(
    article.paywallTypes?.find?.(
      paywallType => paywallType?.entityId === paywall.velocity.entityId,
    ),
  );

  return !paywallTypesHasNotVelocity && paywallTypesHasVelocity;
};
