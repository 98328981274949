import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { ArticleCommentsTrigger } from "scmp-app/components/article/article-comments-trigger";
import { ContentBookmark } from "scmp-app/components/content/content-bookmark";

export const StyledCommentsTrigger = styled(ArticleCommentsTrigger)``;

export type StyledContentBookmarkProps = {
  $size?: "normal" | "small";
  $variant?: "dark" | "light";
};
export const StyledContentBookmark = styled(ContentBookmark, {
  ...transientOptions,
})<StyledContentBookmarkProps>`
  inline-size: 25px;
  block-size: 28px;
  padding: 0;

  ${props =>
    props.$size === "small" &&
    css`
      inline-size: 19px;
      block-size: 23px;
    `}

  path:last-of-type {
    ${props =>
      props.$variant === "dark"
        ? css`
            fill: #ffffff;
          `
        : css`
            fill: #001246;
          `}
  }
`;

export type ContainerProps = {
  $orientation: "column" | "row";
  $variant?: "article-2.0" | "default" | "magazines-style";
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: ${props => props.$orientation};
  gap: 16px;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    props.$variant === "magazines-style" &&
    css`
      ${StyledCommentsTrigger} {
        inline-size: 20px;
        block-size: 22.7px;
      }

      ${StyledContentBookmark} {
        path[opacity] {
          fill: #d7d7d7;
          opacity: 1;
        }
      }
    `}

  ${props =>
    props.$variant === "article-2.0" &&
    css`
      ${StyledCommentsTrigger}, ${StyledContentBookmark} {
        inline-size: 36px;
        block-size: 36px;

        path {
          fill: #000000 !important;
        }
      }
    `}

  button {
    line-height: 0;

    background-color: transparent;
  }
`;
