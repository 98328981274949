import { Fragment, type FunctionComponent, type ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import type { geoLocationGroupQueue$key } from "scmp-app/queries/__generated__/geoLocationGroupQueue.graphql";

import {
  Container,
  LocationGroup,
  LocationItemContainer,
  LocationItemLink,
  StyledIconArrow,
} from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
  expandedLocation: null | string;
  locationName: string;
  onClick?: () => void;
  reference: geoLocationGroupQueue$key | null | undefined;
};

export const GeoLocationGroup: FunctionComponent<Props> = ({
  className,
  expandedLocation,
  locationName,
  onClick,
  reference: reference_,
}) => {
  const data = useFragment<geoLocationGroupQueue$key>(
    graphql`
      fragment geoLocationGroupQueue on Queue {
        items {
          edges {
            node {
              ... on Topic {
                entityId
                ...topicLinkTopic
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      <LocationGroup onClick={onClick}>
        {locationName}
        <StyledIconArrow $isToggle={expandedLocation === locationName} />
      </LocationGroup>

      {expandedLocation === locationName && data && (
        <LocationItemContainer>
          {data.items?.edges.map((edge, index) => {
            if (!edge) return null;
            return (
              <Fragment key={edge.node.entityId}>
                {index > 0 && ` | `}
                <LocationItemLink preferShortName reference={edge.node} />
              </Fragment>
            );
          })}
        </LocationItemContainer>
      )}
    </Container>
  );
};

GeoLocationGroup.displayName = "GeoLocationGroup";
