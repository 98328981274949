import type { FunctionComponent, ReactNode } from "react";

import { Container, StyledBaseLink, StyledTrustLogo } from "./styles";

export type Props = {
  children?: ReactNode;
  className?: string;
};

export const ArticleTrustLabel: FunctionComponent<Props> = ({ children, className }) => (
  <StyledBaseLink
    anchorProps={{ "aria-label": "SCMP participates in the Trust Project", target: "_blank" }}
    className={className}
    pathname="/policies-and-standards#participation"
  >
    <Container>
      <StyledTrustLogo />
      {children ?? <span>Why you can trust SCMP</span>}
    </Container>
  </StyledBaseLink>
);

ArticleTrustLabel.displayName = "ArticleTrustLabel";
