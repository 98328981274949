import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { fontMerriweather, fontRoboto, theme } from "@product/scmp-sdk";

import { getClassNameFromSize } from "scmp-app/components/advertisement/ad-slots/helpers";
import { ArticleImageContainer } from "scmp-app/components/article/article-image/styles";
import {
  InlineOutstreamAdSlot,
  InlineSlot,
} from "scmp-app/components/schema-render/common/inline-ad-slot/styles";
import { Container as InlineWidgetContainer } from "scmp-app/components/schema-render/common/inline-widget/styles";
import { StyledDiv as StyledParagraphDiv } from "scmp-app/components/schema-render/common/paragraph/styles";

type Props = {
  $variant?: "dark" | "light";
  theme: Theme;
};

export const pageStyle = (props: Props) => css`
  font-weight: 400;
  font-size: 16px;
  font-family: ${fontMerriweather};
  line-height: 26px;

  ${props.$variant === "dark"
    ? css`
        color: #f7f7f7;
      `
    : css`
        color: #222222;
      `}

  ${theme.breakpoints.up("desktop")} {
    font-size: 18px;
    line-height: 30px;
  }

  h1,
  h2,
  h3 {
    padding-block: 20px 10px;

    font-weight: 700;
    font-size: 24px;
    font-family: ${fontRoboto};
    line-height: 30px;

    ${props.$variant === "dark"
      ? css`
          color: #f7f7f7;
        `
      : css`
          color: #000000;
        `}

    ${theme.breakpoints.up("tablet")} {
      font-size: 28px;
      line-height: 34px;
    }
  }

  h3:empty {
    display: none;
  }

  h4 {
    padding-block: 15px 2px;

    font-weight: 700;
    font-size: 18px;
    font-family: ${fontRoboto};
    line-height: 23px;

    ${props.$variant === "dark"
      ? css`
          color: #f7f7f7;
        `
      : css`
          color: #000000;
        `}

    ${theme.breakpoints.up("tablet")} {
      font-size: 20px;
      line-height: 27px;
    }
  }

  ${InlineWidgetContainer}, ${StyledParagraphDiv} {
    margin-block-end: 20px;

    overflow-wrap: anywhere;
  }

  p {
    margin-block: 0 20px;
  }

  ol {
    margin-block: 0 20px;

    list-style: decimal;
  }

  ul {
    margin-block: 0 20px;

    list-style: disc;
  }

  li {
    margin-inline: 30px 0;
    padding-block-end: 8px;
    padding-inline-start: 4px;
    &:not(:last-child) {
      padding-block-end: 20px;
    }
    * {
      margin: 0;
    }

    ${theme.breakpoints.up("tablet")} {
      margin-inline: 50px 20px;
    }
  }

  strong {
    font-weight: 700;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
    ${props.$variant === "dark"
      ? css`
          color: #f7f7f7;
        `
      : css`
          color: #2c4692;
        `}

    > span {
      word-break: break-word;
    }
  }

  /*
    NOTE: need this styling to ensure strike through is applied to the anchor as well - currently
    there is a conflict from display: inline-block coming from global-styles.tsx for anchor
  */
  s {
    a {
      display: inline;
    }
  }

  ${ArticleImageContainer} {
    margin-block: 12px 32px;
  }

  ${InlineSlot} {
    margin-block: 10px 40px;

    ${theme.breakpoints.only("mobile")} {
      margin-block: 10px 20px;
    }
  }

  ${InlineOutstreamAdSlot} {
    :not(
      .${getClassNameFromSize({ height: 0, width: 0 })},.${getClassNameFromSize({
          height: 1,
          width: 1,
        })}
    ) {
      margin-block-end: 20px;
    }
  }

  .methode-html-wrapper {
    margin-block: 2px 28px;

    ${theme.breakpoints.up("desktop")} {
      margin-block: 12px 32px;
    }
  }

  .video-wrapper {
    margin-block: 20px;
  }

  *::selection {
    ${props.$variant === "dark"
      ? css`
          background: #ffe99966;
        `
      : css`
          background: #ffe999;
        `}
  }
`;
