/**
 * @generated SignedSource<<ab3e540d17429425c25d8a1a1a7d4644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type styleAuthorCardAuthor$data = {
  readonly " $fragmentSpreads": FragmentRefs<"authorAvatarAuthor" | "authorBioAuthor" | "authorNameAuthor" | "authorSocialLinksAuthor" | "entityFollowButtonBase">;
  readonly " $fragmentType": "styleAuthorCardAuthor";
};
export type styleAuthorCardAuthor$key = {
  readonly " $data"?: styleAuthorCardAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"styleAuthorCardAuthor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "styleAuthorCardAuthor",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorSocialLinksAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorAvatarAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorNameAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorBioAuthor"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityFollowButtonBase"
    }
  ],
  "type": "Author",
  "abstractKey": null
};

(node as any).hash = "c6a388fadd80370a9c31b6461eab3962";

export default node;
