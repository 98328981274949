import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext } from "react";
import { graphql, readInlineData } from "react-relay";

import type { contextsArticleListContextProviderArticle$key } from "scmp-app/queries/__generated__/contextsArticleListContextProviderArticle.graphql";

type ContextValue = {
  firstArticleEntityId: string | undefined;
};

type Props = {
  children: ReactNode;
  reference: contextsArticleListContextProviderArticle$key;
};

const ArticleListContext = createContext<ContextValue>({
  firstArticleEntityId: undefined,
});

export const ArticleListContextProvider: FunctionComponent<Props> = ({ children, reference }) => {
  const article = readInlineData(
    graphql`
      fragment contextsArticleListContextProviderArticle on Article @inline {
        entityId
      }
    `,
    reference,
  );

  return (
    <ArticleListContext.Provider value={{ firstArticleEntityId: article.entityId }}>
      {children}
    </ArticleListContext.Provider>
  );
};

export const useArticleListContext = () => useContext(ArticleListContext);
