import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BaseLink } from "shared/components/common/base-link";

import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";

export const SocialLink = styled(BaseLink)`
  padding-block: 0;
  padding-inline: 10px;
`;

export const AuthorSocialLinkContainer = styled.div`
  ${SocialLink}:first-child {
    padding-inline-start: 0;
  }
  ${SocialLink}:last-child {
    padding-inline-end: 0;
  }
`;

type StyledSocialLinkIconContainerProps = {
  $variant?: string;
};

export const StyledSocialLinkIconContainer = styled.div<StyledSocialLinkIconContainerProps>`
  ${props =>
    props.$variant === MagazinesStyleVariant
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;

          inline-size: 30px;
          block-size: 30px;

          border: 1px solid #222222;
          border-radius: 50%;

          * {
            transform: scale(0.9) translate(0.5px, 0);
          }
        `
      : null}
`;
