import styled from "@emotion/styled";
import { fontMerriweather, fontRoboto } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  display: grid;
  grid:
    "headline image" 1fr
    "counter image" min-content
    / 1fr 60px;
  gap: 8px 12px;

  min-inline-size: 280px;
  padding: 12px;

  border-radius: 4px;

  background: #ffffff;
`;

export const Headline = styled.div`
  grid-area: headline;

  ${Flag} {
    display: none;
  }

  ${StyledEntityLink} {
    color: #000000;
    font-weight: 700;
    font-size: 13px;
    font-family: ${fontMerriweather};
    font-style: normal;
    line-height: 140%;
  }
`;

export const CoverImage = styled.div`
  grid-area: image;

  inline-size: 60px;
  block-size: 60px;
`;

export const Counter = styled.div`
  grid-area: counter;

  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;

  color: #2c4692;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  font-style: italic;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;
`;

export const CurrentText = styled.div`
  color: #ff0000;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: italic;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;
`;
