/**
 * @generated SignedSource<<d7023d9c4108480e6dd348318b9c83ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type scmpArticleSpeechConfigQuery$variables = Record<PropertyKey, never>;
export type scmpArticleSpeechConfigQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
};
export type scmpArticleSpeechConfigQuery = {
  response: scmpArticleSpeechConfigQuery$data;
  variables: scmpArticleSpeechConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "entityId": "scmp_pwa_article_speech"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "json",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "scmpArticleSpeechConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "appConfig(filter:{\"entityId\":\"scmp_pwa_article_speech\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "scmpArticleSpeechConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AppConfig",
        "kind": "LinkedField",
        "name": "appConfig",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": "appConfig(filter:{\"entityId\":\"scmp_pwa_article_speech\"})"
      }
    ]
  },
  "params": {
    "cacheID": "f28cfa11628cdc431e269d6353dcbe7d",
    "id": null,
    "metadata": {},
    "name": "scmpArticleSpeechConfigQuery",
    "operationKind": "query",
    "text": "query scmpArticleSpeechConfigQuery {\n  appConfig(filter: {entityId: \"scmp_pwa_article_speech\"}) {\n    json\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb79bd0671f0b5a9d8985e9545e060d9";

export default node;
