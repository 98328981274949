import {
  FollowButtonDisplay,
  FollowButtonOutline,
  FollowButtonSize,
  FollowButtonVariant,
  FollowType,
} from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { usePlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/hooks";
import type { TypeMagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { useUserService } from "scmp-app/lib/user-service/hooks";
import type { articleTopicFollowWidgetArticle$key } from "scmp-app/queries/__generated__/articleTopicFollowWidgetArticle.graphql";

import type { StyledProps } from "./styles";
import {
  Container,
  Divider,
  PlusTopicLink,
  StyledEntityFollowButton,
  StyledTopicLink,
} from "./styles";

type Props = Partial<PropsFromStyledProps<StyledProps>> & {
  className?: string;
  reference: articleTopicFollowWidgetArticle$key;
  variant?: "banner" | "plus" | TypeMagazinesStyleVariant;
};

export const ArticleTopicFollowWidget: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  variant = "banner",
}) => {
  const { handleOpen } = usePlusDetailedListingPopup();
  const { topics } = useFragment(
    graphql`
      fragment articleTopicFollowWidgetArticle on Article {
        topics {
          ...topicLinkTopic
          ...entityFollowButtonBase
          disableFollow
          entityId
          name
          entityUuid
        }
      }
    `,
    reference_,
  );

  const { checkIsFollowed } = useUserService();

  if (!topics) return null;
  const mainTopic = first(topics);
  if (!mainTopic || mainTopic.disableFollow) return null;
  const isFollowed = checkIsFollowed(FollowType.Topic, mainTopic.entityId);

  const isBanner = variant === "banner";
  const isPlus = variant === "plus";

  const renderTopicLink = () => {
    switch (variant) {
      case "plus":
        return (
          <PlusTopicLink
            onClick={() => {
              handleOpen({ entityUuid: mainTopic.entityUuid, type: "topic" });
            }}
          >
            {mainTopic.name}
          </PlusTopicLink>
        );

      default:
        return <StyledTopicLink reference={mainTopic} />;
    }
  };

  return (
    <Container $isFollowed={isFollowed} $variant={variant} className={className}>
      {renderTopicLink()}
      <Divider />
      <StyledEntityFollowButton
        display={isPlus ? FollowButtonDisplay.BluePlusIcon : FollowButtonDisplay.Text}
        isLoadingEnabled={isPlus}
        outline={isBanner ? FollowButtonOutline.Plain : FollowButtonOutline.Outlined}
        reference={mainTopic}
        size={isBanner ? FollowButtonSize.Compact : FollowButtonSize.Small}
        source="Article_top"
        variant={FollowButtonVariant.Light}
      />
    </Container>
  );
};

ArticleTopicFollowWidget.displayName = "ArticleTopicFollowWidget";
