import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { Div } from "scmp-app/components/schema-render/common/div";
import { VideoTitleContainer } from "scmp-app/components/scmp-youtube-video/scmp-youtube-video-footer/styles";

export const ArticleLeadingVideoContainer = styled.div`
  ${theme.breakpoints.only("mobile")} {
    display: flex;
    align-items: center;

    /* NOTE: Need to control the inline-size of Div component which is the direct child,
             would rather apply it here than to all Div */
    > ${Div} {
      inline-size: 100%;
    }

    ${VideoTitleContainer} {
      display: none;
    }
  }
`;
