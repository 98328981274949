import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type {
  ContentSchemaRenderProps,
  RenderComponent,
} from "scmp-app/components/schema-render/content";
import type { blockquoteQuoteGenericPostiesArticleContent$key } from "scmp-app/queries/__generated__/blockquoteQuoteGenericPostiesArticleContent.graphql";

import GenericPostiesArticleEmailIcon from "./icon-email.svg";
import GenericPostiesArticleQuoteIcon from "./icon-quote.svg";
import GenericPostiesArticleSeparatorIcon from "./icon-separator.svg";
import {
  GenericPostiesArticleBlockquote,
  GenericPostiesArticleContentShareWidget,
  GenericPostiesArticleContentShareWidgetContainer,
  GenericPostiesArticleFigcaption,
  GenericPostiesArticleFigure,
  GenericPostiesArticleFooter,
} from "./styles";

type Props = ContentSchemaRenderProps & {
  reference?: blockquoteQuoteGenericPostiesArticleContent$key;
};

export const Component: FunctionComponent<Props> = ({ reference: reference_, schemaNode }) => {
  const content = useFragment(
    graphql`
      fragment blockquoteQuoteGenericPostiesArticleContent on Content {
        ...contentShareWidgetContent
      }
    `,
    reference_ ?? null,
  );
  if (!content) return null;

  const [quoteNode, sourceNode] = schemaNode.children ?? [];

  return (
    <GenericPostiesArticleFigure>
      <GenericPostiesArticleQuoteIcon />
      <GenericPostiesArticleBlockquote>{quoteNode?.data}</GenericPostiesArticleBlockquote>
      <GenericPostiesArticleFigcaption>{sourceNode?.data}</GenericPostiesArticleFigcaption>
      <GenericPostiesArticleFooter>
        <GenericPostiesArticleSeparatorIcon />
        <GenericPostiesArticleContentShareWidgetContainer>
          <GenericPostiesArticleContentShareWidget
            position="block_quote"
            reference={content}
            style={{
              /* stylelint-disable csstree/validator */
              /* stylelint-disable property-no-unknown */
              backgroundColor: "#0F75BF",
              color: "#ffffff",
              mobilePadding: 0,
              padding: 0,
              svgTransform: "scale(0.6)",
            }}
            types={["facebook", "twitter"]}
          />
          <GenericPostiesArticleContentShareWidget
            hideIcon
            position="block_quote"
            reference={content}
            types={["email"]}
          >
            <GenericPostiesArticleEmailIcon />
          </GenericPostiesArticleContentShareWidget>
        </GenericPostiesArticleContentShareWidgetContainer>
      </GenericPostiesArticleFooter>
    </GenericPostiesArticleFigure>
  );
};

export const GenericPostiesArticleBlockquoteQuote: RenderComponent<Props> = styled(Component)``;
GenericPostiesArticleBlockquoteQuote.isHandlingOwnChildren = true;
