import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { TopicLink } from "scmp-app/components/topic/topic-link";

import IconArrow from "./icon-arrow.svg";

export const Container = styled.div``;

type IconArrowProps = {
  $isToggle: boolean;
};

export const StyledIconArrow = styled(IconArrow)<IconArrowProps>`
  color: #666666;

  transform: rotate(${props => (props.$isToggle ? "180deg" : "0deg")});
`;

export const LocationItemLink = styled(TopicLink)`
  display: inline;

  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.2px;
  word-break: break-word;
`;

export const LocationGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16.41px;
  text-align: start;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const LocationItemContainer = styled.div`
  max-block-size: 104px;

  color: #cccccc;

  overflow-y: auto;

  ${LocationItemLink} {
    color: #666666;
    line-height: 25px;

    &:hover {
      color: #2c4692;
      text-decoration: underline;
    }
  }
`;
