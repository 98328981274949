import type { FunctionComponent, ReactNode } from "react";
import { useContext } from "react";
import { createPortal } from "react-dom";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

export const FooterPortal: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { footerPortalElement } = useContext(GalleryContext);
  return footerPortalElement ? createPortal(children, footerPortalElement) : null;
};

FooterPortal.displayName = "FooterPortal";
