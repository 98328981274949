import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

export const Container = styled.div`
  margin-block-end: 16px;

  svg {
    inline-size: 58px;
    block-size: 48px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-end: 0;
  }

  ${theme.breakpoints.up("largeDesktop")} {
    margin-block-end: 0;

    svg {
      inline-size: 72px;
      block-size: 60px;
    }
  }
`;
