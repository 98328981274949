import { useAtomValue } from "jotai/index";
import { useRouter } from "next/router";
import { useEffect } from "react";
import use from "react-promise-suspense";
import { graphql, useFragment } from "react-relay";

import { isSsr } from "shared/lib/utils";

import { checkIsContainsPlusQueryString } from "scmp-app/components/article/article-render/plus/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { appAtom } from "scmp-app/lib/app/atoms";
import { eventEmitter } from "scmp-app/lib/rosetta/event-emitter";
import { useRosettaPianoExecution } from "scmp-app/lib/rosetta/hooks/rosetta-piano-execution";
import type { hooksPaywallPromiseContent$key } from "scmp-app/queries/__generated__/hooksPaywallPromiseContent.graphql";

import { useArticlePianoPaywallContext } from "./contexts";
import type { ArticlePianoStates } from "./contexts";

export const usePaywallPromise = (content_: hooksPaywallPromiseContent$key) => {
  const { updateArticlePianoPaywallStateState } = useArticlePianoPaywallContext();
  const router = useRouter();

  const content = useFragment(
    graphql`
      fragment hooksPaywallPromiseContent on Content {
        entityId
        ...helpersCheckIsPostiesArticle
      }
    `,
    content_,
  );

  const isPosties = checkIsPostiesArticle(content);
  const isPlus = checkIsContainsPlusQueryString(router.query);

  const { navigatorInfo, navigatorInfoLoaded } = useAtomValue(appAtom);
  const { isRosettaPianoExecutionReady, shouldSkipPianoExecution } = useRosettaPianoExecution(
    navigatorInfo,
    navigatorInfoLoaded,
  );

  const hasSkippedPianoExecution = use(
    (isRosettaPianoExecutionReady: boolean, shouldSkipPianoExecution: boolean, _: string) => {
      if (!isRosettaPianoExecutionReady) return new Promise(() => {});
      return Promise.resolve(shouldSkipPianoExecution);
    },
    [isRosettaPianoExecutionReady, shouldSkipPianoExecution, "hasSkippedPianoExecution"],
  );

  const paywallState = use(
    (_entityId: string, _: string) => {
      if (isSsr() || isPosties || isPlus || hasSkippedPianoExecution === true)
        return Promise.resolve(undefined);

      return new Promise<ArticlePianoStates>(resolve => {
        const handler = (value: ArticlePianoStates) => {
          resolve(value);
          eventEmitter.removeListener("showPaywall", handler);
        };
        eventEmitter.on("showPaywall", handler);
      });
    },
    [content.entityId, "usePaywallPromise"],
  );

  useEffect(() => {
    if (paywallState === undefined) return;

    updateArticlePianoPaywallStateState?.({
      articleEntityId: content.entityId,
      state: paywallState,
    });
  }, [content.entityId, paywallState, updateArticlePianoPaywallStateState]);

  return paywallState;
};
