export const FormPrefixes = ["form-id", "form-latest"];
export const RequiredMessage = "This is a required question";

export const GetFormById = /* GraphQL */ `
  query getFormById($documentId: ID!) {
    getForm(documentId: $documentId) {
      documentId
      isExpired
      title
      description {
        json
        html
      }
      educations {
        documentId
        name
        schoolType
        isOther
      }
      questions {
        documentId
        answerType
        order
        wordLimit
        maxSelect
        required
        question {
          json
          html
        }
        options {
          value
        }
      }
      email {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      name {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      dateOfBirth {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      school {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      phone {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      address {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      yearOrGrade {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      facebook {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      instagram {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      photo {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      artwork {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
    }
  }
`;

export const GetLatestForm = /* GraphQL */ `
  query getLatestFormByType($typeId: ID!) {
    getLatestFormByType(typeId: $typeId) {
      documentId
      isExpired
      title
      description {
        json
        html
      }
      educations {
        documentId
        name
        schoolType
        isOther
      }
      questions {
        documentId
        answerType
        order
        wordLimit
        maxSelect
        required
        question {
          json
          html
        }
        options {
          value
        }
      }
      email {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      name {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      dateOfBirth {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      school {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      phone {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      address {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      yearOrGrade {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      facebook {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      instagram {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      photo {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
      artwork {
        validationType
        required
        display
        placeholder
        order
        title
        helperText
      }
    }
  }
`;
