import { notEmpty } from "@product/scmp-sdk";
import { useToggle } from "@react-hookz/web";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone, toISOString } from "shared/lib/utils";

import type { articleCorrectionsWidgetArticle$key } from "scmp-app/queries/__generated__/articleCorrectionsWidgetArticle.graphql";

import type { ContainerProps } from "./styles";
import {
  Container,
  CorrectionDate,
  Item,
  LatestCorrectionDate,
  LatestCorrectionDateContainer,
  List,
  StyledContentSchemaRender,
  StyledFade,
} from "./styles";

export type Props = {
  className?: string;
  reference: articleCorrectionsWidgetArticle$key;
  variant?: ContainerProps["$variant"];
};

export const ArticleCorrectionsWidget: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  variant = "light",
}) => {
  const article = useFragment(
    graphql`
      fragment articleCorrectionsWidgetArticle on Article {
        corrections {
          correction {
            json
          }
          timestamp
        }
        ...contentSchemaRenderContent
      }
    `,
    reference_,
  );

  const [isToggle, setToggle] = useToggle(false);
  const dateFormat = "h:mmaaa, d MMM, yyyy";

  const latestCorrectionTimestamp = useMemo(
    () => first(article.corrections)?.timestamp,
    [article.corrections],
  );

  if (!notEmpty(article.corrections)) return null;

  return (
    <Container $variant={variant} className={className}>
      <LatestCorrectionDateContainer $isToggle={isToggle} onClick={setToggle}>
        <LatestCorrectionDate>
          <time
            dateTime={
              latestCorrectionTimestamp ? toISOString(latestCorrectionTimestamp) : undefined
            }
          >
            <b>Corrected </b>[
            {latestCorrectionTimestamp && formatInTimeZone(latestCorrectionTimestamp, dateFormat)}]
          </time>
        </LatestCorrectionDate>
      </LatestCorrectionDateContainer>
      <StyledFade in={isToggle} timeout={500}>
        <List $isToggle={isToggle}>
          {article.corrections?.map(
            (correction, index) =>
              correction?.timestamp && (
                <Item key={index}>
                  <CorrectionDate>
                    [{formatInTimeZone(correction?.timestamp, dateFormat)}]
                  </CorrectionDate>
                  <StyledContentSchemaRender
                    reference={article}
                    schema={correction?.correction?.json}
                  />
                </Item>
              ),
          )}
        </List>
      </StyledFade>
    </Container>
  );
};

ArticleCorrectionsWidget.displayName = "ArticleCorrectionsWidget";
