import styled from "@emotion/styled";

import { Icon } from "shared/components/common/icon";

import { Button } from "scmp-app/components/common/button";

export const StyledIcon = styled(Icon)`
  color: var(--pswp-icon-color);
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 44px;
  block-size: 44px;

  :hover {
    ${StyledIcon} {
      opacity: 0.8;
    }
  }
`;
