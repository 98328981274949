import type { FunctionComponent, MutableRefObject, ReactNode } from "react";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";

type Context = {
  slotCount: MutableRefObject<number>;
};
const InlineOutstreamAdSlotContext = createContext<Context | undefined>(undefined);

type Props = { children?: ReactNode };
export const InlineOutstreamAdSlotContextProvider: FunctionComponent<Props> = ({ children }) => {
  const slotCount = useRef(0);
  const value = useMemo(() => ({ slotCount }), [slotCount]);
  return (
    <InlineOutstreamAdSlotContext.Provider value={value}>
      {children}
    </InlineOutstreamAdSlotContext.Provider>
  );
};

// Request by Amazon to have stable div id for integration with their system
export const useInlineOutstreamAdSlotDivId = () => {
  const index = useRef(0);
  const context = useContext(InlineOutstreamAdSlotContext);
  if (context === undefined) {
    throw new Error("InlineOutstreamAdSlotContextProvider need to be rendered in the ancestor.");
  }

  const { slotCount } = context;
  useEffect(() => {
    index.current = slotCount.current;
    slotCount.current = slotCount.current + 1;
    return () => {
      slotCount.current = slotCount.current - 1;
    };
  }, [slotCount]);

  return `article-inline-outstream-ad-slot-${index.current}`;
};
