import {
  notEmpty,
  theme,
  useResponsive,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import take from "lodash/take";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Pagination } from "swiper/modules";

import { articleSponsorType } from "shared/data/article";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useOptimizelyContext } from "scmp-app/lib/optimizely";
import { rosettaAtom } from "scmp-app/lib/rosetta/atoms";
import type { articleFurtherReadingWidgetArticle$key } from "scmp-app/queries/__generated__/articleFurtherReadingWidgetArticle.graphql";

import {
  AdSlot,
  Container,
  FurtherReadingContainer,
  StyledContentItemArticleWidget,
  StyledSwiper,
  StyledSwiperSlide,
  Title,
} from "./styles";

type Props = {
  className?: string;
  inlineAds?: ReactNode;
  reference: articleFurtherReadingWidgetArticle$key;
  variant?: "inline" | "right-hand-side";
};

export const ArticleFurtherReadingWidget: FunctionComponent<Props> = ({
  className,
  inlineAds,
  reference,
  variant,
}) => {
  const article = useFragment(
    graphql`
      fragment articleFurtherReadingWidgetArticle on Article {
        sponsorType
        moreOnThisArticles {
          ...articleWidgetContent
        }
      }
    `,
    reference,
  );

  const { fireEvent } = useOptimizelyContext();

  const isDesktop = useResponsive(theme.breakpoints.up("desktop"));
  const isMobile = useResponsive(theme.breakpoints.only("mobile"));
  const asyncRosettaState = useAtomValue(rosettaAtom);

  const isMatchVariant = useMemo(
    () => (isDesktop && variant === "right-hand-side") || (!isDesktop && variant === "inline"),
    [isDesktop, variant],
  );

  const isSubscriber = useMemo(
    () => asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false,
    [asyncRosettaState?.result?.user?.isSCMPSubscriber],
  );

  const moreOnThisArticles = useMemo(
    () => take(article.moreOnThisArticles?.filter(notEmpty) ?? [], isSubscriber ? 4 : 3),
    [article.moreOnThisArticles, isSubscriber],
  );

  const isShowFurtherReading = useMemo(() => notEmpty(moreOnThisArticles), [moreOnThisArticles]);

  const shouldShowSwiper = useMemo(() => isMobile && !isSubscriber, [isMobile, isSubscriber]);

  const readMoreGA4ImpressionEvent: () => RecirculationWidgetImpressionEvent = () =>
    ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "read_more",
        widget_version: isSubscriber ? "subscriber" : "non_subscriber",
        ...(shouldShowSwiper ? { widget_tab: 1 } : {}),
      },
      subcategory: "widget",
    }) as const;

  const {
    captureTrackImpressionEventTargetElement: readMoreCaptureTrackImpressionEventTargetElement,
  } = useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
    ga4TrackingHandler: sendGA4Tracking,
    getGa4Event: readMoreGA4ImpressionEvent,
    options: {
      intersectionThreshold: [0.1],
      isSendGA4Tracking: true,
      shouldSendOnce: true,
      shouldThrottle: true,
    },
  });

  const getFurtherReadingContentItemArticleWidgetClickHandler = useCallback(() => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "read_more",
        widget_version: isSubscriber ? "subscriber" : "non_subscriber",
        ...(shouldShowSwiper ? { widget_tab: 1 } : {}),
      },
      subcategory: "widget",
    });
    fireEvent?.({
      eventName: "item_click",
    });
  }, [fireEvent, isSubscriber, shouldShowSwiper]);

  const renderFurtherReading = useCallback(
    () => (
      <FurtherReadingContainer>
        {isShowFurtherReading && (
          <>
            <div ref={readMoreCaptureTrackImpressionEventTargetElement} />
            <Title>Further Reading</Title>
            {moreOnThisArticles.map((node, index) => (
              <StyledContentItemArticleWidget
                key={index}
                onClick={getFurtherReadingContentItemArticleWidgetClickHandler}
                query={{
                  module: "further_reading_RM",
                  pgtype: "article",
                }}
                reference={node}
                withCoverImage={false}
              />
            ))}

            {inlineAds && isShowFurtherReading && <AdSlot>{inlineAds}</AdSlot>}
          </>
        )}
      </FurtherReadingContainer>
    ),
    [
      getFurtherReadingContentItemArticleWidgetClickHandler,
      inlineAds,
      isShowFurtherReading,
      moreOnThisArticles,
      readMoreCaptureTrackImpressionEventTargetElement,
    ],
  );

  const render = useCallback(() => <>{renderFurtherReading()}</>, [renderFurtherReading]);

  const renderSwiper = useCallback(() => {
    const children = [renderFurtherReading()].filter(notEmpty);
    return (
      <StyledSwiper
        breakpoints={{
          0: {
            spaceBetween: 20,
          },
        }}
        modules={[Pagination]}
        pagination={true}
      >
        {children.map((child, index) => (
          <StyledSwiperSlide key={index}>{child}</StyledSwiperSlide>
        ))}
      </StyledSwiper>
    );
  }, [renderFurtherReading]);

  const isSponsoredContent = articleSponsorType.has(article.sponsorType ?? "");

  if (isSponsoredContent || !isMatchVariant) return null;

  return (
    isShowFurtherReading && (
      <Container $isSubscriber={isSubscriber} className={className}>
        {isMobile ? renderSwiper() : render()}
      </Container>
    )
  );
};

ArticleFurtherReadingWidget.displayName = "ArticleFurtherReadingWidget";
