import styled from "@emotion/styled";
import { fontRoboto, transientOptions } from "@product/scmp-sdk";

import { AuthorNames } from "scmp-app/components/article/article-author-block/author-names";
import { AuthorLocation } from "scmp-app/components/article/article-author-block/author-names/styles";
import { ArticleDate } from "scmp-app/components/article/article-date";
import {
  PublishedDateContainer,
  UpdatedDateContainer,
} from "scmp-app/components/article/article-date/default-article-date/styles";
import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import { StyledAuthorName } from "scmp-app/components/author/author-name/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${StyledAuthorName} {
    color: #001246;

    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledAuthorNames = styled(AuthorNames)`
  margin-block-end: 20px;

  color: #333333;
  font-size: 14px;
  line-height: 16px;

  ${AuthorLocation} {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  margin-block-end: 8px;
`;

type AuthorAvatarProps = {
  $zIndex: number;
};

export const StyledAuthorAvatar = styled(AuthorAvatar, {
  ...transientOptions,
})<AuthorAvatarProps>`
  inline-size: 50px;
  block-size: 50px;

  cursor: pointer;
`;

export const StyledArticleDate = styled(ArticleDate)`
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-family: ${fontRoboto};
  line-height: 16px;

  ${UpdatedDateContainer} {
    margin-block-start: 4px;
  }

  ${PublishedDateContainer} {
    &:after {
      margin-block-start: 4px;
      border-inline-end: 6px solid transparent;
      border-block-end: 6px solid #001246;
      border-inline-start: 6px solid transparent;
    }
  }
`;
