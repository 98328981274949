import { useState } from "react";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as ArticleSpeechInlineWidgetGenericButtonProps } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/button";
import type { VariantProps } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/types";
import type { genericArticleSpeechInlineWidgetGenericArticle$key } from "scmp-app/queries/__generated__/genericArticleSpeechInlineWidgetGenericArticle.graphql";

import { ArticleSpeechInlineWidgetGenericButton } from "./button";
import { EmptyButton } from "./empty-button";
import {
  useEnableGenericInlineWidgetByUserSubscription,
  useShowGenericInlineWidgetByArticle,
} from "./hooks";
import { Container, Wrapper } from "./styles";
import { ArticleSpeechWidgetToolTip } from "./tool-tip";

export type Props = Omit<
  ArticleSpeechInlineWidgetGenericButtonProps,
  "entityId" | "reference" | "setIsTooltipActive"
> &
  VariantProps & {
    reference: genericArticleSpeechInlineWidgetGenericArticle$key;
  };

export const ArticleSpeechInlineWidgetGeneric: FunctionComponent<Props> = ({
  className,
  reference,
  ...props
}) => {
  const article = useFragment(
    graphql`
      fragment genericArticleSpeechInlineWidgetGenericArticle on Article {
        entityId
        ...buttonArticleSpeechInlineWidgetGenericButtonArticle
        ...hooksShowGenericInlineWidgetByArticleArticle
        ...toolTipArticleSpeechWidgetToolTipArticle
      }
    `,
    reference,
  );
  const { isWidgetVisibleByType, scmpArticleSpeechConfigState } =
    useShowGenericInlineWidgetByArticle(article);
  const { isWidgetEnabledByUser } = useEnableGenericInlineWidgetByUserSubscription();
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const handleRenderArticleSpeechWidget = () => {
    if (scmpArticleSpeechConfigState === undefined || isWidgetEnabledByUser === undefined)
      return <EmptyButton className={className} isMinify={props.isMinify} />;
    else if (isWidgetVisibleByType)
      return (
        <Container className={className}>
          <ArticleSpeechWidgetToolTip
            isTooltipActive={isTooltipActive}
            reference={article}
            setIsTooltipActive={setIsTooltipActive}
          >
            <ArticleSpeechInlineWidgetGenericButton
              {...props}
              entityId={article.entityId}
              reference={article}
              setIsTooltipActive={setIsTooltipActive}
            />
          </ArticleSpeechWidgetToolTip>
        </Container>
      );
    return null;
  };

  return <Wrapper $isMinify={props.isMinify}>{handleRenderArticleSpeechWidget()}</Wrapper>;
};

ArticleSpeechInlineWidgetGeneric.displayName = "ArticleSpeechInlineWidgetGeneric";
