import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";
import type { articleBeforeYouGoWidget$key } from "scmp-app/queries/__generated__/articleBeforeYouGoWidget.graphql";

import { Container, Divider, StyledBeforeYouGoActionWidgets, Title } from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
  provider: DefaultComponentsProvider;
  reference: articleBeforeYouGoWidget$key;
};

export const ArticleBeforeYouGoWidget: FunctionComponent<Props> = ({
  children,
  className,
  provider,
  reference,
}) => {
  const article = useFragment(
    graphql`
      fragment articleBeforeYouGoWidget on Content {
        ...beforeYouGoActionWidgetContent
      }
    `,
    reference,
  );

  return (
    <Container className={className}>
      <Title>Before you go</Title>
      {provider.commentTrigger({ variant: "article-2.0-banner" })}
      <StyledBeforeYouGoActionWidgets provider={provider} reference={article} />
      {children && (
        <>
          <Divider />
          {children}
        </>
      )}
    </Container>
  );
};

ArticleBeforeYouGoWidget.displayName = "ArticleBeforeYouGoWidget";
