import type { DrawerProps } from "@mui/material/Drawer";
import MuiDrawer from "@mui/material/Drawer";
import type { FunctionComponent } from "react";

import { ZIndexLayer } from "shared/lib/styles";

import CloseIcon from "./icon-close.svg";
import { DrawerCard, DrawerCardHeader, DrawerCardTitle, DrawerCloseContainer } from "./styles";

export type Props = DrawerProps & {
  title?: string;
  withClose?: boolean;
  withDrawerCardHeader?: boolean;
};

export const Drawer: FunctionComponent<Props> = ({
  anchor = "bottom",
  children,
  onClose,
  PaperProps = {},
  sx = {},
  title,
  withClose,
  withDrawerCardHeader = true,
  ...props
}) => (
  <MuiDrawer
    anchor={anchor}
    onClose={onClose}
    PaperProps={{
      ...PaperProps,
    }}
    sx={{
      zIndex: `${ZIndexLayer.Drawer}`,
      ...sx,
    }}
    {...props}
  >
    <DrawerCard>
      {withDrawerCardHeader && (
        <DrawerCardHeader>
          {title && <DrawerCardTitle>{title}</DrawerCardTitle>}

          {withClose && (
            <DrawerCloseContainer
              onClick={event => {
                if (onClose) onClose(event, "backdropClick");
              }}
            >
              <CloseIcon />
            </DrawerCloseContainer>
          )}
        </DrawerCardHeader>
      )}

      {children}
    </DrawerCard>
  </MuiDrawer>
);

Drawer.displayName = "Drawer";
