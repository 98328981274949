import { type FunctionComponent } from "react";
import { Mousewheel } from "swiper/modules";

import { StyledSwiper, StyledSwiperSlide } from "./styles";

type Props = {
  className?: string;
  items: React.ReactNode[];
};

export const ArticleCarouselWidget: FunctionComponent<Props> = ({ className, items }) => {
  if (items.length === 0) return null;
  return (
    <StyledSwiper
      breakpoints={{
        0: {
          spaceBetween: 12,
        },
      }}
      className={className}
      freeMode={true}
      modules={[Mousewheel]}
      slidesPerGroupAuto={false}
      slidesPerView="auto"
    >
      {items.map((item, index) => (
        <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
      ))}
    </StyledSwiper>
  );
};

ArticleCarouselWidget.displayName = "ArticleCarouselWidget";
