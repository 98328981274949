import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontFredoka, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { OffsetContainer } from "scmp-app/components/posties/offset-container";

const ButtonStyle = css`
  position: relative;

  padding-block: 10px;
  padding-inline: 24px;

  color: #000000;
  font-weight: 600;
  font-size: 14px;
  font-family: ${fontFredoka};
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;

  background-color: transparent;

  cursor: pointer;

  ${theme.breakpoints.up("tablet")} {
    padding-block: 12px;
    padding-inline: 24px;

    font-size: 16px;
  }
`;

export const StyledButton = styled.button`
  ${ButtonStyle}
`;

export const StyledLinkButton = styled(BaseLink)`
  ${ButtonStyle}
`;

export const StyledOffsetContainer = styled(OffsetContainer)``;
