import styled from "@emotion/styled";

import { ContentItemArticleWidget } from "scmp-app/components/content/content-item-render/variants/article-widget";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  margin-block-end: 12px;
`;

export const LocationName = styled(EntityLink)`
  display: flex;
  gap: 4px;
  align-items: center;

  margin-block: 8px 12px;

  color: #000000;
  font-weight: 700;
  font-size: 20px;
`;

export const StyledContentItemArticleWidget = styled(ContentItemArticleWidget)``;

export const ContentContainer = styled.div`
  margin-block-start: 12px;
  ${StyledContentItemArticleWidget}:not(:last-child) {
    margin-block-end: 12px;
    padding-block-end: 12px;
    border-block-end: 1px solid #cccccc;
  }
`;

export const Divider = styled.div`
  inline-size: 100%;
  block-size: 1px;

  background: #333333;
`;
