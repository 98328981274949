import styled from "@emotion/styled";

import { ContentShareWidget } from "scmp-app/components/content/content-share-widget";

import IconPrint from "./icon-print.svg";

export const StyledIconPrint = styled(IconPrint)`
  border-radius: 50%;

  cursor: pointer;

  :hover {
    background-color: #dddddd;

    path {
      fill: #dddddd;
    }
  }
`;

export const StyledContentShareWidget = styled(ContentShareWidget)`
  inline-size: 36px;
  block-size: 36px;
`;
