import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import type {
  Sentiment,
  SentimentTargeting,
  Targeting,
} from "scmp-app/components/advertisement/ad-slots/types";
import {
  sentimentTargetingKeys,
  TargetingPaidType,
} from "scmp-app/components/advertisement/ad-slots/types";
import { checkIsMainTopic } from "scmp-app/components/content/helpers";
import type { articleBaseAdvertisingInfoArticle$key } from "scmp-app/queries/__generated__/articleBaseAdvertisingInfoArticle.graphql";

export const useArticleBaseAdvertisingInfo = (article_: articleBaseAdvertisingInfoArticle$key) => {
  const article = useFragment(
    graphql`
      fragment articleBaseAdvertisingInfoArticle on Article {
        advertZone(version: 2)
        entityId
        sentiment
        contentLock
        topics {
          entityId
          sponsor {
            __typename
          }
        }
        types {
          value {
            entityId
          }
        }
        sections {
          value {
            entityId
          }
        }
        ...helpersCheckIsMainTopicContent
      }
    `,
    article_,
  );

  const articleBaseAdvertisingInfo = useMemo<{
    targeting: Targeting;
    zone?: string;
  }>(() => {
    const mainTopic = article.topics?.find(topic => checkIsMainTopic(article, topic?.entityId));
    const computeSentimentTargeting = () => {
      const sentiment = article.sentiment as null | Sentiment;
      if (sentiment === null) return {};
      const filtered = pick(sentiment, sentimentTargetingKeys);
      const mapped: SentimentTargeting = mapValues(filtered, value => value?.toString());
      return mapped;
    };

    return {
      targeting: {
        articletype:
          first(article.types?.filter(notEmpty))
            ?.value?.filter(notEmpty)
            .map(type => type?.entityId) ?? "DEFAULT",
        paid: (() => {
          if (mainTopic?.sponsor) {
            return TargetingPaidType.Sponsored;
          } else if (article.contentLock) {
            return TargetingPaidType.Paid;
          } else {
            return TargetingPaidType.Free;
          }
        })(),
        scnid: article.entityId,
        scsid: first(article.sections?.filter(notEmpty))
          ?.value?.filter(notEmpty)
          .map(section => section.entityId),
        sctid: mainTopic?.entityId,
        ...computeSentimentTargeting(),
      },
      zone: findFirstNonEmptyString(article.advertZone, "default"),
    };
  }, [article]);

  return articleBaseAdvertisingInfo;
};
