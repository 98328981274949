import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ZIndexLayer } from "shared/lib/styles";

import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech/article-speech-skip-container";
import type { articleSpeechInlineWidgetArticle$key } from "scmp-app/queries/__generated__/articleSpeechInlineWidgetArticle.graphql";

import { ArticleSpeechInlineWidgetArticle20 } from "./variants/article-2.0";
import { ArticleSpeechInlineWidgetGeneric } from "./variants/generic";
import type { HeadphoneIconTheme } from "./variants/generic/button/headphone-icon";
import { ArticleSpeechInlineWidgetPosties } from "./variants/posties";

export type ArticleSpeechInlineWidgetProps = {
  className?: string;
  headphoneIconTheme?: HeadphoneIconTheme;
  onClick?: () => void;
  reference: articleSpeechInlineWidgetArticle$key;
  variant: "article-2.0-minify" | "generic" | "generic-minify" | "posties";
  withMinifyCleanUpButton?: boolean;
  withMinifyText?: boolean;
  zIndex?: string;
};

export const ArticleSpeechInlineWidget: FunctionComponent<ArticleSpeechInlineWidgetProps> = ({
  className,
  headphoneIconTheme = "grey",
  onClick,
  reference,
  variant,
  withMinifyCleanUpButton = true,
  withMinifyText = true,
  zIndex = `${ZIndexLayer.Drawer - 1}`,
}) => {
  const article = useFragment(
    graphql`
      fragment articleSpeechInlineWidgetArticle on Article {
        entityId
        ...genericArticleSpeechInlineWidgetGenericArticle
      }
    `,
    reference,
  );

  const handleRenderInlineWidget = () => {
    switch (variant) {
      case "article-2.0-minify":
        return (
          <ArticleSpeechInlineWidgetArticle20
            className={className}
            extendClick={onClick}
            headphoneIconTheme={headphoneIconTheme}
            isMinify={true}
            keepMinifyText={true}
            reference={article}
            withMinifyCleanUpButton={withMinifyCleanUpButton}
            withMinifyText={withMinifyText}
            zIndex={zIndex}
          />
        );
      case "generic-minify":
        return (
          <ArticleSpeechInlineWidgetGeneric
            className={className}
            extendClick={onClick}
            headphoneIconTheme={headphoneIconTheme}
            isMinify={true}
            reference={article}
            withMinifyText={withMinifyText}
            zIndex={zIndex}
          />
        );
      case "posties":
        return (
          <ArticleSpeechInlineWidgetPosties className={className} entityId={article.entityId} />
        );
      default:
        return (
          <ArticleSpeechInlineWidgetGeneric
            className={className}
            extendClick={onClick}
            headphoneIconTheme={headphoneIconTheme}
            reference={article}
            zIndex={zIndex}
          />
        );
    }
  };
  return <ArticleSpeechSkipContainer>{handleRenderInlineWidget()}</ArticleSpeechSkipContainer>;
};

ArticleSpeechInlineWidget.displayName = "ArticleSpeechInlineWidget";
