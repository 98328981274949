import { ClickAwayListener } from "@mui/material";
import { useAtomValue } from "jotai";
import type { Dispatch, FunctionComponent, SetStateAction } from "react";
import { graphql, useFragment } from "react-relay";

import { config } from "shared/data/configs";
import { accountAtom } from "shared/lib/account";

import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { toolTipArticleSpeechWidgetToolTipArticle$key } from "scmp-app/queries/__generated__/toolTipArticleSpeechWidgetToolTipArticle.graphql";

import {
  ClickAwayListenerWrapper,
  Link,
  LogInText,
  StyledClose,
  StyledTooltip,
  Text,
  ToolTipMessage,
} from "./styles";

type Props = {
  children: JSX.Element;
  isTooltipActive: boolean;
  reference: toolTipArticleSpeechWidgetToolTipArticle$key;
  setIsTooltipActive: Dispatch<SetStateAction<boolean>>;
};

export const ArticleSpeechWidgetToolTip: FunctionComponent<Props> = ({
  children,
  isTooltipActive,
  reference,
  setIsTooltipActive,
}) => {
  const article = useFragment(
    graphql`
      fragment toolTipArticleSpeechWidgetToolTipArticle on Article {
        entityId
      }
    `,
    reference,
  );

  const { isLoggedIn } = useAtomValue(accountAtom);
  const { openLoginDialog } = useLoginDialogStateHelper();
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const subscriberPhase = asyncRosettaState?.result?.tracking?.ga4.subscriberPhase;

  const handleLoginClick = () => {
    sendGA4Tracking({
      action: "click",
      category: "subs",
      customized_parameters: {
        article_id: article.entityId,
        call_to_action: "sign in",
        trigger_point: "text_to_speech",
      },
      subcategory: "entry_point",
    });

    openLoginDialog({
      description: "Sign in to enjoy our audio option, only for subscribers.",
      destination: window.location.href,
      ga4CustomParameter: {
        trigger_point: "text_to_speech",
      },
      title: "Listen to this article",
    });
  };

  const handleSubscriptionClickEvent = () => {
    sendGA4Tracking({
      action: "click",
      category: "subs",
      customized_parameters: {
        article_id: article.entityId,
        call_to_action: "subscribe",
        subscriber_phase: subscriberPhase,
        trigger_point: "text_to_speech",
      },
      subcategory: "entry_point",
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setIsTooltipActive(false)}>
      <ClickAwayListenerWrapper>
        <StyledTooltip
          arrow
          open={isTooltipActive}
          placement="top"
          title={
            <ToolTipMessage>
              <Text>
                Our audio option is only available for subscribers.{" "}
                {!isLoggedIn && (
                  <>
                    <LogInText onClick={() => handleLoginClick()}>LOG IN</LogInText> or{" "}
                  </>
                )}
                <Link
                  href={`${config.rosetta.subscribeHost}`}
                  onClick={() => handleSubscriptionClickEvent()}
                >
                  SUBSCRIBE NOW
                </Link>
              </Text>
              <StyledClose
                onClick={event => {
                  setIsTooltipActive(false);
                  event?.stopPropagation();
                }}
              />
            </ToolTipMessage>
          }
        >
          {children}
        </StyledTooltip>
      </ClickAwayListenerWrapper>
    </ClickAwayListener>
  );
};

ArticleSpeechWidgetToolTip.displayName = "ArticleSpeechWidgetToolTip";
