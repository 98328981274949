import styled from "@emotion/styled";

import type { ThemeType } from "scmp-app/components/article/article-render/theme";

type ThemeProps = {
  theme?: ThemeType;
};

export const StyledDiv = styled.div<ThemeProps>`
  inline-size: 28px;
  block-size: 6px;
  margin-block-end: 5px;

  background-color: ${props => props.theme?.articleTypeColor ?? "#ffca05"};

  opacity: 0.96;
`;
