import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";

import { ZIndexLayer } from "shared/lib/styles";

import { Drawer } from "scmp-app/components/common/drawer";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import { StyledSubscriptionWidget } from "./styles";

export const PostiesPaywallContainer: FunctionComponent = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const shouldShowPaywall =
    asyncRosettaState?.result?.hasPianoExperienceExecuted &&
    !asyncRosettaState?.result?.posties?.disablePaywall &&
    !asyncRosettaState?.result?.hasPostiesAccessRight &&
    asyncRosettaState?.result?.contentServiceUserInfo?.isSubscriptionEnabled === true;

  if (!shouldShowPaywall) {
    return null;
  }

  return (
    <Drawer
      anchor="bottom"
      open={true}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
      }}
      sx={{
        zIndex: `${ZIndexLayer.AppBar - 1}`,
      }}
    >
      <StyledSubscriptionWidget variant="paywall" />
    </Drawer>
  );
};

PostiesPaywallContainer.displayName = "PostiesPaywallContainer";
