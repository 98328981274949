import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { inlineArticleSeriesInlineArticle$key } from "scmp-app/queries/__generated__/inlineArticleSeriesInlineArticle.graphql";

import { Container, Counter, Headline, StyledEntityLink, Title } from "./styles";

type Props = {
  className?: string;
  reference: inlineArticleSeriesInlineArticle$key;
};

export const ArticleSeriesInline: FunctionComponent<Props> = ({ className, reference }) => {
  const article = useFragment(
    graphql`
      fragment inlineArticleSeriesInlineArticle on Article {
        entityId
        seriesContainer {
          entityId
          headline
          socialHeadline
          urlAlias
          series {
            ... on Content {
              entityId
            }
          }
          ...entityLink
        }
      }
    `,
    reference,
  );

  const counter = useMemo(() => {
    const total = article.seriesContainer?.series?.length;
    const current = article.seriesContainer?.series?.findIndex(
      node => node?.entityId === article.entityId,
    );
    if (total === undefined || current === undefined) return null;
    return `[ ${current + 1} of ${total} ]`;
  }, [article.entityId, article.seriesContainer?.series]);

  const handleClickTracking = useCallback((articleId?: string, url?: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: articleId,
        destination_url: url,
        page_type: "article",
        widget_name: "series_recirculation",
      },
      subcategory: "widget",
    });
  }, []);

  if (!notEmpty(article.seriesContainer)) return null;

  return (
    <Container className={className}>
      <Title>SCMP Series</Title>
      <Headline>
        <StyledEntityLink
          onClick={() =>
            handleClickTracking(
              article.seriesContainer?.entityId,
              article.seriesContainer?.urlAlias,
            )
          }
          reference={article.seriesContainer}
        >
          {findFirstNonEmptyString(
            article.seriesContainer?.socialHeadline,
            article.seriesContainer?.headline,
          )}
        </StyledEntityLink>
      </Headline>
      <Counter>{counter}</Counter>
    </Container>
  );
};

ArticleSeriesInline.displayName = "ArticleSeriesInline";
