import styled from "@emotion/styled";

import { ZIndexLayer } from "shared/lib/styles";

import { Popper } from "scmp-app/components/common/popper";

import IconShare from "./icon-share.svg";

export const StyledIconShare = styled(IconShare)``;

export const StyledPopper = styled(Popper)`
  z-index: ${ZIndexLayer.Tooltip};
`;

export const Container = styled.div`
  inline-size: 36px;
  block-size: 36px;

  ${StyledIconShare} {
    border-radius: 50%;

    cursor: pointer;

    :hover {
      background-color: #dddddd;
    }
  }
`;
