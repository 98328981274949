/**
 * @generated SignedSource<<4e449fdbf282bbd877d95220134c7708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type toolTipArticleSpeechWidgetToolTipArticle$data = {
  readonly entityId: string;
  readonly " $fragmentType": "toolTipArticleSpeechWidgetToolTipArticle";
};
export type toolTipArticleSpeechWidgetToolTipArticle$key = {
  readonly " $data"?: toolTipArticleSpeechWidgetToolTipArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"toolTipArticleSpeechWidgetToolTipArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "toolTipArticleSpeechWidgetToolTipArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "9ce3c40a4e2cb15bd30d5cf63d2b8548";

export default node;
