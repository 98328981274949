import Fade from "@mui/material/Fade";
import type { ShareButtonProps, SocialShareType } from "@product/scmp-sdk";
import { ShareButton } from "@product/scmp-sdk";
import { bindPopper, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import type { FunctionComponent } from "react";

import { ToolbarButton } from "scmp-app/components/image-gallery/toolbar/toolbar-button";

import { MenuItem, ShareContainer, StyledPaper, StyledPopper } from "./styles";

export type Props = Pick<ShareButtonProps, "relativeUrl" | "shortUrl" | "title" | "utm">;

export const ToolbarButtonShare: FunctionComponent<Props> = ({
  relativeUrl,
  shortUrl,
  title,
  utm,
}) => {
  const popupState = usePopupState({
    popupId: "gallery-share-menu",
    variant: "popper",
  });

  const renderMenuItems = () => {
    const socialShareTypes: SocialShareType[] = ["facebook", "twitter", "email"];
    return socialShareTypes.map(type => (
      <MenuItem key={type}>
        <ShareButton
          hideIcon
          relativeUrl={relativeUrl}
          shortUrl={shortUrl}
          showLabel
          title={title}
          type={type}
          utm={utm}
        />
      </MenuItem>
    ));
  };

  return (
    <>
      <ShareContainer onClick={() => popupState.isOpen && popupState.close()}>
        <StyledPopper
          bindType="toggle"
          {...bindPopper(popupState)}
          popupComponent={({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={100}>
              <StyledPaper>{renderMenuItems()}</StyledPaper>
            </Fade>
          )}
          transition
          triggerComponent={
            <span {...bindTrigger(popupState)}>
              <ToolbarButton icon="share" title="Share" />
            </span>
          }
        />
      </ShareContainer>
    </>
  );
};

ToolbarButtonShare.displayName = "ToolbarButtonShare";
