import {
  findFirstNonEmptyString,
  notEmpty,
  useTrackImpressionEventByIntersection,
} from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { getAbsoluteUrl } from "shared/lib/utils";

import { BaseImage } from "scmp-app/components/common/base-image";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { articleMoreOnThisListArticle$key } from "scmp-app/queries/__generated__/articleMoreOnThisListArticle.graphql";

import { useTracking } from "./hooks";
import type { StyledProps } from "./styles";
import {
  Item,
  StyledContainer,
  StyledEntityLink,
  StyledFigure,
  StyledHeader,
  StyledTitle,
} from "./styles";

export type Props = Partial<PropsFromStyledProps<StyledProps>> & {
  className?: string;
  reference: articleMoreOnThisListArticle$key;
};

export const ArticleMoreOnThisList: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  variant = "horizontal",
}) => {
  const { trackClick } = useTracking();

  const { moreOnThisArticles } = useFragment(
    graphql`
      fragment articleMoreOnThisListArticle on Article {
        moreOnThisArticles {
          ...entityLink
          entityId
          headline
          socialHeadline
          urlAlias
          images {
            url
            style(filter: { style: "237x147" }) {
              url
            }
          }
        }
      }
    `,
    reference_,
  );

  const articles = moreOnThisArticles?.filter(notEmpty);

  const ga4ImpressionEvent = useCallback<() => RecirculationWidgetImpressionEvent>(
    () => ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "read_more",
      },
      subcategory: "widget",
    }),
    [],
  );

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: {
        intersectionThreshold: [0.1],
        isSendGA4Tracking: true,
        shouldSendOnce: true,
        shouldThrottle: true,
      },
    });

  const renderImage = (article: Article) => {
    const mainImage = first(article.images);
    const source = mainImage?.style?.url ?? mainImage?.url;
    return (
      source && (
        <BaseImage
          $aspectRatio="237 / 147"
          $height="150px"
          $objectFit="cover"
          $width="220px"
          alt={article.headline ?? undefined}
          disableLinkingData
          src={source}
        />
      )
    );
  };

  const renderTitle = (article: Article) => (
    <StyledTitle>{findFirstNonEmptyString(article.socialHeadline, article.headline)}</StyledTitle>
  );

  if (!articles?.length) return null;

  return (
    <StyledContainer
      $variant={variant}
      className={className}
      ref={captureTrackImpressionEventTargetElement}
    >
      {articles.map(article => (
        <Item key={article.entityId}>
          <StyledEntityLink
            onClick={() => {
              trackClick(getAbsoluteUrl(article?.urlAlias), article.entityId);
            }}
            reference={article}
          >
            <StyledFigure>
              <StyledHeader>Read more</StyledHeader>
              {renderImage(article)}
              {renderTitle(article)}
            </StyledFigure>
          </StyledEntityLink>
        </Item>
      ))}
    </StyledContainer>
  );

  type Article = Exclude<typeof articles, undefined>[number];
};

ArticleMoreOnThisList.displayName = "ArticleMoreOnThisList";
