import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import {
  StyledImage as AuthorAvatarImage,
  StyledPlaceholder as AuthorPlaceholder,
} from "scmp-app/components/author/author-avatar/styles";
import { AuthorBio } from "scmp-app/components/author/author-bio";
import { AuthorName } from "scmp-app/components/author/author-name";
import { AuthorRole } from "scmp-app/components/author/author-role";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";
import {
  StyledDesktopEntityFollowButton,
  StyledMobileEntityFollowButton,
} from "scmp-app/components/home/follow-button/styles";

export const AuthorCardContainer = styled.div`
  display: grid;
  grid:
    "avatar . header" min-content
    "avatar . ." 8px
    "avatar . bio" min-content
    / min-content 12px auto;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "avatar . header" min-content
      "avatar . ." 8px
      "avatar . bio" min-content
      / min-content 20px auto;
  }
`;

export const AvatarContainer = styled.div`
  grid-area: avatar;

  ${AuthorAvatarImage}, ${AuthorPlaceholder} {
    inline-size: 40px;
    block-size: 40px;
  }
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  ${StyledMobileEntityFollowButton} {
    display: block;
  }
  ${StyledDesktopEntityFollowButton} {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  grid-area: header;

  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }

  ${theme.breakpoints.down("tablet")} {
    margin-block-end: 8px;
  }
`;

export const NameAndPositionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

export const StyledAuthorRole = styled(AuthorRole)`
  color: #333333;
`;

export const StyledAuthorName = styled(AuthorName)`
  line-height: 16px;
  text-decoration: underline;
`;

export const BioContainer = styled.div`
  grid-area: bio;

  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: block;
  }
`;

type StyledAuthorBioProps = {
  $isExpand: boolean;
};

export const StyledAuthorBio = styled(AuthorBio)<StyledAuthorBioProps>`
  ${props =>
    !props.$isExpand &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
    `}
  color: #000000;
  line-height: 16px;
  text-overflow: ellipsis;

  cursor: pointer;

  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2; /* stylelint-disable-line property-no-unknown */
`;
