import { theme, useResponsive } from "@product/scmp-sdk";
import first from "lodash/first";
import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import type { geoLocationMainLocationArticle$key } from "scmp-app/queries/__generated__/geoLocationMainLocationArticle.graphql";
import type { geoLocationMainLocationArticleContentQuery } from "scmp-app/queries/__generated__/geoLocationMainLocationArticleContentQuery.graphql";

import LocationIcon from "./icon-location.svg";
import {
  Container,
  ContentContainer,
  Divider,
  LocationName,
  StyledContentItemArticleWidget,
} from "./styles";
type Props = {
  children?: ReactNode;
  className?: string;
  reference: geoLocationMainLocationArticle$key;
};

export const GeoLocationMainLocationArticle: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const { firstTopic, ...content } = useFragment<geoLocationMainLocationArticle$key>(
    graphql`
      fragment geoLocationMainLocationArticle on Article {
        entityId
        entityUuid
        firstTopic {
          contents(first: 4, orderBy: { field: PUBLISHED_DATE, direction: DESC }) {
            edges {
              node {
                entityId
                ...articleWidgetContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const excludeEntityIds = firstTopic?.contents?.edges.map(edge => edge.node.entityId) ?? [];

  const articleMainLocationContent = useLazyLoadQuery<geoLocationMainLocationArticleContentQuery>(
    graphql`
      query geoLocationMainLocationArticleContentQuery(
        $excludeEntityIds: [String!]
        $entityUuid: String!
      ) {
        article(filter: { entityUuid: $entityUuid }) {
          topics {
            ...entityLink
            types
            name
            contents(
              first: 4
              orderBy: { field: PUBLISHED_DATE, direction: DESC }
              exclude: { entityIds: $excludeEntityIds }
            ) {
              edges {
                node {
                  entityId
                  ...articleWidgetContent
                }
              }
            }
          }
        }
      }
    `,
    { entityUuid: content.entityUuid, excludeEntityIds },
    { fetchPolicy: "store-and-network" },
  );

  const articleMainLocation = first(
    articleMainLocationContent.article.topics?.filter(topic =>
      topic?.types?.includes("place_locations"),
    ),
  );

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  const contents = articleMainLocation?.contents?.edges
    .filter(edge => edge.node.entityId !== content.entityId)
    .slice(0, isMobile ? 1 : 2);

  return (
    <Container className={className}>
      {articleMainLocation && (
        <LocationName reference={articleMainLocation}>
          <LocationIcon />
          {articleMainLocation?.name}
        </LocationName>
      )}
      <Divider />
      <ContentContainer>
        {contents?.map(edge => (
          <StyledContentItemArticleWidget
            key={edge.node.entityId}
            reference={edge.node}
            withCoverImage={false}
          />
        ))}
      </ContentContainer>
    </Container>
  );
};

GeoLocationMainLocationArticle.displayName = "GeoLocationMainLocationArticle";
