import styled from "@emotion/styled";
import { fontAngkor, fontFredoka, theme } from "@product/scmp-sdk";

import Line from "scmp-app/components/article/article-additional-info-box/line.svg";
import { GenericPostiesArticleNumberIcon } from "scmp-app/components/article/article-vocab-box/generic-posties-article-vocab-box/number-icon";
import { ContentSchemaRender } from "scmp-app/components/schema-render/content";

import SvgPig from "./icon-pig.svg";

export const Container = styled.div`
  position: relative;

  max-inline-size: 440px;
  border-block-start: 2px solid #000000;

  ${theme.breakpoints.up("desktop")} {
    max-inline-size: none;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    max-inline-size: 280px;
    padding-block: 70px 32px;

    border: 2px solid #000000;
    border-radius: 20px;
  }
`;

export const Title = styled.div`
  padding-block: 24px;

  color: #ff7c1d;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontAngkor};
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;

  ${theme.breakpoints.up("mediumDesktop")} {
    padding-block: 0;

    font-size: 28px;
    line-height: 36px;
  }
`;

export const StyledLineSvg = styled(Line)`
  display: none;

  inline-size: 100%;
  margin-block: 32px;

  ${theme.breakpoints.up("mediumDesktop")} {
    display: block;
  }
`;

export const StyledVocabBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  border-block-start: 2px solid #000000;
  border-block-end: 2px solid #000000;

  padding-block: 24px;
  padding-inline: 16px;
  ${theme.breakpoints.up("mediumDesktop")} {
    gap: 32px;

    padding-block: 0;
    padding-inline: 20px;

    border: none;
  }
`;

export const StyledVocab = styled.div`
  display: grid;
  grid:
    "number text" auto
    "number description" auto / auto 1fr;
  gap: 8px 4px;

  ${theme.breakpoints.up("mediumDesktop")} {
    grid:
      "number" auto
      "text" auto
      "description" auto / 1fr;
    gap: 8px 4px;
  }
`;

export const StyledText = styled.div`
  grid-area: text;

  display: flex;
  flex-direction: column;
  row-gap: 8px;

  font-family: ${fontFredoka};
`;

export const StyledTermAndTranslation = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  ${theme.breakpoints.up("mediumDesktop")} {
    align-items: flex-start;
  }
`;

export const StyledTerm = styled.div`
  color: #333333;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;

  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const StyledTranslation = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;

  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 18px;
  }
`;

export const StyledContentSchemaRender = styled(ContentSchemaRender)`
  grid-area: description;

  font-weight: 400;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 21.6px;
  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const StyledGenericPostiesArticleNumberIcon = styled(GenericPostiesArticleNumberIcon)`
  grid-area: number;
  svg {
    inline-size: 50px;
    block-size: 40.5px;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    margin-block-end: 0;

    svg {
      inline-size: 54px;
      block-size: 45px;
    }
  }
`;

export const StyledPig = styled(SvgPig)`
  position: absolute;

  display: none;

  ${theme.breakpoints.up("mediumDesktop")} {
    inset-block-start: -54.5px;
    inset-inline-start: 50%;

    display: block;

    max-inline-size: none;

    transform: translateX(-50%);
  }
`;
