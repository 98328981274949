import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { getReadingTime } from "shared/lib/utils";

import type { articleReadingTimeContent$key } from "scmp-app/queries/__generated__/articleReadingTimeContent.graphql";

import { Container } from "./styles";

type Props = {
  className?: string;
  reference: articleReadingTimeContent$key;
};

export const ArticleReadingTime: FunctionComponent<Props> = ({ className, reference }) => {
  const content = useFragment(
    graphql`
      fragment articleReadingTimeContent on Content {
        readingTime
      }
    `,
    reference,
  );

  const readingTime = getReadingTime(content?.readingTime ?? 0);

  return (
    <Container className={className}>
      <span>Reading Time:</span>
      <strong>{readingTime}</strong>
    </Container>
  );
};

ArticleReadingTime.displayName = "ArticleReadingTime";
