/**
 * @generated SignedSource<<363ee2ad1c32430ead55adaf26f576f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type genericArticleSpeechInlineWidgetGenericArticle$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"buttonArticleSpeechInlineWidgetGenericButtonArticle" | "hooksShowGenericInlineWidgetByArticleArticle" | "toolTipArticleSpeechWidgetToolTipArticle">;
  readonly " $fragmentType": "genericArticleSpeechInlineWidgetGenericArticle";
};
export type genericArticleSpeechInlineWidgetGenericArticle$key = {
  readonly " $data"?: genericArticleSpeechInlineWidgetGenericArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"genericArticleSpeechInlineWidgetGenericArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "genericArticleSpeechInlineWidgetGenericArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "buttonArticleSpeechInlineWidgetGenericButtonArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksShowGenericInlineWidgetByArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "toolTipArticleSpeechWidgetToolTipArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "873ceaa8d1be014e9d4e83c707a2ea8d";

export default node;
