/**
 * @generated SignedSource<<21719f453b29e1a3f84efffe44923547>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleAuthorBlockArticle$data = {
  readonly authors: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"authorAvatarAuthor" | "entityFollowButtonBase">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"articleDateContent" | "authorAvatarsArticle" | "authorNamesArticle" | "helpersCheckCanShowInPlusPlatformContent" | "helpersCheckIsPlusArticle" | "plusArticleAuthorBlockArticle">;
  readonly " $fragmentType": "articleAuthorBlockArticle";
};
export type articleAuthorBlockArticle$key = {
  readonly " $data"?: articleAuthorBlockArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleAuthorBlockArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaywallType",
        "kind": "LinkedField",
        "name": "paywallTypes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entityUuid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleAuthorBlockArticle",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleDateContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorNamesArticle"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityFollowButtonBase"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "authorAvatarAuthor"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckCanShowInPlusPlatformContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckArticleIsInSectionsArticle",
              "selections": (v2/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPlusArticle",
              "selections": (v3/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsStyleArticle",
              "selections": (v2/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersGetArticleTypeArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleTypeSegment",
                      "kind": "LinkedField",
                      "name": "types",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ArticleType",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersUseContentSponsorContent",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Topic",
                  "kind": "LinkedField",
                  "name": "topics",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sponsor",
                      "kind": "LinkedField",
                      "name": "sponsor",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "sponsorTagSponsor"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sponsorType",
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersCheckIsMainTopicContent",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Topic",
                      "kind": "LinkedField",
                      "name": "topics",
                      "plural": true,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPlusArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v3/*: any*/),
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "plusArticleAuthorBlockArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorAvatarsArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "e1dea9b1a057eab54ebc5e9257032520";

export default node;
