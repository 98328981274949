import styled from "@emotion/styled";
import { Paper } from "@mui/material";

import { Popper } from "scmp-app/components/common/popper";

export const StyledPopper = styled(Popper)`
  /**  z-index in photo swipe --pswp-root-z-index */
  z-index: 100000;
`;

export const ShareContainer = styled.div`
  position: relative;
`;

export const MenuItem = styled.div`
  padding-block: 8px;

  color: #666666;

  :hover {
    color: #000000;
  }
`;

export const StyledPaper = styled(Paper)`
  padding-inline: 12px;
`;
