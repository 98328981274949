import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { articleAdditionalInfoBoxArticle$key } from "scmp-app/queries/__generated__/articleAdditionalInfoBoxArticle.graphql";
import type { articleAdditionalInfoBoxArticleContent$key } from "scmp-app/queries/__generated__/articleAdditionalInfoBoxArticleContent.graphql";

import ImageLogo from "./logo.png";
import {
  BorderedContainer,
  ContentsWrapper,
  ImgWrapper,
  LineWrapper,
  StyledContentSchemaRenderDesc,
  StyledImg,
  StyledLineSvg,
  Title,
} from "./styles";

type Props = {
  className?: string;
  contentReference: articleAdditionalInfoBoxArticleContent$key;
  reference?: articleAdditionalInfoBoxArticle$key;
};

export const ArticleAdditionalInfoBox: FunctionComponent<Props> = ({
  className,
  contentReference,
  reference,
}) => {
  const additionalInfoBox = useFragment(
    graphql`
      fragment articleAdditionalInfoBoxArticle on AdditionalInfoBox {
        title
        description {
          json
        }
      }
    `,
    reference ?? null,
  );

  const content = useFragment(
    graphql`
      fragment articleAdditionalInfoBoxArticleContent on Content {
        ...contentSchemaRenderContent
      }
    `,
    contentReference,
  );

  return (
    <BorderedContainer className={className}>
      <ImgWrapper>
        <StyledImg src={ImageLogo.src} />
      </ImgWrapper>
      <ContentsWrapper>
        <Title>{additionalInfoBox?.title}</Title>
        <LineWrapper>
          <StyledLineSvg />
        </LineWrapper>
        <StyledContentSchemaRenderDesc
          reference={content}
          schema={additionalInfoBox?.description?.json}
        />
      </ContentsWrapper>
    </BorderedContainer>
  );
};

ArticleAdditionalInfoBox.displayName = "ArticleAdditionalInfoBox";
