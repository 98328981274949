import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useArticleDateUtils } from "scmp-app/components/article/article-date/hooks";
import type { article20DateContent$key } from "scmp-app/queries/__generated__/article20DateContent.graphql";

import { Container, PublishedDate, UpdatedDate } from "./styles";

type Props = {
  className?: string;
  reference: article20DateContent$key;
};

export const Article20Date: FunctionComponent<Props> = ({ className, reference }) => {
  const content = useFragment(
    graphql`
      fragment article20DateContent on Content {
        ...hooksArticleDateUtilsContent
      }
    `,
    reference,
  );

  const { isoPublishedDate, isoUpdatedDate, lastUpdatedDate, publishedDate } =
    useArticleDateUtils(content);

  const isNeedToDisplayUpdatedDate = isoUpdatedDate && isoPublishedDate !== isoUpdatedDate;

  if (!content) return null;

  return (
    <Container className={className}>
      <PublishedDate dateTime={isoPublishedDate}>Published: {publishedDate}</PublishedDate>
      {isNeedToDisplayUpdatedDate && (
        <UpdatedDate dateTime={isoUpdatedDate}>Updated: {lastUpdatedDate}</UpdatedDate>
      )}
    </Container>
  );
};

Article20Date.displayName = "Article20Date";
