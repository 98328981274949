import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityFollowButton } from "scmp-app/components/entity-follow-button";
import { TopicLink } from "scmp-app/components/topic/topic-link";

export const Container = styled.div``;

export const Title = styled.div`
  padding-block-end: 12px;

  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const StyledTopicLink = styled(TopicLink)`
  display: inline;

  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.2px;
  word-break: break-word;
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)`
  z-index: 0;

  background-color: transparent;
`;

export const ItemsContainer = styled.div`
  color: #cccccc;

  ${StyledTopicLink} {
    color: #666666;
    line-height: 25px;

    &:hover {
      color: #2c4692;
      text-decoration: underline;
    }
  }
`;

export const MainItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  inline-size: fit-content;
  padding: 4px;

  color: #000000;

  border-radius: 2px;

  background-color: #f2f2f2;
`;

export const Topics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 16px;
`;

export const ArticleTopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RelatedTopicsContainer = styled.div`
  display: flex;
  gap: 8px;

  margin-block: 20px 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid #000000;
`;

export const RelatedTopicsLabel = styled.div`
  padding-block: 2px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18px;
`;

export const RelatedTopics = styled.div`
  flex: 1;
`;
