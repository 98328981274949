import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  AdSlotContainer,
  StyledSlot,
} from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { ContentItemArticleWidget } from "scmp-app/components/content/content-item-render/variants/article-widget";

export const StyledContentItemArticleWidget = styled(ContentItemArticleWidget)`
  margin-block-start: 12px;
  padding-block-start: 12px;
  border-block-start: 1px #cccccc solid;
`;

export const Title = styled.div`
  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
`;

export const AdSlot = styled.div`
  && ${AdSlotContainer} {
    display: flex;
    align-items: flex-start;

    block-size: 100%;

    ${StyledSlot} {
      block-size: auto;
    }
  }
`;

export const FurtherReadingContainer = styled.div`
  grid-area: further-reading;

  flex: 1 1 50%;

  ${StyledContentItemArticleWidget}+${Title} {
    margin-block-start: 12px;
    padding-block-start: 24px;
    border-block-start: 1px #cccccc solid;
  }

  ${Title}+${StyledContentItemArticleWidget} {
    margin-block-start: 0;
    border-block-start: none;
  }

  ${StyledContentItemArticleWidget}+${AdSlot} {
    padding-block-start: 12px;
    border-block-start: 1px #cccccc solid;
  }
`;

type ContainerProps = {
  $isSubscriber: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-flow: row nowrap;

  inline-size: 100%;
  max-inline-size: ${props => (props.$isSubscriber ? "500px" : "unset")};
  padding: 20px;

  column-gap: 28px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  background-color: #ffffff;

  ${".swiper"} {
    padding-block-end: 34px;

    ${theme.breakpoints.only("mobile")} {
      padding-block-end: 20px;
    }
  }

  ${".swiper-pagination-bullet"} {
    inline-size: 6px;
    block-size: 6px;

    background-color: #dddddd;
  }

  ${".swiper-pagination-bullet"} {
    background-color: #cccccc;

    opacity: 1;
  }

  ${".swiper-pagination-bullet-active"} {
    background-color: #000000;
  }

  ${theme.breakpoints.only("mobile")} {
    padding-block-end: 0;
  }

  ${theme.breakpoints.up("desktop")} {
    display: grid;
    grid:
      "further-reading" min-content
      "stories-you-might-like" min-content
      / 100%;

    max-inline-size: unset;
  }

  ${AdSlot} {
    margin-block-start: 12px;
  }
`;

export const StyledSwiper = styled(Swiper)``;

export const StyledSwiperSlide = styled(SwiperSlide)``;
