import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import {
  ArticleImageContainer,
  ImageContainer,
  StyledImage,
} from "scmp-app/components/article/article-image/styles";

import LightBulb from "./light-bulb.svg";
import Stars from "./stars.svg";

export const StyledMascotDesktop = styled.img`
  inline-size: 220px;
  block-size: 369px;
`;

export const StyledMascotDesktopForQuiz = styled.img`
  inline-size: 239px;
  block-size: 354px;

  transform: translateY(16px);
`;

export const StyledMascotDesktopContainer = styled.div`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: flex;
    justify-content: center;

    inline-size: 100%;
  }
`;

export const StyledMascotMobile = styled.img`
  inline-size: 242px;
  block-size: 177px;
`;

export const StyledMascotMobileForQuiz = styled.img`
  inline-size: 265px;
  block-size: 180px;
`;

export const StyledMascotMobileContainer = styled.div`
  display: flex;
  justify-content: center;

  inline-size: 100%;

  ${theme.breakpoints.up("desktop")} {
    display: none;
  }
`;

export const StyledStars = styled(Stars)`
  position: absolute;
  inset-block-start: 10.5%;
  inset-inline-start: -6.85%;

  inline-size: 18.5%;
  block-size: fit-content;
`;

export const StyledLightBulb = styled(LightBulb)`
  position: absolute;
  inset-block-start: -24%;
  inset-inline-end: -8%;

  inline-size: 18.5%;
  block-size: fit-content;
`;

export const LeadingContainer = styled.div`
  position: relative;

  inline-size: 100%;
  max-inline-size: 580px;
  margin-block-start: 24px;
  margin-inline: auto;

  pointer-events: none;

  ${theme.breakpoints.up("desktop")} {
    grid-column: span 12;

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    max-inline-size: 100%;
    margin-block-start: 40px;

    column-gap: 24px;

    ${StyledMascotDesktopContainer} {
      grid-column: 1 / span 4;

      position: absolute;
      inset-block-start: -369px;
    }
  }
`;

export const LeadingImageContainer = styled.div`
  position: relative;

  inline-size: 92.72%;
  margin: auto;

  ${ArticleImageContainer} {
    inset-inline-start: unset;
    inset-inline-end: unset;

    max-inline-size: 100%;
    margin-inline: auto;

    ${ImageContainer} {
      position: relative;
      inset-inline-start: 0.9%;

      border: 1.11px solid #000000;
      border-radius: 7.39px;

      aspect-ratio: unset;

      ${StyledImage} {
        position: relative;
        inset-inline-start: -0.9%;
        z-index: -1;

        margin-block: 1% -1%;

        border-radius: 7.39px;

        object-fit: contain;
      }
    }
  }

  ${theme.breakpoints.up("desktop")} {
    grid-column: 2 / span 10;

    inline-size: calc(100% + 48px);
    margin-inline: -24px;

    ${ArticleImageContainer} {
      inline-size: 100%;
      padding-inline: 0;

      ${ImageContainer} {
        inline-size: 99.1%;

        border: 2.838px solid #000000;
        border-radius: 18.92px;

        ${StyledImage} {
          border-radius: 18.92px;
        }
      }
    }
  }

  ${theme.breakpoints.up("largeDesktop")} {
    ${ArticleImageContainer} {
      ${ImageContainer} {
        border: 2.97px solid #000000;
        border-radius: 20px;

        ${StyledImage} {
          border-radius: 20px;
        }
      }
    }
  }
`;
