/**
 * @generated SignedSource<<4a6e109fa29541eaad507bdb7e6c0b56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleSeriesArticle$data = {
  readonly seriesContainer: {
    readonly entityId: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"carouselArticleSeriesCarouselArticle" | "inlineArticleSeriesInlineArticle">;
  readonly " $fragmentType": "articleSeriesArticle";
};
export type articleSeriesArticle$key = {
  readonly " $data"?: articleSeriesArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSeriesArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleSeriesArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "seriesContainer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "inlineArticleSeriesInlineArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "carouselArticleSeriesCarouselArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "e932f8843d3060d53d8951e244182d5a";

export default node;
