import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { EntityFollowButton } from "scmp-app/components/entity-follow-button";
import { TopicLink } from "scmp-app/components/topic/topic-link";
import { StyledEntityLink } from "scmp-app/components/topic/topic-link/styles";

export const StyledTopicLink = styled(TopicLink)`
  word-break: break-word;
`;

export const PlusTopicLink = styled.div`
  word-break: break-word;

  cursor: pointer;
`;

// Item
export const TopicSeparator = styled.span`
  padding-block: 0;
  padding-inline: 8px;

  color: #bbbbbb;
  font-size: 16px;
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)`
  z-index: 0;

  margin-inline-start: 6px;
`;

export const Item = styled.h2`
  display: flex;
  flex: none;
  flex-flow: row nowrap;
  align-items: center;

  max-inline-size: 100%;
`;

export const MainItem = styled(Item)``;

export const Topics = styled.div``;

export const Label = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 16px;
`;

export type ArticleTopicsContainerProps = {
  $variant?: "dark" | "light" | "magazines-style" | "plus";
};
export const ArticleTopicsContainer = styled.div<ArticleTopicsContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 24px;
  }

  ${Label} {
    ${props => {
      switch (props.$variant) {
        case "magazines-style":
          return css`
            font-family: ${fontRobotoCondensed};
            letter-spacing: 1.6px;
          `;
      }
    }}
  }

  ${Topics} {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    gap: 8px;

    padding: 16px;

    ${props => {
      switch (props.$variant) {
        case "dark":
          return css`
            background: #111111;
          `;
        case "light":
          return css`
            background: #fafafa;
          `;
        case "magazines-style":
          return css`
            padding: 0px;
            column-gap: 16px;
            row-gap: 8px;

            ${theme.breakpoints.up("tablet")} {
              column-gap: 24px;
              row-gap: 12px;
            }
          `;
        case "plus":
          return css`
            padding: 0px;
          `;
      }
    }}

    ${MainItem} {
      ${props => {
        switch (props.$variant) {
          case "dark":
          case "light":
            return css`
              flex-basis: 100%;

              max-inline-size: 100%;
            `;
        }
      }}
    }

    ${MainItem}, ${Item} {
      ${StyledEntityLink}, ${PlusTopicLink} {
        ${props => {
          switch (props.$variant) {
            case "dark":
              return css`
                color: #666666;
                &:hover {
                  color: #ffffff;
                }
              `;
            case "light":
              return css`
                color: #bbbbbb;
                &:hover {
                  color: #000000;
                }
              `;
            case "magazines-style":
              return css`
                color: #000000;
                font-family: ${fontRobotoCondensed};
                letter-spacing: 1.6px;
              `;
            case "plus":
              return css`
                padding-block: 2px;
                padding-inline: 4px;

                color: #000000;
                font-weight: 400;
                font-size: 14px;
                font-family: ${fontRobotoCondensed};
                line-height: 18px;

                border-radius: 2px;

                background-color: #c8ced566;
              `;
          }
        }}
      }
    }
  }
`;

export const RelatedTopicsContainer = styled.div`
  display: flex;
  gap: 8px;

  margin-block: 20px 20px;
  padding-block-start: 20px;
  border-block-start: 1px solid #000000;
`;

export const RelatedTopicsLabel = styled.div`
  padding-block: 2px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18px;
`;

export const RelatedTopics = styled.div`
  flex: 1;
`;
