import { notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import type { articleSeriesArticle$key } from "scmp-app/queries/__generated__/articleSeriesArticle.graphql";

import { ArticleSeriesCarousel } from "./carousel";
import { ArticleSeriesInline } from "./inline";
import { Container } from "./styles";

type Props = {
  className?: string;
  reference: articleSeriesArticle$key;
  variant?: "carousel" | "inline";
};

export const ArticleSeries: FunctionComponent<Props> = ({ className, reference, variant }) => {
  const article = useFragment(
    graphql`
      fragment articleSeriesArticle on Article {
        seriesContainer {
          entityId
        }
        ...inlineArticleSeriesInlineArticle
        ...carouselArticleSeriesCarouselArticle
      }
    `,
    reference,
  );

  const seriesWidgetGA4ImpressionEvent: () => RecirculationWidgetImpressionEvent = () =>
    ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        page_type: "article",
        widget_name: "series_recirculation",
      },
      subcategory: "widget",
    }) as const;

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: seriesWidgetGA4ImpressionEvent,
      options: {
        intersectionThreshold: [0.1],
        isSendGA4Tracking: true,
        shouldSendOnce: true,
        shouldThrottle: true,
      },
    });

  const render = () => {
    switch (variant) {
      case "carousel":
        return <ArticleSeriesCarousel reference={article} />;
      case "inline":
        return <ArticleSeriesInline reference={article} />;
      default:
        return null;
    }
  };

  if (!notEmpty(variant) || !notEmpty(article.seriesContainer?.entityId)) return null;

  return (
    <Container className={className} ref={captureTrackImpressionEventTargetElement}>
      {render()}
    </Container>
  );
};

ArticleSeries.displayName = "ArticleSeries";
