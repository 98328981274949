import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentCoverVideo } from "scmp-app/components/content/content-cover-video";
import { isArticleVideoAsHero } from "scmp-app/components/content/content-cover-video/helpers";
import { useContentSchemaRenderIframe } from "scmp-app/components/schema-render/common/iframe/hooks";
import type { ExtraProps as SCMPYoutubeProps } from "scmp-app/components/schema-render/common/iframe/scmp-youtube";
import type { Props as ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import type { articleLeadingArticle$key } from "scmp-app/queries/__generated__/articleLeadingArticle.graphql";

import type { Props as ArticleLeadingImagesProps } from "./article-leading-images";
import { ArticleLeadingImages } from "./article-leading-images";
import { ArticleLeadingVideoContainer } from "./styles";

export type Props = Omit<ArticleLeadingImagesProps, "imageProps" | "reference"> &
  Pick<ContentSchemaRenderProps, "advertising"> & {
    className?: string;
    reference: articleLeadingArticle$key;
    scmpYoutubeProps?: SCMPYoutubeProps;
  };

export const ArticleLeading: FunctionComponent<Props> = ({
  advertising,
  className,
  reference: reference_,
  scmpYoutubeProps = {},
  ...articleLeadingProps
}) => {
  const article = useFragment(
    graphql`
      fragment articleLeadingArticle on Article {
        entityId
        displaySlideShow
        ...articleLeadingImagesContent
        ...contentCoverVideoContent
        ...contentSchemaRenderContent
        ...helpersIsArticleVideoAsHeroArticle
      }
    `,
    reference_,
  );

  const { displaySlideShow, entityId } = article;

  const { ComponentWithExtraProps: IframeWithExtraProps } = useContentSchemaRenderIframe({
    gaData: {
      ga4ArticleEntityId: entityId,
      ga4Position: "article_header",
    },
    ...scmpYoutubeProps,
  });

  if (isArticleVideoAsHero(article))
    return (
      <ArticleLeadingVideoContainer className={className}>
        <ContentCoverVideo
          advertising={advertising}
          extraComponentMap={{
            iframe: IframeWithExtraProps,
          }}
          reference={article}
        />
      </ArticleLeadingVideoContainer>
    );

  if (!displaySlideShow) return null;

  return (
    <ArticleLeadingImages
      {...articleLeadingProps}
      className={className}
      imageProps={{ lazyload: false }}
      reference={article}
    />
  );
};

ArticleLeading.displayName = "ArticleLeading";
