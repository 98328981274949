import type { FunctionComponent } from "react";

import IconEight from "./icon-eight.svg";
import IconEleven from "./icon-eleven.svg";
import IconFifteen from "./icon-fifteen.svg";
import IconFive from "./icon-five.svg";
import IconFour from "./icon-four.svg";
import IconFourteen from "./icon-fourteen.svg";
import IconNine from "./icon-nine.svg";
import IconOne from "./icon-one.svg";
import IconSeven from "./icon-seven.svg";
import IconSix from "./icon-six.svg";
import IconTen from "./icon-ten.svg";
import IconThirteen from "./icon-thirteen.svg";
import IconThree from "./icon-three.svg";
import IconTwelve from "./icon-twelve.svg";
import IconTwo from "./icon-two.svg";
import { Container } from "./styles";

const iconMap = {
  1: <IconOne />,
  2: <IconTwo />,
  3: <IconThree />,
  4: <IconFour />,
  5: <IconFive />,
  6: <IconSix />,
  7: <IconSeven />,
  8: <IconEight />,
  9: <IconNine />,
  10: <IconTen />,
  11: <IconEleven />,
  12: <IconTwelve />,
  13: <IconThirteen />,
  14: <IconFourteen />,
  15: <IconFifteen />,
} as const;

type Props = {
  className?: string;
  number: number;
};

type ValidNumber = keyof typeof iconMap;

export const GenericPostiesArticleNumberIcon: FunctionComponent<Props> = ({
  className,
  number,
}) => {
  const numberIcon = iconMap[number as ValidNumber];

  if (!numberIcon) {
    return null;
  }

  return <Container className={className}>{numberIcon}</Container>;
};

GenericPostiesArticleNumberIcon.displayName = "GenericPostiesArticleNumberIcon";
