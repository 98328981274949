import type { FunctionComponent, ReactNode } from "react";

import { FooterCaption } from "./footer/footer-caption";
import { FooterPortal } from "./footer/footer-portal";
import type { StyledGalleryProps } from "./styles";
import { ArrowBackwardSVGString, ArrowForwardSVGString, StyledGallery } from "./styles";
import { ToolbarButtonClose } from "./toolbar/toolbar-button-close";
import { ToolbarButtonFullscreen } from "./toolbar/toolbar-button-fullscreen";
import type { Props as ToolbarButtonShareProps } from "./toolbar/toolbar-button-share";
import { ToolbarButtonShare } from "./toolbar/toolbar-button-share";
import { ToolbarButtonZoom } from "./toolbar/toolbar-button-zoom";
import { ToolbarPortal } from "./toolbar/toolbar-portal";

type Props = StyledGalleryProps &
  ToolbarButtonShareProps & {
    children?: ReactNode;
    className?: string;
  };

export const ImageGallery: FunctionComponent<Props> = ({
  $variant,
  children,
  relativeUrl,
  shortUrl,
  title,
  utm,
}) => (
  <StyledGallery
    $variant={$variant}
    options={{
      arrowNextSVG: ArrowForwardSVGString,
      arrowPrevSVG: ArrowBackwardSVGString,
      bgOpacity: 1,
      close: false,
      showHideAnimationType: "none",
      zoom: false,
    }}
  >
    <ToolbarPortal>
      <ToolbarButtonFullscreen />
      <ToolbarButtonShare relativeUrl={relativeUrl} shortUrl={shortUrl} title={title} utm={utm} />
      <ToolbarButtonZoom />
      <ToolbarButtonClose />
    </ToolbarPortal>
    <FooterPortal>
      <FooterCaption />
    </FooterPortal>
    {children}
  </StyledGallery>
);
