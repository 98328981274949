import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { extractAfterPrefix } from "scmp-app/components/article/article-render/posties/helpers";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import { FormPrefixes } from "./consts";
import { getFormData, getStsToken } from "./helpers";
import type { FormDrawer, ModifiedFormData, StsToken } from "./types";

type ContextValue = {
  formData: ModifiedFormData | null;
  formDrawer: FormDrawer;
  ossCredential: null | StsToken;
  setFormDrawer?: Dispatch<SetStateAction<FormDrawer>>;
  setOssCredential?: Dispatch<SetStateAction<null | StsToken>>;
  setShowAlertBox?: Dispatch<SetStateAction<boolean>>;
  showAlertBox: boolean;
};

const PostiesFormDrawerContext = createContext<ContextValue>({
  formData: null,
  formDrawer: {
    documentId: null,
    isLatestForm: false,
    messageBox: null,
    open: false,
  },
  ossCredential: null,
  showAlertBox: false,
});

type Props = {
  children: ReactNode;
};

export const PostiesFormDrawerContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const router = useRouter();
  const [formDrawer, setFormDrawer] = useState<FormDrawer>({
    documentId: null,
    isLatestForm: false,
    messageBox: null,
    open: false,
  });
  const [formData, setFormData] = useState<ModifiedFormData | null>(null);
  const [ossCredential, setOssCredential] = useState<null | StsToken>(null);
  const [showAlertBox, setShowAlertBox] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const asyncRosettaState = useAtomValue(rosettaAtom);

  useEffect(() => {
    const form = extractAfterPrefix(window.location.href, FormPrefixes);
    if (asyncRosettaState?.status !== "success") return;
    if (form && formData === null) {
      try {
        void (async () => {
          const data = await getFormData(form);
          if (data) {
            setFormData(data);
            setFormDrawer({
              documentId: data.restData.documentId,
              isLatestForm: form.prefix === "form-latest",
              messageBox: data.restData.isExpired ? "expired" : null,
              open: true,
            });
          } else {
            setFormDrawer({
              documentId: null,
              isLatestForm: false,
              messageBox: "invalid",
              open: true,
            });
          }
        })();
      } catch (error) {
        console.info(error);
      }
    }
  }, [formDrawer.documentId, router, formData, asyncRosettaState?.status]);

  useEffect(() => {
    if (!formData) return;
    if (formData.staticFields.photo.display || formData.staticFields.artwork.display) {
      if (!executeRecaptcha) return;
      void (async () => {
        const recaptchaToken = await executeRecaptcha("form_action");
        if (ossCredential === null) {
          const credential = await getStsToken(recaptchaToken);
          setOssCredential?.(credential);
        }
      })();
    }
  }, [executeRecaptcha, formData, ossCredential]);

  useEffect(() => {
    if (formDrawer.open) return;
    setFormData(null);
    setOssCredential(null);
  }, [formDrawer.open]);

  return (
    <PostiesFormDrawerContext.Provider
      value={{
        formData,
        formDrawer,
        ossCredential,
        setFormDrawer,
        setOssCredential,
        setShowAlertBox,
        showAlertBox,
      }}
    >
      {children}
    </PostiesFormDrawerContext.Provider>
  );
};

PostiesFormDrawerContextProvider.displayName = "PostiesFormDrawerContextProvider";

export const usePostiesFormDrawerContext = () => useContext(PostiesFormDrawerContext);
