import type { EmotionJSX } from "@emotion/react/dist/declarations/src/jsx-namespace";
import type { ComponentType } from "react";

import { ArticleRenderContextProvider } from "./contexts";

export function withArticleRenderContext<Props extends object>(Component: ComponentType<Props>) {
  const WrappedComponent = (props: Props) => (
    <ArticleRenderContextProvider>
      <Component {...(props as EmotionJSX.LibraryManagedAttributes<typeof Component, Props>)} />
    </ArticleRenderContextProvider>
  );
  return WrappedComponent;
}
