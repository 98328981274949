import type { FunctionComponent } from "react";

import { StyledButton, StyledIcon } from "./styles";

type Props = {
  className?: string;
  icon?: string;
  onClick?: () => void;
  title?: string;
};

export const ToolbarButton: FunctionComponent<Props> = ({ className, icon, onClick, title }) => (
  <StyledButton className={className} icon onClick={onClick} title={title}>
    <StyledIcon>{icon}</StyledIcon>
  </StyledButton>
);

ToolbarButton.displayName = "ToolbarButton";
