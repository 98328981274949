/* eslint-disable max-lines */
import { ThemeProvider } from "@mui/material";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import type { FunctionComponent, PartialPropsOf, ReactNode } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import type { Required } from "utility-types";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";
import { article as articleData } from "shared/data";

import { TakeoverAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot";
import { ArticleImageGallery } from "scmp-app/components/article/article-image-gallery";
import { ImageSize } from "scmp-app/components/article/article-leading/article-leading-images/enums";
import { useArticlePianoPaywallContext } from "scmp-app/components/article/article-piano-paywall/contexts";
import type { Props as ArticlePrintHeadlineMessageProps } from "scmp-app/components/article/article-print-headline-message";
import { ArticlePrintHeadlineMessage } from "scmp-app/components/article/article-print-headline-message";
import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { ArticleSpeechPlayerPortal } from "scmp-app/components/article/article-speech/article-speech-player-portal";
import { AuthorAvatarContextProvider } from "scmp-app/components/author/author-avatar/contexts";
import type { ContentBody } from "scmp-app/components/content/content-body";
import { useContentReadMoreContext } from "scmp-app/components/content/content-read-more";
import { ContentReadMoreButton } from "scmp-app/components/content/content-read-more/content-read-more-button";
import { type Props as InlineAdSlotProps } from "scmp-app/components/schema-render/common/inline-ad-slot";
import { appAtom } from "scmp-app/lib/app/atoms";
import { usePaywallMaskingABTest } from "scmp-app/lib/rosetta/a-b-test/hooks";
import type { articleRenderArticle$key } from "scmp-app/queries/__generated__/articleRenderArticle.graphql";

import { useArticleRenderContext } from "./contexts";
import {
  DynamicGenericArticle,
  DynamicInfographicArticle,
  DynamicLiveArticle,
  DynamicMorningStudioArticle,
  DynamicNewsletterArticle,
  DynamicPhotoEssayAndLongReadArticle,
  DynamicPlusGenericArticle,
  DynamicPostMagazineArticle,
  DynamicSeriesArticle,
  DynamicStyleArticle,
} from "./dynamics";
import { GlobalChecker } from "./global-checker";
import { checkArticleRenderType, getArticleType } from "./helpers";
import { withArticleRenderContext } from "./hoc";
import { useMostLikelyToSubscribe, usePianoIntegration } from "./hooks";
import { useArticleBaseAdvertisingInfo } from "./hooks/article-base-advertising-info";
import { usePostiesArticle } from "./posties/hooks";
import type { InlinePaywallOverlayerProps } from "./styles";
import {
  ArticleRenderContainer,
  ContentReadMoreButtonContainer,
  InlinePaywallOverlayer,
  PrimaryContentBody,
  SecondlyContentBody,
  StyledAdSlot,
  StyledArticleActionBar,
  StyledArticleActionWidget,
  StyledArticleAdditionalInfoBox,
  StyledArticleAuthorAvatars,
  StyledArticleAuthorBlock,
  StyledArticleAuthorNames,
  StyledArticleAuthorsDetails,
  StyledArticleBeforeYouGoWidget,
  StyledArticleCarouselWidget,
  StyledArticleCommentsTrigger,
  StyledArticleCorrectionsWidget,
  StyledArticleDate,
  StyledArticleFurtherReadingWidget,
  StyledArticleGeoLocationWidget,
  StyledArticleLeading,
  StyledArticleLiveTag,
  StyledArticleMoreOnThisList,
  StyledArticlePaywallContainer,
  StyledArticleReadingTime,
  StyledArticleRelatedTopics,
  StyledArticleScmpPollWidget,
  StyledArticleSections,
  StyledArticleSeries,
  StyledArticleShareWidget,
  StyledArticleSpeechInlineWidget,
  StyledArticleSponsor,
  StyledArticleTopic,
  StyledArticleTopicFollowWidget,
  StyledArticleTopics,
  StyledArticleTopicWidget,
  StyledArticleVocabBox,
  StyledCommentContainer,
  StyledContentHeadline,
  StyledContentSubHeadline,
} from "./styles";
import { theme as defaultTheme } from "./theme";
import { ArticleRenderType } from "./types";
export * from "./types";

export type DefaultComponentsProvider = {
  actionBar: (props?: PartialPropsOf<typeof StyledArticleActionBar>) => ReactNode;
  actionWidget: (props?: PartialPropsOf<typeof StyledArticleActionWidget>) => ReactNode;
  adslot: (props: Required<PartialPropsOf<typeof StyledAdSlot>, "adUnit" | "sizes">) => ReactNode;
  articleAdditionalInfoBox: (
    props?: PartialPropsOf<typeof StyledArticleAdditionalInfoBox>,
  ) => ReactNode;
  authorAvatars: (props?: PartialPropsOf<typeof StyledArticleAuthorAvatars>) => ReactNode;
  authorBlock: (props?: PartialPropsOf<typeof StyledArticleAuthorBlock>) => ReactNode;
  authorDetails: (props?: PartialPropsOf<typeof StyledArticleAuthorsDetails>) => ReactNode;
  authorNames: (props?: PartialPropsOf<typeof StyledArticleAuthorNames>) => ReactNode;
  beforeYouGoWidget: (props?: PartialPropsOf<typeof StyledArticleBeforeYouGoWidget>) => ReactNode;
  body: (
    props?: PartialPropsOf<typeof ContentBody> & {
      isDisabledArticleImageGallery?: boolean;
      paywallVariant?: InlinePaywallOverlayerProps["$variant"];
      printHeadlineVariant?: ArticlePrintHeadlineMessageProps["variant"];
    },
  ) => ReactNode;
  bottomCarouselWidget: (
    provider: DefaultComponentsProvider,
    props?: PartialPropsOf<typeof StyledArticleCarouselWidget>,
  ) => ReactNode;
  commentTrigger: (props?: PartialPropsOf<typeof StyledArticleCommentsTrigger>) => ReactNode;
  correction: (props?: PartialPropsOf<typeof StyledArticleCorrectionsWidget>) => ReactNode;
  furtherReading: (props?: PartialPropsOf<typeof StyledArticleFurtherReadingWidget>) => ReactNode;
  geoLocationWidget: (props?: PartialPropsOf<typeof StyledArticleGeoLocationWidget>) => ReactNode;
  headline: (props?: PartialPropsOf<typeof StyledContentHeadline>) => ReactNode;
  leading: (
    props?: PartialPropsOf<typeof StyledArticleLeading> & { withExternalGallery?: boolean },
  ) => ReactNode;
  liveTag: (props?: PartialPropsOf<typeof StyledArticleLiveTag>) => ReactNode;
  moreOnThis: (props?: PartialPropsOf<typeof StyledArticleMoreOnThisList>) => ReactNode;
  paywall: (props?: PartialPropsOf<typeof StyledArticlePaywallContainer>) => ReactNode;
  publishedDate: (props?: PartialPropsOf<typeof StyledArticleDate>) => ReactNode;
  readingTime: (props?: PartialPropsOf<typeof StyledArticleReadingTime>) => ReactNode;
  relatedTopics: (props?: PartialPropsOf<typeof StyledArticleRelatedTopics>) => ReactNode;
  scmpPollWidget: (props?: PartialPropsOf<typeof StyledArticleScmpPollWidget>) => ReactNode;
  sections: (props?: PartialPropsOf<typeof StyledArticleSections>) => ReactNode;
  series: (props?: PartialPropsOf<typeof StyledArticleSeries>) => ReactNode;
  shareWidget: (props?: PartialPropsOf<typeof StyledArticleShareWidget>) => ReactNode;
  speechPlayer: (props: PartialPropsOf<typeof StyledArticleSpeechInlineWidget>) => ReactNode;
  speechPlayerPortal: (props: PartialPropsOf<typeof ArticleSpeechPlayerPortal>) => ReactNode;
  sponsor: (props?: PartialPropsOf<typeof StyledArticleSponsor>) => ReactNode;
  subHeadline: (props?: PartialPropsOf<typeof StyledContentSubHeadline>) => ReactNode;
  takeOverAdSlot: (props?: PartialPropsOf<typeof TakeoverAdSlot>) => ReactNode;
  topic: (props?: PartialPropsOf<typeof StyledArticleTopic>) => ReactNode;
  topicFollowWidget: (props?: PartialPropsOf<typeof StyledArticleTopicFollowWidget>) => ReactNode;
  topics: (props?: PartialPropsOf<typeof StyledArticleTopics>) => ReactNode;
  topicWidget: (props?: PartialPropsOf<typeof StyledArticleTopicWidget>) => ReactNode;
  vocabBox: (props?: PartialPropsOf<typeof StyledArticleVocabBox>) => ReactNode;
};

export type Props = {
  reference: articleRenderArticle$key;
};

const Component: FunctionComponent<Props> = ({ reference }) => {
  const article = useFragment(
    graphql`
      fragment articleRenderArticle on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        entityId
        ...articleActionWidgetArticle
        ...articleAdditionalInfoBoxArticleContent
        ...articleAuthorBlockArticle
        ...articleAuthorDetailsContent
        ...articleBaseAdvertisingInfoArticle
        ...articleBeforeYouGoWidget
        ...articleCommentsTriggerArticle
        ...articleCorrectionsWidgetArticle
        ...articleDateContent
        ...articleFurtherReadingWidgetArticle
        ...articleImageGalleryContent
        ...articleLeadingArticle
        ...articleMoreOnThisListArticle
        ...articlePrintHeadlineMessageArticle
        ...articleReadingTimeContent
        ...articleRelatedTopicsContent
        ...articleSectionsContent
        ...articleSeriesArticle
        ...articleShareWidgetArticle
        ...articleSpeechInlineWidgetArticle
        ...articleSpeechPlayerPortalArticle
        ...articleSponsorContent
        ...articleTopicContent
        ...articleTopicFollowWidgetArticle
        ...articleTopicsContent
        ...articleTopicWidgetArticle
        ...articleVocabBoxArticle
        ...articleGeoLocationWidget
        ...authorAvatarsArticle
        ...authorNamesArticle
        ...contentActionBarContent
        ...contentBodyContent @arguments(customContents: $customContents, bodyType: $bodyType)
        ...contentHeadlineContent
        ...contentLiveTagContent
        ...contentReadMoreButtonContent
        ...contentSchemaRenderContent
        ...contentSubHeadlineContent
        ...contentSummaryContent
        ...genericArticleContent
        ...globalCheckerContent
        ...helpersCheckArticleRenderTypeArticle
        ...helpersGetArticleTypeArticle
        ...hooksPostiesArticleArticle
          @arguments(customContents: $customContents, bodyType: $bodyType)
        ...infographicArticleArticle
        ...liveArticleArticle
        ...morningStudioArticleContent
        ...newsletterArticleArticle
        ...pianoIntegrationArticle
        ...plusColumnsArticleContent
        ...plusGenericArticleContent
        ...postMagazineArticleContent
        ...seriesArticleArticle
        ...styleArticleContent
      }
    `,
    reference,
  );

  usePianoIntegration(article);
  const advertising = useArticleBaseAdvertisingInfo(article);
  const articleType = getArticleType(article);
  const { navigatorInfo } = useAtomValue(appAtom);
  const isBot = navigatorInfo?.isBot;
  const isLightHouseCrawler = navigatorInfo?.isLightHouseCrawler;
  const router = useRouter();

  const renderType = checkArticleRenderType(article, router.query);

  const { isMostLikelyToSubscribeUser } = useMostLikelyToSubscribe();
  const { articlePianoPaywallStateMap } = useArticlePianoPaywallContext();

  const isShowInlinePaywall = useMemo(() => {
    if (renderType === ArticleRenderType.Plus) return false; // Articles of type "Plus" have a strict hard paywall and do not show inline paywalls
    if (articlePianoPaywallStateMap?.[article.entityId] === undefined) return;

    return (
      articlePianoPaywallStateMap[article.entityId] === "requiresInlinePaywall" ||
      articlePianoPaywallStateMap[article.entityId] === "shownInlinePaywall"
    );
  }, [article.entityId, articlePianoPaywallStateMap, renderType]);

  const isInlinePaywallDomChanged = useMemo(
    () => articlePianoPaywallStateMap?.[article.entityId] === "shownInlinePaywall",
    [article.entityId, articlePianoPaywallStateMap],
  );

  const { getPaywallPosition } = usePaywallMaskingABTest();

  const { isReadMoreClicked, setIsReadMoreClicked } = useContentReadMoreContext();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);
  const { articleElementReference, setEntityId, setIsContentReady } = useArticleRenderContext();

  const provider: DefaultComponentsProvider = useMemo(
    () => ({
      actionBar: props => <StyledArticleActionBar reference={article} {...props} />,
      actionWidget: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleActionWidget reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      adslot: props => (
        <ArticleSpeechSkipContainer>
          <StyledAdSlot
            isDisabled={isShowInlinePaywall === undefined}
            {...advertising}
            {...props}
          />
        </ArticleSpeechSkipContainer>
      ),
      articleAdditionalInfoBox: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleAdditionalInfoBox contentReference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      authorAvatars: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleAuthorAvatars reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      authorBlock: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleAuthorBlock reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      authorDetails: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleAuthorsDetails reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      authorNames: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleAuthorNames reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      beforeYouGoWidget: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleBeforeYouGoWidget provider={provider} reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      body: props => {
        const [outstreamAdSlotTag] = Object.entries(props?.inlineAdSlots ?? {}).find(
          ([_, slot]) => slot?.adUnit === "outstream1",
        ) ?? ["1"];

        const getReadMoreSeparateIndex = (schema: Schema) => {
          const outstreamAdSlotPosition = schema.findIndex(
            node => node.type === "inline-ad-slot" && node.attribs?.tag === outstreamAdSlotTag,
          );
          return outstreamAdSlotPosition === -1 ? 5 : outstreamAdSlotPosition + 1;
        };

        const { primary: primaryInlineAdSlots, secondary: secondInlineAdSlots } = Object.entries(
          props?.inlineAdSlots ?? {},
        ).reduce(
          (mapping, [key, adSlot]) => {
            if (adSlot) {
              const isPrimary = adSlot.isDisabled === undefined;
              const slot = {
                ...adSlot,
                isDisabled: adSlot?.isDisabled === true || isShowInlinePaywall === undefined,
              } as InlineAdSlotProps["slot"];

              if (isPrimary) mapping.primary[key] = slot;
              mapping.secondary[key] = slot;
            }
            return mapping;
          },
          {
            primary: {} as Record<string, InlineAdSlotProps["slot"]>,
            secondary: {} as Record<string, InlineAdSlotProps["slot"]>,
          },
        );

        const numberOfMoreReadableElements = 5;

        const handleRenderContentBody = () => (
          <>
            <PrimaryContentBody
              {...props}
              advertising={advertising}
              inlineAdSlots={primaryInlineAdSlots}
              preprocessSchema={schema => {
                const templateProcessedSchema = props?.preprocessSchema?.(schema) ?? schema;
                const outstreamAdSlotPosition = getReadMoreSeparateIndex(templateProcessedSchema);

                if (isBot || isLightHouseCrawler)
                  return templateProcessedSchema.slice(
                    0,
                    outstreamAdSlotPosition + numberOfMoreReadableElements,
                  );
                if (articleType === articleData.types.live.entityId) return templateProcessedSchema;
                const defaultPaywallPosition = outstreamAdSlotPosition - 1;
                const paywallPosition =
                  getPaywallPosition(templateProcessedSchema) ?? defaultPaywallPosition;

                return isShowInlinePaywall
                  ? templateProcessedSchema.slice(0, paywallPosition)
                  : templateProcessedSchema.slice(
                      0,
                      outstreamAdSlotPosition + numberOfMoreReadableElements,
                    );
              }}
              reference={article}
            >
              {!isBot && !isLightHouseCrawler && isShowInlinePaywall && (
                <InlinePaywallOverlayer
                  $height={isMostLikelyToSubscribeUser ? "small" : "normal"}
                  $variant={props?.paywallVariant}
                />
              )}
              {/* Prevent show read more before paywall has been executed */}
              {isShowInlinePaywall === false && (
                <ContentReadMoreButtonContainer>
                  <ArticleSpeechSkipContainer>
                    <ContentReadMoreButton reference={article} />
                  </ArticleSpeechSkipContainer>
                </ContentReadMoreButtonContainer>
              )}
            </PrimaryContentBody>

            <ArticleSpeechSkipContainer
              breakpointString={
                isReadMoreClicked && isMobile
                  ? theme.breakpoints.up("tablet")
                  : theme.breakpoints.down("tablet")
              }
            >
              <SecondlyContentBody
                {...props}
                $isShow={isReadMoreClicked}
                advertising={advertising}
                inlineAdSlots={secondInlineAdSlots}
                preprocessSchema={schema => {
                  if (
                    articleType === articleData.types.live.entityId ||
                    (!isBot &&
                      !isLightHouseCrawler &&
                      renderType !== ArticleRenderType.Plus &&
                      isShowInlinePaywall !== false)
                  )
                    return [];

                  const templateProcessedSchema = props?.preprocessSchema?.(schema) ?? schema;
                  const outstreamAdSlotPosition = getReadMoreSeparateIndex(templateProcessedSchema);
                  return templateProcessedSchema.slice(
                    outstreamAdSlotPosition + numberOfMoreReadableElements,
                  );
                }}
                reference={article}
              >
                <ArticlePrintHeadlineMessage
                  reference={article}
                  variant={props?.printHeadlineVariant}
                />
              </SecondlyContentBody>
            </ArticleSpeechSkipContainer>
          </>
        );

        const contentBody = handleRenderContentBody();
        if (props?.isDisabledArticleImageGallery === true) return contentBody;
        return <ArticleImageGallery reference={article}>{contentBody}</ArticleImageGallery>;
      },
      bottomCarouselWidget: (provider, props) => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <StyledArticleCarouselWidget
              {...props}
              items={
                props?.items ?? [
                  provider?.scmpPollWidget({
                    adSlotProps: {
                      breakpoint: theme.breakpoints.down("desktop"),
                      zone: advertising.zone,
                    },
                  }),
                  provider?.beforeYouGoWidget({
                    children: provider?.relatedTopics(),
                  }),
                  provider?.topicWidget(),
                  provider?.geoLocationWidget(),
                ]
              }
            />
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      commentTrigger: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledCommentContainer>
                <StyledArticleCommentsTrigger reference={article} variant="banner" {...props} />
              </StyledCommentContainer>
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      correction: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleCorrectionsWidget reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      furtherReading: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleFurtherReadingWidget reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      geoLocationWidget: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleGeoLocationWidget reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      headline: props => <StyledContentHeadline componentTag="h1" reference={article} {...props} />,
      leading: props =>
        props?.withExternalGallery || props?.skipGallery ? (
          <ArticleSpeechSkipContainer>
            <StyledArticleLeading
              responsiveVariants={{
                desktopUp: ImageSize["1200x800"],
                mobileUp: ImageSize["768x768"],
                tabletUp: ImageSize["1020x680"],
              }}
              {...props}
              advertising={{
                ...advertising,
                ...props?.advertising,
              }}
              reference={article}
            />
          </ArticleSpeechSkipContainer>
        ) : (
          <ArticleImageGallery reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleLeading
                responsiveVariants={{
                  desktopUp: ImageSize["1200x800"],
                  mobileUp: ImageSize["768x768"],
                  tabletUp: ImageSize["1020x680"],
                }}
                {...props}
                advertising={{
                  ...advertising,
                  ...props?.advertising,
                }}
                reference={article}
              />
            </ArticleSpeechSkipContainer>
          </ArticleImageGallery>
        ),
      liveTag: props => <StyledArticleLiveTag reference={article} {...props} />,
      moreOnThis: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleMoreOnThisList reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      paywall: props => (
        <StyledArticlePaywallContainer isShowInlinePaywall={isShowInlinePaywall} {...props} />
      ),
      publishedDate: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleDate reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      readingTime: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleReadingTime reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      relatedTopics: props => <StyledArticleRelatedTopics reference={article} {...props} />,
      scmpPollWidget: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleScmpPollWidget {...props} />
        </ArticleSpeechSkipContainer>
      ),
      sections: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleSections reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      series: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleSeries reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      shareWidget: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleShareWidget reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      speechPlayer: props => (
        <StyledArticleSpeechInlineWidget
          onClick={() => {
            setIsReadMoreClicked?.(true);
          }}
          reference={article}
          variant="generic"
          {...props}
        />
      ),
      speechPlayerPortal: props => (
        <ArticleSpeechPlayerPortal
          onClick={() => {
            setIsReadMoreClicked?.(true);
          }}
          reference={article}
          variant="generic-minify"
          {...props}
        />
      ),
      sponsor: props => <StyledArticleSponsor reference={article} {...props} />,
      subHeadline: props =>
        !(isShowInlinePaywall === true && isMostLikelyToSubscribeUser) && (
          <StyledContentSubHeadline reference={article} {...props} />
        ),
      takeOverAdSlot: props => <TakeoverAdSlot adSlotProps={advertising} {...props} />,
      topic: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleTopic reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      topicFollowWidget: props => (
        <ArticleSpeechSkipContainer>
          <StyledArticleTopicFollowWidget reference={article} {...props} />
        </ArticleSpeechSkipContainer>
      ),
      topics: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleTopics reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      topicWidget: props => (
        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <ArticleSpeechSkipContainer>
              <StyledArticleTopicWidget reference={article} {...props} />
            </ArticleSpeechSkipContainer>
          </GlobalChecker>
        </ClientSideSuspense>
      ),
      vocabBox: props => <StyledArticleVocabBox reference={article} {...props} />,
    }),
    [
      article,
      isShowInlinePaywall,
      advertising,
      isBot,
      isLightHouseCrawler,
      isMostLikelyToSubscribeUser,
      isReadMoreClicked,
      isMobile,
      articleType,
      getPaywallPosition,
      renderType,
      setIsReadMoreClicked,
    ],
  );

  const { handleRenderPostiesArticle } = usePostiesArticle(provider, article);

  const handleRenderApplicationArticle = useCallback(() => {
    switch (renderType) {
      case ArticleRenderType.Generic:
        return (
          <DynamicGenericArticle
            isInlinePaywallDomChanged={isInlinePaywallDomChanged}
            isShowInlinePaywall={isShowInlinePaywall}
            provider={provider}
            reference={article}
          />
        );
      case ArticleRenderType.Infographic:
      case ArticleRenderType.InfographicLongRead:
        return (
          <DynamicInfographicArticle
            isShowInlinePaywall={isShowInlinePaywall}
            provider={provider}
            reference={article}
          />
        );
      case ArticleRenderType.Live:
        return (
          <DynamicLiveArticle
            isInlinePaywallDomChanged={isInlinePaywallDomChanged}
            isShowInlinePaywall={isShowInlinePaywall}
            provider={provider}
            reference={article}
          />
        );
      case ArticleRenderType.LongRead:
      case ArticleRenderType.PhotoEssay:
        return (
          <DynamicPhotoEssayAndLongReadArticle
            isShowInlinePaywall={isShowInlinePaywall}
            provider={provider}
            reference={article}
          />
        );
      case ArticleRenderType.MorningStudio:
        return <DynamicMorningStudioArticle provider={provider} reference={article} />;
      case ArticleRenderType.Newsletter:
        return <DynamicNewsletterArticle provider={provider} reference={article} />;
      case ArticleRenderType.Plus:
        return <DynamicPlusGenericArticle provider={provider} reference={article} />;
      case ArticleRenderType.Posties:
        return handleRenderPostiesArticle();
      case ArticleRenderType.PostMagazine:
        return (
          <DynamicPostMagazineArticle
            isShowInlinePaywall={isShowInlinePaywall}
            provider={provider}
            reference={article}
          />
        );
      case ArticleRenderType.Series:
        return <DynamicSeriesArticle provider={provider} reference={article} />;
      case ArticleRenderType.Style:
        return <DynamicStyleArticle provider={provider} reference={article} />;
    }
  }, [
    article,
    handleRenderPostiesArticle,
    isInlinePaywallDomChanged,
    isShowInlinePaywall,
    provider,
    renderType,
  ]);

  useEffect(() => {
    if (isShowInlinePaywall !== undefined) {
      setIsContentReady?.(true);
      setEntityId?.(article?.entityId);
    }
    return;
  }, [article.entityId, isShowInlinePaywall, setEntityId, setIsContentReady]);

  return (
    <ArticleRenderContainer $renderType={renderType} ref={articleElementReference}>
      <AuthorAvatarContextProvider
        showBorderColor={articleType === articleData.types.column.entityId}
      >
        <ThemeProvider theme={defaultTheme}>{handleRenderApplicationArticle()}</ThemeProvider>
      </AuthorAvatarContextProvider>
    </ArticleRenderContainer>
  );
};

Component.displayName = "ArticleRender";
export const ArticleRender = withArticleRenderContext(Component);
