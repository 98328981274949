/**
 * @generated SignedSource<<0c0fcd45508c90a962913eb54fa4aafa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pianoIntegrationArticle$data = {
  readonly __typename: "Article";
  readonly authors: ReadonlyArray<{
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly contentLock: boolean | null | undefined;
  readonly createdDate: number | null | undefined;
  readonly entityId: string;
  readonly entityUuid: string;
  readonly headline: string | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityUuid: string;
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly sponsor: string | null | undefined;
  readonly sponsorType: string | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"helpersAppBarVariantBase" | "helpersCheckCanShowInPlusPlatformContent" | "helpersCheckIsPlusArticle" | "helpersCheckIsPostiesArticle" | "helpersIsArchiveWallArticle" | "helpersIsVelocityWallArticle" | "hooksPaywallPromiseContent" | "shareContentUnlockedArticle">;
  readonly " $fragmentType": "pianoIntegrationArticle";
};
export type pianoIntegrationArticle$key = {
  readonly " $data"?: pianoIntegrationArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"pianoIntegrationArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityUuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sponsorType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlAlias",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v6/*: any*/)
    ],
    "type": "Page",
    "abstractKey": null
  }
],
v10 = [
  (v2/*: any*/)
],
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v10/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "InlineFragment",
    "selections": (v11/*: any*/),
    "type": "Article",
    "abstractKey": null
  }
],
v13 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "fullSectionPath",
        "plural": true,
        "selections": (v10/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Section",
    "abstractKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PaywallType",
  "kind": "LinkedField",
  "name": "paywallTypes",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v15 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersIsVelocityWallArticle",
  "selections": [
    (v14/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v16 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaywallType",
        "kind": "LinkedField",
        "name": "paywallTypes",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pianoIntegrationArticle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLock",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sponsor",
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleTypeSegment",
      "kind": "LinkedField",
      "name": "types",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleType",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersAppBarVariantBase",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsLiveArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "flattenTypeIds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LiveArticle",
                      "kind": "LinkedField",
                      "name": "liveArticle",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPlusPage",
              "selections": (v9/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPostiesArticle",
              "selections": (v12/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPostiesPage",
              "selections": (v9/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPostMagazineArticle",
              "selections": (v12/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsStyleArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v11/*: any*/),
                  "type": "Content",
                  "abstractKey": "__isContent"
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersSectionContent",
              "selections": (v13/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersStyleSectionContent",
              "selections": (v13/*: any*/),
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "BaseWithApplicationAndUrlAlias",
          "abstractKey": "__isBaseWithApplicationAndUrlAlias"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksPaywallPromiseContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersIsArchiveWallArticle",
      "selections": [
        (v15/*: any*/),
        (v1/*: any*/),
        (v4/*: any*/),
        (v14/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v15/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostiesArticle",
      "selections": (v11/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPlusArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v16/*: any*/),
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckCanShowInPlusPlatformContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckArticleIsInSectionsArticle",
              "selections": (v11/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsPlusArticle",
              "selections": (v16/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsStyleArticle",
              "selections": (v11/*: any*/),
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersGetArticleTypeArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleTypeSegment",
                      "kind": "LinkedField",
                      "name": "types",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ArticleType",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": (v10/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersUseContentSponsorContent",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Topic",
                  "kind": "LinkedField",
                  "name": "topics",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sponsor",
                      "kind": "LinkedField",
                      "name": "sponsor",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "sponsorTagSponsor"
                        },
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v5/*: any*/)
                  ],
                  "type": "Article",
                  "abstractKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersCheckIsMainTopicContent",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Topic",
                      "kind": "LinkedField",
                      "name": "topics",
                      "plural": true,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "shareContentUnlockedArticle",
      "selections": (v10/*: any*/),
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "eeab186507d102193fdacb57b4be3801";

export default node;
