import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;

  inline-size: 100%;

  overflow: scroll hidden;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-padding: 20px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    inline-size: fit-content;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  max-inline-size: min-content;
`;
