import { useFullscreen } from "ahooks";
import type { FunctionComponent } from "react";
import { useContext, useRef } from "react";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

import { ToolbarButton } from "./toolbar-button";

export const ToolbarButtonFullscreen: FunctionComponent = () => {
  const { rootPortalElement } = useContext(GalleryContext);
  const rootPortalElementReference = useRef(rootPortalElement);
  const [isFullscreen, { toggleFullscreen }] = useFullscreen(rootPortalElementReference);

  return (
    <ToolbarButton
      icon={isFullscreen ? "fullscreen_exit" : "fullscreen"}
      onClick={() => {
        toggleFullscreen();
      }}
      title="Toggle Fullscreen"
    />
  );
};

ToolbarButtonFullscreen.displayName = "ToolbarButtonFullscreen";
