import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { ContentItemArticleWidget } from "scmp-app/components/content/content-item-render/variants/article-widget";
import { EntityLink } from "scmp-app/components/entity-link";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";
import {
  StyledDesktopEntityFollowButton,
  StyledMobileEntityFollowButton,
} from "scmp-app/components/home/follow-button/styles";

export const Divider = styled.div`
  inline-size: 100%;
  block-size: 1px;
  margin-block: 16px;

  background: #333333;
`;

export const StyledHomeFollowButton = styled(HomeFollowButton)`
  display: flex;

  min-inline-size: max-content;
  margin-block-start: 8px;
  ${StyledMobileEntityFollowButton} {
    display: block;
  }
  ${StyledDesktopEntityFollowButton} {
    display: none;
  }
`;

export const FollowButtonText = styled.span`
  display: flex;
  gap: 3px;
  align-items: center;

  margin-inline-start: 6px;

  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;
`;

export const StyledContentItemArticleWidget = styled(ContentItemArticleWidget)``;

export const Container = styled.div`
  inline-size: 300px;
  block-size: 100%;
  min-inline-size: 295px;
  padding: 20px;

  font-family: ${fontRobotoCondensed};

  border: 1px solid #cccccc;
  border-radius: 8px;

  background: #ffffff;

  ${theme.breakpoints.up("desktop")} {
    block-size: unset;
  }
`;

export const Header = styled.div`
  color: #4585ff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
`;

export const TopicName = styled(EntityLink)`
  margin-block-start: 8px;

  color: #000000;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledBaseLink = styled(BaseLink)`
  display: flex;

  padding-block-start: 1px;
`;

export const ContentContainer = styled.div`
  ${StyledContentItemArticleWidget}:not(:last-child) {
    margin-block-end: 12px;
    padding-block-end: 12px;
    border-block-end: 1px solid #cccccc;
  }
`;
