/**
 * @generated SignedSource<<b992624245ee32d3efac855932398536>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersIsVelocityWallArticle$data = {
  readonly paywallTypes: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "helpersIsVelocityWallArticle";
};
export type helpersIsVelocityWallArticle$key = {
  readonly " $data"?: helpersIsVelocityWallArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersIsVelocityWallArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersIsVelocityWallArticle"
};

(node as any).hash = "5bf52b16f389a82e4b98aa30c1c43405";

export default node;
