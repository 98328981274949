/**
 * @generated SignedSource<<94d7344219bacdc480fbef168f4a8325>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contextsContentReadMoreContextProviderContent$data = {
  readonly sponsorType?: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsStyleArticle" | "helpersGetArticleTypeArticle">;
  readonly " $fragmentType": "contextsContentReadMoreContextProviderContent";
};
export type contextsContentReadMoreContextProviderContent$key = {
  readonly " $data"?: contextsContentReadMoreContextProviderContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contextsContentReadMoreContextProviderContent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "entityId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contextsContentReadMoreContextProviderContent",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleTypeSegment",
              "kind": "LinkedField",
              "name": "types",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleType",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsStyleArticle",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SectionSegment",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Section",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sponsorType",
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "0df874bf8903f2533caac5afe2e3992f";

export default node;
