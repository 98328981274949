import styled from "@emotion/styled";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import {
  StyledImage as AuthorAvatarImage,
  StyledPlaceholder as AuthorPlaceholder,
} from "scmp-app/components/author/author-avatar/styles";
import { AuthorBio } from "scmp-app/components/author/author-bio";
import { AuthorName } from "scmp-app/components/author/author-name";

export const StyledAuthorName = styled(AuthorName)`
  line-height: 16px;
`;

export const StyleAuthorCardContainer = styled.div`
  display: flex;
`;

export const StyleAuthorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyleAvatarContainer = styled.div`
  margin-inline-end: 16px;

  ${AuthorAvatarImage}, ${AuthorPlaceholder} {
    inline-size: 60px;
    block-size: 60px;
  }
`;

export const StyledAuthorAvatar = styled(AuthorAvatar)`
  inline-size: 60px;
  block-size: 60px;

  cursor: pointer;
`;

export const StyleNameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  margin-block-end: 4px;
`;

export const StyledStyleAuthorName = styled(AuthorName)`
  color: #333333;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;

  cursor: pointer;
`;

export const StyledAuthorBio = styled(AuthorBio)`
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Expertise = styled.div`
  color: #333333;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
