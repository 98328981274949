import { findFirstNonEmptyString } from "@product/scmp-sdk";
import { type FunctionComponent, type ReactNode, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { ImageGallery } from "scmp-app/components/image-gallery";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import type { articleImageGalleryContent$key } from "scmp-app/queries/__generated__/articleImageGalleryContent.graphql";

type Props = {
  children?: ReactNode;
  reference: articleImageGalleryContent$key;
};

export const ArticleImageGallery: FunctionComponent<Props> = ({
  children,
  reference: reference_,
}) => {
  const content = useFragment(
    graphql`
      fragment articleImageGalleryContent on Content {
        ...helpersCheckIsStyleArticle
        entityId
        urlAlias
        shortUrl
        headline
        socialHeadline
      }
    `,
    reference_,
  );

  const title = findFirstNonEmptyString(content.socialHeadline, content.headline);

  const variant = useMemo(
    () => (checkIsStyleArticle(content) ? MagazinesStyleVariant : undefined),
    [content],
  );

  return (
    <ImageGallery
      $variant={variant}
      relativeUrl={content.urlAlias}
      shortUrl={content.shortUrl ?? undefined}
      title={title}
      utm={{
        campaign: content.entityId,
        medium: "image_gallery",
      }}
    >
      {children}
    </ImageGallery>
  );
};

ArticleImageGallery.displayName = "ArticleImageGallery";
