import { theme, useResponsive } from "@product/scmp-sdk";
import type { FunctionComponent, ReactNode } from "react";

import { useContentReadMoreContext } from "scmp-app/components/content/content-read-more/contexts";

type Props = {
  children?: ReactNode;
};

export const ContentReadMoreChecker: FunctionComponent<Props> = ({ children }) => {
  const { isReadMoreClicked } = useContentReadMoreContext();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false, {
    initializeWithValue: true,
  });
  return isReadMoreClicked || !isMobile ? <>{children}</> : null;
};

ContentReadMoreChecker.displayName = "ContentReadMoreChecker";
