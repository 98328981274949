import type { ReactNode } from "react";
import { createContext, startTransition, useContext, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { article } from "shared/data";
import { articleSponsorType } from "shared/data/article";

import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import type { contextsContentReadMoreContextProviderContent$key } from "scmp-app/queries/__generated__/contextsContentReadMoreContextProviderContent.graphql";

type ContextValue = {
  isReadMoreClicked: boolean;
  setIsReadMoreClicked?: (isReadMoreClicked: boolean) => void;
};

const ContentReadMoreContext = createContext<ContextValue>({
  isReadMoreClicked: false,
});

type Props = {
  children: ReactNode;
  reference: contextsContentReadMoreContextProviderContent$key;
};

export const ContentReadMoreContextProvider: React.FunctionComponent<Props> = ({
  children,
  reference,
}) => {
  const content = useFragment(
    graphql`
      fragment contextsContentReadMoreContextProviderContent on Content {
        ...helpersGetArticleTypeArticle
        ...helpersCheckIsStyleArticle
        ... on Article {
          sponsorType
        }
      }
    `,
    reference,
  );

  const mainArticleType = getArticleType(content);
  const disabledArticleTypes = new Set<string>([
    article.types.live.entityId,
    article.types.newsletter.entityId,
    article.types.series.entityId,
    article.types.studio.entityId,
  ]);

  const isDisableReadMore =
    disabledArticleTypes.has(mainArticleType ?? "") ||
    articleSponsorType.has(content.sponsorType ?? "");

  const [isReadMoreClicked, setIsReadMoreClicked] = useState(isDisableReadMore);

  const setNextIsReadMoreClicked = (nextIsReadMoreClicked: boolean) => {
    startTransition(() => {
      setIsReadMoreClicked(nextIsReadMoreClicked);
    });
  };

  return (
    <ContentReadMoreContext.Provider
      value={{
        isReadMoreClicked,
        setIsReadMoreClicked: setNextIsReadMoreClicked,
      }}
    >
      {children}
    </ContentReadMoreContext.Provider>
  );
};
ContentReadMoreContextProvider.displayName = "ContentReadMoreContextProvider";

export const useContentReadMoreContext = () => useContext(ContentReadMoreContext);
