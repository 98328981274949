import styled from "@emotion/styled";

export const CaptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  min-block-size: 44px;
  padding: 10px;

  background-color: rgba(0, 0, 0, 0.3);
`;

export const CaptionLabel = styled.span`
  max-inline-size: 420px;

  color: var(--pswp-icon-color);
  font-size: 14px;
  text-align: start;
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
`;
