import { FollowType, notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { FunctionComponent, ReactNode } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { articleSponsorType } from "shared/data/article";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useOptimizelyContext } from "scmp-app/lib/optimizely";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import type { articleTopicWidgetArticle$key } from "scmp-app/queries/__generated__/articleTopicWidgetArticle.graphql";

import MyNewsIcon from "./icon-my-news.svg";
import {
  Container,
  ContentContainer,
  Divider,
  FollowButtonText,
  Header,
  StyledBaseLink,
  StyledContentItemArticleWidget,
  StyledHomeFollowButton,
  TopicName,
} from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
  reference: articleTopicWidgetArticle$key;
};

export const ArticleTopicWidget: FunctionComponent<Props> = ({
  children,
  className,
  reference,
}) => {
  const { firstTopic, ...content } = useFragment(
    graphql`
      fragment articleTopicWidgetArticle on Article {
        entityId
        sponsorType
        firstTopic {
          ...entityLink
          ...followButtonBase
          name
          disableFollow
          contents(first: 4, orderBy: { field: PUBLISHED_DATE, direction: DESC }) {
            edges {
              node {
                entityId
                ...articleWidgetContent
              }
            }
          }
        }
      }
    `,
    reference,
  );

  const { fireEvent } = useOptimizelyContext();

  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isSubscriber = useMemo(
    () => asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false,
    [asyncRosettaState?.result?.user?.isSCMPSubscriber],
  );

  const widgetName = "topic_widget";

  const ga4ImpressionEvent = () =>
    ({
      action: "imp",
      category: "recirculation",
      customized_parameters: {
        block_section: firstTopic?.name ?? "",
        page_type: "article",
        widget_name: widgetName,
        widget_version: isSubscriber ? "subscriber" : "non_subscriber",
      },
      subcategory: "widget",
    }) as const;

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: ga4ImpressionEvent,
      options: {
        intersectionThreshold: [0.1],
        isSendGA4Tracking: true,
        shouldSendOnce: true,
        shouldThrottle: true,
      },
    });

  const getContentItemArticleWidgetClickHandler = (articleId?: string, urlAlias?: string) => {
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: articleId,
        block_section: firstTopic?.name ?? "",
        destination_url: urlAlias,
        page_type: "article",
        widget_name: widgetName,
        widget_version: isSubscriber ? "subscriber" : "non_subscriber",
      },
      subcategory: "widget",
    });
    fireEvent?.({
      eventName: "item_click",
    });
  };

  const contents = firstTopic?.contents?.edges
    .filter(edge => edge.node.entityId !== content.entityId)
    .slice(0, 3);

  const isSponsoredContent = articleSponsorType.has(content.sponsorType ?? "");

  if (isSponsoredContent || !notEmpty(firstTopic)) {
    return null;
  }

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "topic_widget",
        pgtype: "article",
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Header>Discover MORE stories on</Header>

        <TopicName reference={firstTopic}>{firstTopic.name}</TopicName>

        {!firstTopic.disableFollow && (
          <StyledHomeFollowButton
            reference={firstTopic}
            source={widgetName}
            type={FollowType.Topic}
          >
            <FollowButtonText>
              now and stay updated with
              <StyledBaseLink anchorProps={{ "aria-label": "my news" }} pathname="/mynews">
                <MyNewsIcon />
              </StyledBaseLink>
            </FollowButtonText>
          </StyledHomeFollowButton>
        )}
        <Divider />
        <ContentContainer>
          {contents?.map(edge => (
            <StyledContentItemArticleWidget
              key={edge.node.entityId}
              onClick={getContentItemArticleWidgetClickHandler}
              reference={edge.node}
              withCoverImage={false}
            />
          ))}
        </ContentContainer>
        {children && (
          <>
            <Divider />
            {children}
          </>
        )}
      </Container>
    </BaseLinkContextProvider>
  );
};

ArticleTopicWidget.displayName = "ArticleTopicWidget";
