import { FollowButtonDisplay, FollowButtonOutline, FollowButtonSize } from "@product/scmp-sdk";
import { Fragment, type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";

import type { articleRelatedTopicsContent$key } from "scmp-app/queries/__generated__/articleRelatedTopicsContent.graphql";

import {
  ArticleTopicsContainer,
  Container,
  ItemsContainer,
  Label,
  MainItem,
  StyledEntityFollowButton,
  StyledTopicLink,
  Title,
  Topics,
} from "./styles";

type Props = {
  className?: string;
  label?: string;
  reference: articleRelatedTopicsContent$key;
};

export const ArticleRelatedTopics: FunctionComponent<Props> = ({ className, label, reference }) => {
  const { topics } = useFragment(
    graphql`
      fragment articleRelatedTopicsContent on Content {
        topics {
          ...topicLinkTopic
          ...entityFollowButtonBase
          entityId
        }
      }
    `,
    reference,
  );

  if (!topics || topics.length === 0) return null;
  const [mainTopic, ...otherTopics] = topics.slice(0, 10);

  const renderArticleTopics = () => (
    <ArticleTopicsContainer>
      {label && <Label>{label}</Label>}
      <Topics>
        {mainTopic && (
          <MainItem>
            <StyledTopicLink preferShortName reference={mainTopic} />
            <StyledEntityFollowButton
              display={FollowButtonDisplay.BlueIcon}
              outline={FollowButtonOutline.Plain}
              reference={mainTopic}
              size={FollowButtonSize.Compact}
              source="Article_topic_footer"
            />
          </MainItem>
        )}

        <ItemsContainer>
          {otherTopics.map((topic, index) => {
            if (!topic) return null;
            return (
              <Fragment key={topic.entityId}>
                {index > 0 && ` | `}
                <StyledTopicLink preferShortName reference={topic} />
              </Fragment>
            );
          })}
        </ItemsContainer>
      </Topics>
    </ArticleTopicsContainer>
  );

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "related_topics",
        pgtype: "article",
      }}
    >
      <Container className={className}>
        <Title>related topics</Title>
        {renderArticleTopics()}
      </Container>
    </BaseLinkContextProvider>
  );
};
ArticleRelatedTopics.displayName = "ArticleRelatedTopics";
