/* eslint-disable react/no-multi-comp */
import styled from "@emotion/styled";
import { useDeepCompareMemo } from "@react-hookz/web";

import type {
  ContentSchemaRenderProps,
  RenderFunction,
} from "scmp-app/components/schema-render/content";

import { Iframe as ContentSchemaRenderIframe } from ".";
import type { ExtraProps } from "./scmp-youtube";

const getRenderIframeComponent = (extraProps: ExtraProps) => {
  const Component = (p: ContentSchemaRenderProps) => (
    <ContentSchemaRenderIframe {...p} {...extraProps} />
  );
  return styled(Component)``;
};

export function useContentSchemaRenderIframe(extraProps: ExtraProps) {
  const ComponentWithExtraProps = useDeepCompareMemo(
    () => getRenderIframeComponent(extraProps),
    [extraProps],
  );

  const ComponentWithExtraPropsRenderFunction: RenderFunction<ExtraProps> = (
    props,
    itemIndex,
    index,
    _children,
    schemaNode,
    parentSchemaNode,
  ) => (
    <ContentSchemaRenderIframe
      {...props}
      index={index}
      key={itemIndex}
      parentSchemaNode={parentSchemaNode}
      schemaNode={schemaNode}
      {...extraProps}
    />
  );

  return {
    ComponentWithExtraProps,
    ComponentWithExtraPropsRenderFunction,
  };
}
