import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { ArticleSpeechInlineWidgetGeneric } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic";
import {
  MinifyContainer,
  StyledCloseIcon,
  Text,
} from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/button/styles";
import { IconWrapper } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/empty-button/styles";

export const StyledArticleSpeechInlineWidgetGeneric = styled(ArticleSpeechInlineWidgetGeneric)`
  ${IconWrapper} {
    background-color: transparent;

    svg {
      inline-size: 24px !important;
      block-size: 24px !important;
    }
  }

  ${StyledCloseIcon} {
    margin-inline-start: 4px;
  }

  svg:not(${StyledCloseIcon}) path {
    fill: #000000 !important;
  }
`;

const textStyle = css`
  color: #000000 !important;
  font-weight: 700;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;
`;

export const Container = styled.div`
  :hover {
    ${Text} {
      text-decoration: underline;
    }
  }

  ${MinifyContainer}, ${IconWrapper} {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
    justify-content: center;
    align-items: center;

    inline-size: auto !important;
    block-size: 36px !important;
    padding: 0 !important;
  }

  ${IconWrapper} {
    ::before {
      content: "Listen";

      ${textStyle}
    }
  }

  ${Text} {
    ${textStyle}
  }
`;
