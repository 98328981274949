import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { Tooltip, tooltipClasses } from "scmp-app/components/common/tooltip";

import Close from "./close.svg";

export const ClickAwayListenerWrapper = styled.div``;

export const StyledTooltip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    background-color: #001246;
  }

  & .${tooltipClasses.arrow} {
    color: #001246;
    font-size: 20px;
  }
`;

export const StyledClose = styled(Close)`
  flex: none;

  cursor: pointer;
`;

export const ToolTipMessage = styled.div`
  display: flex;
  gap: 10px;

  padding: 14px;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 22px;
`;

export const LogInText = styled.span`
  color: #ffca05;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 22px;
  text-decoration: underline;

  cursor: pointer;
`;

export const Link = styled.a`
  color: #ffca05;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 22px;
  text-decoration: underline;
`;
