/**
 * @generated SignedSource<<d37893e0dfe5da3512a9eb2b8a41dbd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type beforeYouGoActionWidgetContent$data = {
  readonly entityId: string;
  readonly headline: string | null | undefined;
  readonly shortUrl: string | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleShareWidgetArticle" | "shareArticle20Content">;
  readonly " $fragmentType": "beforeYouGoActionWidgetContent";
};
export type beforeYouGoActionWidgetContent$key = {
  readonly " $data"?: beforeYouGoActionWidgetContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"beforeYouGoActionWidgetContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "beforeYouGoActionWidgetContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shareArticle20Content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleShareWidgetArticle"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "f4ccf5e6a4af5a200604af2d1ecf8d71";

export default node;
