import styled from "@emotion/styled";

import { Button } from "scmp-app/components/common/button";

import SmartphoneIcon from "./icon-smartphone.svg";

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;

  padding: 16px;

  color: #ffffff;

  background: #4585ff;
`;

export const StyledSmartphoneIcon = styled(SmartphoneIcon)`
  margin-inline-start: 6px;
`;
