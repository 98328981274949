import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { SubscriptionWidget } from "scmp-app/components/posties/subscription-widget";

export const StyledSubscriptionWidget = styled(SubscriptionWidget)`
  max-block-size: calc(100vh - 200px);

  ${theme.breakpoints.up("desktop")} {
    max-block-size: calc(100vh - 150px);
    padding: 48px;
  }
`;
