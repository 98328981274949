import { NoSsr } from "@mui/material";
import { notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import first from "lodash/first";
import last from "lodash/last";
import type { FunctionComponent } from "react";
import { Fragment } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { graphql, useFragment } from "react-relay";

import { formatInTimeZone } from "shared/lib/utils";

import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";
import { useArticleBaseAdvertisingInfo } from "scmp-app/components/article/article-render/hooks";
import {
  GenericPostiesArticleTableThead,
  GenericPostiesArticleTableTr,
} from "scmp-app/components/article/article-render/posties/generic-posties-article/table/styles";
import {
  extractAfterPrefix,
  hasPostiesQuiz,
} from "scmp-app/components/article/article-render/posties/helpers";
import { useArticleFormLink } from "scmp-app/components/article/article-render/posties/hooks";
import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { ContentBody } from "scmp-app/components/content/content-body";
import { FormPrefixes } from "scmp-app/components/posties/form-drawer/consts";
import { usePostiesFormDrawerContext } from "scmp-app/components/posties/form-drawer/contexts";
import { DynamicPostiesFormDrawer } from "scmp-app/components/posties/form-drawer/dynamics";
import { PostiesPaywallContainer } from "scmp-app/components/posties/paywall-container";
import { DynamicPostiesQuiz } from "scmp-app/components/posties/quiz/dynamics";
import { Link } from "scmp-app/components/schema-render/common/link";
import type { genericPostiesArticleContent$key } from "scmp-app/queries/__generated__/genericPostiesArticleContent.graphql";

import { GenericPostiesArticleBlockquoteQuote } from "./blockquote-quote";
import {
  Authors,
  AuthorsAndPublishedDate,
  Content,
  ContentContainer,
  Correction,
  DesktopLeft,
  DesktopRight,
  DesktopVocabBox,
  FormLink,
  GenericPostiesArticleContainer,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Headline,
  ListenNow,
  MobileBottom,
  MobileTop,
  MobileVocabBox,
  PostiesQuizWrapper,
  PublishedDate,
  Right,
  Section,
  StyledGenericPostiesArticleVocabBox,
  StyledGenericPostiesLeading,
  StyledPostiesSubHeadline,
  StyledTopBackgroundImage,
  TopBackgroundContainer,
  TopBlock,
} from "./styles";
import { GenericPostiesArticleTable } from "./table";
import TopBackgroundQuiz from "./top-background-quiz.png";
import TopBackground from "./top-background.png";

type Props = {
  provider: DefaultComponentsProvider;
  reference: genericPostiesArticleContent$key;
};

export const GenericPostiesArticle: FunctionComponent<Props> = ({
  provider,
  reference: reference_,
}) => {
  const article = useFragment(
    graphql`
      fragment genericPostiesArticleContent on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        sections {
          value {
            name
            urlAlias
          }
        }
        authors {
          name
        }
        publishedDate
        additionalInfoBoxes {
          ...articleAdditionalInfoBoxArticle
        }

        ...genericPostiesArticleVocabBoxArticle
        ...blockquoteQuoteGenericPostiesArticleContent
        ...articleBaseAdvertisingInfoArticle
        ...contentBodyContent @arguments(customContents: $customContents, bodyType: $bodyType)
        ...helpersHasPostiesQuiz
        ...quizPostiesQuizArticle
      }
    `,
    reference_,
  );

  const additionalInfoBoxes = article?.additionalInfoBoxes
    ?.filter(notEmpty)
    .map?.((item, index) => (
      <Fragment key={index}>{provider.articleAdditionalInfoBox({ reference: item })}</Fragment>
    ));

  const [mobileTopAdditionInfoBox, ...mobileBottomAdditionInfoBoxes] = additionalInfoBoxes ?? [];

  const advertising = useArticleBaseAdvertisingInfo(article);
  const section = last(first(article?.sections)?.value);
  const { toggleByUrl } = useArticleFormLink();

  const isDesktopAndAbove = useResponsive(theme.breakpoints.up("desktop"), true);
  const hasQuiz = hasPostiesQuiz(article);

  const { formDrawer } = usePostiesFormDrawerContext();

  return (
    <GenericPostiesArticleContainer>
      <NoSsr>
        <ErrorBoundary fallback={<></>}>
          {formDrawer?.open && <DynamicPostiesFormDrawer />}
        </ErrorBoundary>
      </NoSsr>

      <TopBackgroundContainer>
        <StyledTopBackgroundImage src={hasQuiz ? TopBackgroundQuiz.src : TopBackground.src} />
      </TopBackgroundContainer>
      <TopBlock>
        {notEmpty(section) && (
          <ArticleSpeechSkipContainer>
            <Section pathname={section.urlAlias}>{section?.name}</Section>
          </ArticleSpeechSkipContainer>
        )}
        <Headline>{provider.headline()}</Headline>
        <AuthorsAndPublishedDate>
          <Authors>{article?.authors?.map(author => author?.name).join(", ")}</Authors>
          <PublishedDate>
            {article?.publishedDate &&
              `${formatInTimeZone(article.publishedDate ?? "", "d MMM, yyyy")}`}
          </PublishedDate>
        </AuthorsAndPublishedDate>
      </TopBlock>
      <StyledPostiesSubHeadline>
        {provider.subHeadline({ disableFallbackEmptyToSummary: true })}
      </StyledPostiesSubHeadline>
      <StyledGenericPostiesLeading
        hasQuiz={hasQuiz}
        provider={provider}
        showTitle={isDesktopAndAbove}
      />
      <DesktopLeft>{additionalInfoBoxes}</DesktopLeft>
      <MobileTop>
        {mobileTopAdditionInfoBox}
        <ArticleSpeechSkipContainer>
          <ListenNow>
            {provider.speechPlayer({
              variant: "posties",
            })}
          </ListenNow>
        </ArticleSpeechSkipContainer>
      </MobileTop>
      <ContentContainer>
        <Correction>{provider.correction()}</Correction>
        <Content>
          <ContentBody
            advertising={advertising}
            extraComponentMap={{
              h1: Heading1,
              h2: Heading2,
              h3: Heading3,
              h4: Heading4,
              h5: Heading5,
              h6: Heading6,
              "inline-widget": null,
              table: GenericPostiesArticleTable,
              thead: GenericPostiesArticleTableThead,
              tr: GenericPostiesArticleTableTr,
            }}
            extraRenderFunctionMap={{
              a: (props, itemIndex, index, children, schemaNode, parentSchemaNode) => {
                try {
                  const href = schemaNode.attribs?.href ?? "";
                  const form = extractAfterPrefix(href, FormPrefixes);

                  if (schemaNode.type === "a" && form?.documentId) {
                    return (
                      <FormLink
                        key={itemIndex}
                        onClick={() => {
                          toggleByUrl(form.hash);
                        }}
                      >
                        {children}
                      </FormLink>
                    );
                  }
                } catch {
                  return null;
                }

                return (
                  <Link
                    {...props}
                    index={index}
                    key={itemIndex}
                    parentSchemaNode={parentSchemaNode}
                    schemaNode={schemaNode}
                  >
                    {children}
                  </Link>
                );
              },
              "blockquote-quote": (
                props,
                itemIndex,
                index,
                _children,
                schemaNode,
                parentSchemaNode,
              ) => (
                <GenericPostiesArticleBlockquoteQuote
                  {...props}
                  index={index}
                  key={itemIndex}
                  parentSchemaNode={parentSchemaNode}
                  reference={article}
                  schemaNode={schemaNode}
                />
              ),
            }}
            reference={article}
          />
        </Content>
      </ContentContainer>
      <DesktopRight>
        <ArticleSpeechSkipContainer>
          <ListenNow>
            {provider.speechPlayer({
              variant: "posties",
            })}
          </ListenNow>
        </ArticleSpeechSkipContainer>
        <ArticleSpeechSkipContainer>
          <DesktopVocabBox>
            <StyledGenericPostiesArticleVocabBox reference={article} />
          </DesktopVocabBox>
        </ArticleSpeechSkipContainer>
      </DesktopRight>
      <ArticleSpeechSkipContainer>
        <MobileVocabBox>
          <StyledGenericPostiesArticleVocabBox reference={article} />
        </MobileVocabBox>
      </ArticleSpeechSkipContainer>
      {hasQuiz && (
        <PostiesQuizWrapper>
          <NoSsr>
            <ErrorBoundary fallback={<></>}>
              <DynamicPostiesQuiz reference={article} />
            </ErrorBoundary>
          </NoSsr>
        </PostiesQuizWrapper>
      )}
      <MobileBottom>{mobileBottomAdditionInfoBoxes}</MobileBottom>
      <PostiesPaywallContainer />
      <Right />
    </GenericPostiesArticleContainer>
  );
};

GenericPostiesArticle.displayName = "GenericPostiesArticle";
