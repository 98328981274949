/**
 * @generated SignedSource<<344524ecd5afedbbf7087e9263d5b317>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleSpeechInlineWidgetArticle$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"genericArticleSpeechInlineWidgetGenericArticle">;
  readonly " $fragmentType": "articleSpeechInlineWidgetArticle";
};
export type articleSpeechInlineWidgetArticle$key = {
  readonly " $data"?: articleSpeechInlineWidgetArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSpeechInlineWidgetArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleSpeechInlineWidgetArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "genericArticleSpeechInlineWidgetGenericArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "69b08a5f20e96d9286ee13ced4a05e63";

export default node;
