import styled from "@emotion/styled";
import { ShareButton } from "@product/scmp-sdk";

import { ArticleShareWidget } from "scmp-app/components/article/article-share-widget";

import IconCopy from "./icon-copy.svg";

export const StyledIconCopy = styled(IconCopy)``;

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  align-items: center;

  inline-size: 100%;
`;
export const StyledArticleShareWidget = styled(ArticleShareWidget)`
  z-index: 1;

  inline-size: 36px;
  block-size: 36px;

  border: 1px solid #d9d9d9;
  border-radius: 50%;

  cursor: pointer;

  :hover {
    background-color: #dddddd;
  }
`;

export const StyledShareButton = styled(ShareButton)`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;

  ${StyledIconCopy} {
    path {
      fill: #00000000;
    }

    :hover {
      border-radius: 50%;

      background-color: #dddddd;
      path {
        fill: #dddddd;
      }
    }
  }
`;
