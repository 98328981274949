/**
 * @generated SignedSource<<4e77fa5ed92587e7dbd0ba4f37567727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleRenderArticle$data = {
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleActionWidgetArticle" | "articleAdditionalInfoBoxArticleContent" | "articleAuthorBlockArticle" | "articleAuthorDetailsContent" | "articleBaseAdvertisingInfoArticle" | "articleBeforeYouGoWidget" | "articleCommentsTriggerArticle" | "articleCorrectionsWidgetArticle" | "articleDateContent" | "articleFurtherReadingWidgetArticle" | "articleGeoLocationWidget" | "articleImageGalleryContent" | "articleLeadingArticle" | "articleMoreOnThisListArticle" | "articlePrintHeadlineMessageArticle" | "articleReadingTimeContent" | "articleRelatedTopicsContent" | "articleSectionsContent" | "articleSeriesArticle" | "articleShareWidgetArticle" | "articleSpeechInlineWidgetArticle" | "articleSpeechPlayerPortalArticle" | "articleSponsorContent" | "articleTopicContent" | "articleTopicFollowWidgetArticle" | "articleTopicWidgetArticle" | "articleTopicsContent" | "articleVocabBoxArticle" | "authorAvatarsArticle" | "authorNamesArticle" | "contentActionBarContent" | "contentBodyContent" | "contentHeadlineContent" | "contentLiveTagContent" | "contentReadMoreButtonContent" | "contentSchemaRenderContent" | "contentSubHeadlineContent" | "contentSummaryContent" | "genericArticleContent" | "globalCheckerContent" | "helpersCheckArticleRenderTypeArticle" | "helpersGetArticleTypeArticle" | "hooksPostiesArticleArticle" | "infographicArticleArticle" | "liveArticleArticle" | "morningStudioArticleContent" | "newsletterArticleArticle" | "pianoIntegrationArticle" | "plusColumnsArticleContent" | "plusGenericArticleContent" | "postMagazineArticleContent" | "seriesArticleArticle" | "styleArticleContent">;
  readonly " $fragmentType": "articleRenderArticle";
};
export type articleRenderArticle$key = {
  readonly " $data"?: articleRenderArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleRenderArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "bodyType",
    "variableName": "bodyType"
  },
  {
    "kind": "Variable",
    "name": "customContents",
    "variableName": "customContents"
  }
],
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SectionSegment",
    "kind": "LinkedField",
    "name": "sections",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "value",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaywallType",
        "kind": "LinkedField",
        "name": "paywallTypes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entityUuid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v5 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticleTypeSegment",
        "kind": "LinkedField",
        "name": "types",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleType",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
],
v6 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersGetArticleTypeArticle",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v5/*: any*/),
      "type": "Content",
      "abstractKey": "__isContent"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "customContents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleRenderArticle",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleActionWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleAdditionalInfoBoxArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleAuthorBlockArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleAuthorDetailsContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleBaseAdvertisingInfoArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleBeforeYouGoWidget"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleCommentsTriggerArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleCorrectionsWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleDateContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleFurtherReadingWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleImageGalleryContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleLeadingArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleMoreOnThisListArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articlePrintHeadlineMessageArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleReadingTimeContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleRelatedTopicsContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSectionsContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSeriesArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleShareWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSpeechInlineWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSpeechPlayerPortalArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleSponsorContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleTopicContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleTopicFollowWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleTopicsContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleTopicWidgetArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleVocabBoxArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleGeoLocationWidget"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorAvatarsArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authorNamesArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentActionBarContent"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "contentBodyContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentHeadlineContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentLiveTagContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentReadMoreButtonContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSubHeadlineContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSummaryContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "genericArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "globalCheckerContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckArticleRenderTypeArticle",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckCanShowInPlusPlatformContent",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersCheckArticleIsInSectionsArticle",
                  "selections": (v3/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersCheckIsPlusArticle",
                  "selections": (v4/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersCheckIsStyleArticle",
                  "selections": (v3/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersGetArticleTypeArticle",
                  "selections": (v5/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersUseContentSponsorContent",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Topic",
                      "kind": "LinkedField",
                      "name": "topics",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Sponsor",
                          "kind": "LinkedField",
                          "name": "sponsor",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "sponsorTagSponsor"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sponsorType",
                          "storageKey": null
                        }
                      ],
                      "type": "Article",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "helpersCheckIsMainTopicContent",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Topic",
                          "kind": "LinkedField",
                          "name": "topics",
                          "plural": true,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPlusArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v4/*: any*/),
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPostiesArticle",
          "selections": (v3/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPostMagazineArticle",
          "selections": (v3/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsStyleArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v3/*: any*/),
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v6/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v6/*: any*/),
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "hooksPostiesArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "infographicArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "liveArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "morningStudioArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "newsletterArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "pianoIntegrationArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "plusColumnsArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "plusGenericArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "postMagazineArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "seriesArticleArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "styleArticleContent"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "a7e4a28c89b55bbd86e22248a2e51e10";

export default node;
