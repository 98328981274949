/**
 * @generated SignedSource<<ce130b6f3c59366d4e49df4f2a5ed62e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleMoreOnThisListArticle$data = {
  readonly moreOnThisArticles: ReadonlyArray<{
    readonly entityId: string;
    readonly headline: string | null | undefined;
    readonly images: ReadonlyArray<{
      readonly style: {
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly url: string;
    } | null | undefined> | null | undefined;
    readonly socialHeadline: string | null | undefined;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "articleMoreOnThisListArticle";
};
export type articleMoreOnThisListArticle$key = {
  readonly " $data"?: articleMoreOnThisListArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleMoreOnThisListArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleMoreOnThisListArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "moreOnThisArticles",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "socialHeadline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "style": "237x147"
                  }
                }
              ],
              "concreteType": "ImageStyle",
              "kind": "LinkedField",
              "name": "style",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": "style(filter:{\"style\":\"237x147\"})"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "0bd957de63c4c83a6392930045709b95";

export default node;
