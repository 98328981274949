import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { ZIndexLayer } from "shared/lib/styles";

import { StyledDiv as ArticleDate } from "scmp-app/components/article/article-date/default-article-date/styles";
import { ArticleTrustLabel } from "scmp-app/components/article/article-trust-label";
import { StyledEntityLink as AuthorName } from "scmp-app/components/author/author-name/styles";
import { EntityFollowButton } from "scmp-app/components/entity-follow-button";

import { ArticleAuthorAvatars } from "./author-avatars";
import { AuthorNames } from "./author-names";
import { AndSeparator, CommaSeparator } from "./author-names/styles";

export const StyledArticleAuthorAvatars = styled(ArticleAuthorAvatars)`
  margin-inline-end: 16px;
`;

type InformationContainerProps = {
  $authorsLength: number;
};
export const InformationContainer = styled.div<InformationContainerProps>`
  flex-grow: 1;

  padding-block-start: ${props => (props.$authorsLength > 1 ? "12px" : "0")};

  ${theme.breakpoints.up("desktop")} {
    padding-block-start: 0;
  }
`;

export const ArticleDateAndLabelContainer = styled.div`
  display: flex;
  flex-flow: column;

  ${theme.breakpoints.up("tablet")} {
    flex-flow: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;

export type ArticleAuthorBlockContainerProps = {
  $authorsLength: number;
  $variant?: "dark" | "light";
};
export const ArticleAuthorBlockContainer = styled.div<ArticleAuthorBlockContainerProps>`
  display: flex;
  flex-flow: ${props => (props.$authorsLength > 1 ? "column" : "row")};
  justify-content: flex-start;
  align-items: stretch;

  ${theme.breakpoints.up("desktop")} {
    flex-flow: row;
  }

  ${ArticleDate}, ${AuthorName}, ${CommaSeparator}, ${AndSeparator} {
    ${props =>
      props.$variant === "dark"
        ? css`
            color: #ffffff;
          `
        : null}
  }
`;

export const StyledArticleTrustLabel = styled(ArticleTrustLabel)`
  ${theme.breakpoints.only("mobile")} {
    margin-block-start: 4px;
  }
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)`
  z-index: ${ZIndexLayer.ArticleAuthorFollowButton};

  margin-block: 6px;
  margin-inline-start: 12px;
`;

export const StyledAuthorNames = styled(AuthorNames)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${theme.breakpoints.up("tablet")} {
    max-inline-size: 500px;
    margin-block-end: 4px;
  }
`;
