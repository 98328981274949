/**
 * @generated SignedSource<<972b4ec8b8004b6d8e015394a9b3b28f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type genericPostiesArticleContent$data = {
  readonly additionalInfoBoxes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"articleAdditionalInfoBoxArticle">;
  } | null | undefined> | null | undefined;
  readonly authors: ReadonlyArray<{
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
      readonly urlAlias: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"articleBaseAdvertisingInfoArticle" | "blockquoteQuoteGenericPostiesArticleContent" | "contentBodyContent" | "genericPostiesArticleVocabBoxArticle" | "helpersHasPostiesQuiz" | "quizPostiesQuizArticle">;
  readonly " $fragmentType": "genericPostiesArticleContent";
};
export type genericPostiesArticleContent$key = {
  readonly " $data"?: genericPostiesArticleContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"genericPostiesArticleContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "PARAGRAPH",
      "kind": "LocalArgument",
      "name": "bodyType"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "customContents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "genericPostiesArticleContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlAlias",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdditionalInfoBox",
      "kind": "LinkedField",
      "name": "additionalInfoBoxes",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "articleAdditionalInfoBoxArticle"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "genericPostiesArticleVocabBoxArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "blockquoteQuoteGenericPostiesArticleContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleBaseAdvertisingInfoArticle"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "bodyType",
          "variableName": "bodyType"
        },
        {
          "kind": "Variable",
          "name": "customContents",
          "variableName": "customContents"
        }
      ],
      "kind": "FragmentSpread",
      "name": "contentBodyContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersHasPostiesQuiz",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SectionSegment",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Section",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "entityId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "quizPostiesQuizArticle"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "8c6f7f1de606024eeb70b7e0150ce03d";

export default node;
