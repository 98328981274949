import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import TrustLogo from "./trust-logo.svg";

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;

  column-gap: 4px;
`;

export const StyledTrustLogo = styled(TrustLogo)`
  block-size: 16px;

  path {
    fill: #aaaaaa;
  }
`;

export const StyledBaseLink = styled(BaseLink)`
  color: #aaaaaa;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 16px;
  text-decoration: underline;

  cursor: pointer;
`;
