/**
 * @generated SignedSource<<47b21e6d2867c4c4b66b1baf28564ef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleLeadingArticle$data = {
  readonly displaySlideShow: boolean | null | undefined;
  readonly entityId: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleLeadingImagesContent" | "contentCoverVideoContent" | "contentSchemaRenderContent" | "helpersIsArticleVideoAsHeroArticle">;
  readonly " $fragmentType": "articleLeadingArticle";
};
export type articleLeadingArticle$key = {
  readonly " $data"?: articleLeadingArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleLeadingArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleLeadingArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displaySlideShow",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleLeadingImagesContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentCoverVideoContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSchemaRenderContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersIsArticleVideoAsHeroArticle",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "multimediaEmbed",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "json",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "f3c7694b650c52a5d55d0d222278817e";

export default node;
