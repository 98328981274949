import styled from "@emotion/styled";
import { Button } from "@mui/base/Button";

export const ButtonStyled = styled(Button)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding-block: 5px;
  padding-inline: 15px;

  text-transform: uppercase;

  border-radius: 4px;

  cursor: pointer;

  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.default {
    color: rgb(25, 118, 210);

    border-color: rgba(25, 118, 210, 0.5);
    border-style: solid;
    border-width: 1px;

    background-color: rgba(0, 0, 0, 0);

    &:hover {
      border-color: rgb(25, 118, 210) !important;

      background-color: rgba(25, 118, 210, 0.04) !important;
    }
  }

  &.text {
    color: rgb(25, 118, 210);

    border-color: rgba(25, 118, 210, 0.5);
    border-width: 0;

    background-color: rgba(0, 0, 0, 0);

    &:hover {
      border-color: rgb(25, 118, 210) !important;

      background-color: rgba(25, 118, 210, 0.04) !important;
    }
  }

  &.icon {
    padding-block: 5px !important;
    padding-inline: 6.5px !important;

    border-color: rgba(25, 118, 210, 0.5);
    border-width: 0;
    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
`;
