/**
 * @generated SignedSource<<080bf7059f9dd67ac0be4b3fb4d3636c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersIsArchiveWallArticle$data = {
  readonly createdDate: number | null | undefined;
  readonly paywallTypes: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersIsVelocityWallArticle">;
  readonly " $fragmentType": "helpersIsArchiveWallArticle";
};
export type helpersIsArchiveWallArticle$key = {
  readonly " $data"?: helpersIsArchiveWallArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersIsArchiveWallArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersIsArchiveWallArticle"
};

(node as any).hash = "20759885009aa42a350d07349eaae9da";

export default node;
