import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Icon from "./icon.svg";

export const IconWrapper = styled.div`
  display: flex;
  place-content: center center;

  inline-size: 220px;
  block-size: 44px;
  padding-block: 10px;
  padding-inline: 12px 16px;

  border-radius: 4px;

  background-color: #e5e5e5;
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

type ContainerProps = {
  $isMinify?: boolean;
};

export const Container = styled.div<ContainerProps>`
  border-block-end: none;

  ${IconWrapper} {
    ${props =>
      props.$isMinify &&
      css`
        inline-size: 44px;
        padding-block: 9px;
        padding-inline: 9px;
      `}
  }
`;
