import type { FunctionComponent } from "react";
import { useContext } from "react";

import { GalleryContext } from "scmp-app/components/image-gallery/contexts";

import { ToolbarButton } from "./toolbar-button";

export const ToolbarButtonClose: FunctionComponent = () => {
  const { instance } = useContext(GalleryContext);

  return (
    <ToolbarButton
      icon="close"
      onClick={() => {
        instance?.close();
      }}
      title="Close"
    />
  );
};

ToolbarButtonClose.displayName = "ToolbarButtonClose";
