export const paywall = {
  notArchive: {
    entityId: "511139",
    entityUuid: "c4365f23-40c4-47d5-a503-5c7f58c7b2b5",
    name: "Not archive"
  },
  notVelocity: {
    entityId: "511138",
    entityUuid: "8f904aa8-bd1b-4a06-93fc-104e3559fd4e",
    name: "Not velocity"
  },
  scmpPlus: {
    entityId: "517129",
    entityUuid: "716f570e-3083-4138-a080-47d3830fafe3",
    name: "SCMP Plus"
  },
  velocity: {
    entityId: "507836",
    entityUuid: "a847a765-95e0-444a-a4df-76b85c9d089b",
    name: "velocity"
  }
};