import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, theme } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

const textStyle = css`
  color: #2c4692;
  font-size: 14px;
  font-family: ${fontMerriweather};
  font-style: italic;
  line-height: normal;
  font-variant-numeric: lining-nums proportional-nums;

  ${theme.breakpoints.up("tablet")} {
    font-size: 15px;
  }
`;

export const StyledEntityLink = styled(EntityLink)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;

  padding-inline-start: 20px;
  border-inline-start: 1px solid #2c4692;

  ${textStyle}
`;

export const Title = styled.div`
  padding-block-end: 5px;

  font-weight: 400;

  border-block-end: 1px dashed #2c4692;

  ${textStyle}
`;

export const Headline = styled.div`
  max-inline-size: 240px;

  ${theme.breakpoints.up("tablet")} {
    max-inline-size: 400px;
  }

  ${StyledEntityLink} {
    font-weight: 700;

    ${textStyle}
  }
`;

export const Counter = styled.div`
  font-weight: 400;

  ${textStyle}
`;
