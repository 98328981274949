/**
 * @generated SignedSource<<ded7906dc5fb8c22b9b09b1617198c10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type articleAdditionalInfoBoxArticle$data = {
  readonly description: {
    readonly json: Schema | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "articleAdditionalInfoBoxArticle";
};
export type articleAdditionalInfoBoxArticle$key = {
  readonly " $data"?: articleAdditionalInfoBoxArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleAdditionalInfoBoxArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "articleAdditionalInfoBoxArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "description",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "json",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AdditionalInfoBox",
  "abstractKey": null
};

(node as any).hash = "b3013417fe7358c1c59d996b67141949";

export default node;
