import type { FunctionComponent, ReactNode } from "react";

import type { ContainerProps } from "./styles";
import { ChildrenContainer, Container } from "./styles";

type Props = Partial<PropsFromStyledProps<ContainerProps>> & {
  children: ReactNode;
  className?: string;
};

export const OffsetContainer: FunctionComponent<Props> = ({
  backgroundColor,
  borderColor = "#000000",
  borderRadius = "100px",
  borderWidth = "2px",
  children,
  childrenOffset = 0,
  className,
  offset = 4,
  ...props
}) => (
  <Container
    {...props}
    $backgroundColor={backgroundColor}
    $borderColor={borderColor}
    $borderRadius={borderRadius}
    $borderWidth={borderWidth}
    $childrenOffset={childrenOffset}
    $offset={offset}
    className={className}
  >
    <ChildrenContainer>{children}</ChildrenContainer>
  </Container>
);
OffsetContainer.displayName = "OffsetContainer";
