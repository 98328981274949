/**
 * @generated SignedSource<<971835b75a57c1e7bb9d3ef938b8b8e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentReadMoreButtonContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"appButtonContent">;
  readonly " $fragmentType": "contentReadMoreButtonContent";
};
export type contentReadMoreButtonContent$key = {
  readonly " $data"?: contentReadMoreButtonContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentReadMoreButtonContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentReadMoreButtonContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "appButtonContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "fbe60ac1753c05855997675c4e197128";

export default node;
