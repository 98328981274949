import type { FunctionComponent } from "react";
import type { Optional } from "utility-types";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { type Props as OutstreamAdSlotProps } from "scmp-app/components/advertisement/ad-slots/outstream-ad-slot";
import {
  isNativeAdUnit,
  isOutstreamAdUnit,
} from "scmp-app/components/advertisement/ad-slots/types";
import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { useRenderContext } from "scmp-app/components/schema-render/content";

import { Container, InlineNativeSlot, InlineOutstreamAdSlot, InlineSlot } from "./styles";

export type Props = {
  className?: string;
  entityId: string;
  slot: Optional<AdSlotProps, "sizes"> | OutstreamAdSlotProps;
};

export const InlineAdSlot: FunctionComponent<Props> = ({ className, entityId, slot }) => {
  const { advertising } = useRenderContext();

  if (isOutstreamAdUnitProps(slot)) {
    return (
      <ArticleSpeechSkipContainer>
        <InlineOutstreamAdSlot {...advertising} {...slot} className={className} />
      </ArticleSpeechSkipContainer>
    );
  }

  if (isNativeAdUnit(slot.adUnit)) {
    return (
      <ArticleSpeechSkipContainer>
        <InlineNativeSlot sizes={["fluid"]} {...advertising} {...slot} className={className} />
      </ArticleSpeechSkipContainer>
    );
  }

  return (
    <ArticleSpeechSkipContainer>
      <Container>
        <InlineSlot
          autoRefreshOptions={{
            scrollBackRefreshOptions: {
              slotArticleEntityId: entityId,
            },
          }}
          className={className}
          sizes={[
            [300, 250],
            [300, 600],
            [320, 480],
          ]}
          withBackground
          withLabel
          withSticky
          {...advertising}
          {...slot}
        />
      </Container>
    </ArticleSpeechSkipContainer>
  );

  function isOutstreamAdUnitProps(slot: Props["slot"]): slot is OutstreamAdSlotProps {
    return isOutstreamAdUnit(slot.adUnit);
  }
};

InlineAdSlot.displayName = "InlineAdSlot";
