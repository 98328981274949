import { type FunctionComponent, type ReactNode, useState } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import type { geoLocationContinentListQuery } from "scmp-app/queries/__generated__/geoLocationContinentListQuery.graphql";

import { GeoLocationGroup } from "./geo-location-group";
import {
  AccordionLocationContainer,
  AccordionLocationMenu,
  Container,
  Divider,
  Header,
} from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const GeoLocationContinentList: FunctionComponent<Props> = ({ className }) => {
  const queuesData = useLazyLoadQuery<geoLocationContinentListQuery>(
    graphql`
      query geoLocationContinentListQuery {
        chinaQueue: queue(filter: { name: "collection_related_topics_520763" }) {
          ...geoLocationGroupQueue
        }
        middleEastQueue: queue(filter: { name: "collection_related_topics_520764" }) {
          ...geoLocationGroupQueue
        }
        asiaQueue: queue(filter: { name: "collection_related_topics_520210" }) {
          ...geoLocationGroupQueue
        }
        oceaniaQueue: queue(filter: { name: "collection_related_topics_520211" }) {
          ...geoLocationGroupQueue
        }
        russiaAndCentralAsiaQueue: queue(filter: { name: "collection_related_topics_520765" }) {
          ...geoLocationGroupQueue
        }
        europeQueue: queue(filter: { name: "collection_related_topics_520212" }) {
          ...geoLocationGroupQueue
        }
        africaQueue: queue(filter: { name: "collection_related_topics_520215" }) {
          ...geoLocationGroupQueue
        }
        americasAndCaribbeanQueue: queue(filter: { name: "collection_related_topics_520214" }) {
          ...geoLocationGroupQueue
        }
        northAmericaQueue: queue(filter: { name: "collection_related_topics_520213" }) {
          ...geoLocationGroupQueue
        }
      }
    `,
    {},
  );

  const continentQueueList = [
    {
      locationName: "China",
      reference: queuesData.chinaQueue,
    },
    {
      locationName: "Asia",
      reference: queuesData.asiaQueue,
    },
    {
      locationName: "North America",
      reference: queuesData.northAmericaQueue,
    },
    {
      locationName: "Middle East",
      reference: queuesData.middleEastQueue,
    },
    {
      locationName: "Europe",
      reference: queuesData.europeQueue,
    },
    {
      locationName: "Russia and Central Asia",
      reference: queuesData.russiaAndCentralAsiaQueue,
    },
    {
      locationName: "Oceania",
      reference: queuesData.oceaniaQueue,
    },
    {
      locationName: "Africa",
      reference: queuesData.africaQueue,
    },
    {
      locationName: "Americas and Caribbean",
      reference: queuesData.americasAndCaribbeanQueue,
    },
  ];

  const [expandedLocation, setExpandedLocation] = useState<null | string>(null);

  return (
    <Container className={className}>
      <Header>Browse other locations</Header>
      <Divider />

      <AccordionLocationContainer>
        {continentQueueList.map((queue, index) => (
          <AccordionLocationMenu key={index}>
            <GeoLocationGroup
              expandedLocation={expandedLocation}
              locationName={queue.locationName}
              onClick={() => {
                setExpandedLocation(
                  expandedLocation === queue.locationName ? null : queue.locationName,
                );
              }}
              reference={queue.reference}
            />
          </AccordionLocationMenu>
        ))}
      </AccordionLocationContainer>

      <Divider />
    </Container>
  );
};

GeoLocationContinentList.displayName = "GeoLocationContinentList";
