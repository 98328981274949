import { ClickAwayListener } from "@mui/base";
import Fade from "@mui/material/Fade";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useSetAtom } from "jotai";
import { bindPopper, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { contentShareWidgetDrawerAtom } from "scmp-app/components/content/content-share-widget-drawer/atoms";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { shareArticle20Content$key } from "scmp-app/queries/__generated__/shareArticle20Content.graphql";

import type { Props as ShareMenuProps } from "./popup";
import { Article20ShareMenuPopup } from "./popup";
import { Container, StyledIconShare, StyledPopper } from "./styles";

type Props = Omit<ShareMenuProps, "reference"> & {
  className?: string;
  reference: shareArticle20Content$key;
};

export const Share: FunctionComponent<Props> = ({
  className,
  position,
  reference,
  ...shareMenuProps
}) => {
  const content = useFragment(
    graphql`
      fragment shareArticle20Content on Content {
        ...popupArticle20ShareMenuContent
      }
    `,
    reference,
  );

  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);
  const setContentShareWidgetDrawer = useSetAtom(contentShareWidgetDrawerAtom);

  const popupState = usePopupState({
    popupId: "article-2.0-share-menu",
    variant: "popper",
  });

  if (isMobile)
    return (
      <StyledIconShare
        onClick={() => {
          setContentShareWidgetDrawer(props => ({
            ...props,
            isOpen: true,
            position: position ?? "article_bottom",
          }));
        }}
      />
    );

  return (
    <Container className={className}>
      <StyledPopper
        {...bindPopper(popupState)}
        bindType="toggle"
        popupComponent={({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              popupState.close();
            }}
          >
            <Fade {...TransitionProps} timeout={{ enter: 200, exit: 0 }}>
              <Article20ShareMenuPopup
                className={className}
                position={position}
                reference={content}
                {...shareMenuProps}
              />
            </Fade>
          </ClickAwayListener>
        )}
        popupState={popupState}
        sendTrackingHandler={() => {
          sendGA4Tracking({
            action: "click",
            category: "share",
            customized_parameters: {
              action_type: "open_sharing_drawer",
              position: position ?? "article_header",
            },
            subcategory: "",
          });
        }}
        transition
        triggerComponent={<StyledIconShare {...bindTrigger(popupState)} />}
      />
    </Container>
  );
};

Share.displayName = "Share";
