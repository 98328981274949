import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { fontRobotoCondensed, setLineClamp } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

import { VerticalItemHeight } from "./consts";

export type StyledProps = {
  $variant: "horizontal" | "vertical";
};

export const StyledContainer = styled.div<StyledProps>`
  display: flex;

  inline-size: 100%;
  /* Hide the scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  ${props => {
    switch (props.$variant) {
      case "horizontal":
        return css`
          flex-flow: row nowrap;
          gap: 20px;

          overflow-x: auto;
        `;
      case "vertical":
        return css`
          flex-flow: column nowrap;
          gap: 30px;

          overflow-y: auto;
        `;
    }
  }}
`;

export const Item = styled(Card)`
  flex: none;

  inline-size: 220px;
  block-size: ${VerticalItemHeight}px;
  margin: 2px;
`;

export const StyledEntityLink = styled(EntityLink)`
  color: inherit;
`;

export const StyledFigure = styled.figure``;

export const StyledHeader = styled.div`
  padding-block: 12px;
  padding-inline: 14px;

  color: #000d37;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 16px;
`;

export const StyledTitle = styled.div`
  margin: 14px;

  ${setLineClamp(3, "21px")}
  font-weight: 700;
  font-size: 18px;
  font-family: ${fontRobotoCondensed};
`;
