import type { FunctionComponent, ReactNode } from "react";

import { BulletPointWrapper, StyledBulletPoint, StyledSubHeadlineText } from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const PostiesSubHeadline: FunctionComponent<Props> = ({ children, className }) => (
  <BulletPointWrapper className={className}>
    <StyledSubHeadlineText>{children}</StyledSubHeadlineText>
    <StyledBulletPoint />
  </BulletPointWrapper>
);
