import { FollowButtonOutline, FollowButtonSize } from "@product/scmp-sdk";
import first from "lodash/first";
import take from "lodash/take";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ArticleDate } from "scmp-app/components/article/article-date";
import {
  checkCanShowInPlusPlatform,
  checkIsContainsPlusQueryString,
  checkIsPlusArticle,
} from "scmp-app/components/article/article-render/plus/helpers";
import { AuthorHeaderBar } from "scmp-app/components/author/author-header-bar";
import type { articleAuthorBlockArticle$key } from "scmp-app/queries/__generated__/articleAuthorBlockArticle.graphql";

import { PlusArticleAuthorBlock } from "./plus-article-author-block";
import type { ArticleAuthorBlockContainerProps } from "./styles";
import {
  ArticleAuthorBlockContainer,
  ArticleDateAndLabelContainer,
  InformationContainer,
  StyledArticleAuthorAvatars,
  StyledArticleTrustLabel,
  StyledAuthorNames,
  StyledEntityFollowButton,
} from "./styles";

export type Props = {
  className?: string;
  firstNthAuthor?: number;
  reference: articleAuthorBlockArticle$key;
  variant?: ArticleAuthorBlockContainerProps["$variant"];
  withFollow?: boolean;
};

export const ArticleAuthorBlock: FunctionComponent<Props> = ({
  className,
  firstNthAuthor,
  reference: reference_,
  variant = "light",
  withFollow = true,
}) => {
  const router = useRouter();
  const article = useFragment(
    graphql`
      fragment articleAuthorBlockArticle on Article {
        ...articleDateContent
        ...authorNamesArticle
        authors {
          ...entityFollowButtonBase
          ...authorAvatarAuthor
        }
        ...helpersCheckCanShowInPlusPlatformContent
        ...helpersCheckIsPlusArticle
        ...plusArticleAuthorBlockArticle
        ...authorAvatarsArticle
      }
    `,
    reference_,
  );
  if (!article) return null;

  const authors = firstNthAuthor ? take(article?.authors, firstNthAuthor) : article?.authors;
  const authorsLength = authors?.length ?? 0;

  const handleRenderFollowButton = () => {
    const firstAuthor = first(article?.authors);
    if (!withFollow || !firstAuthor || authorsLength > 1) return null;
    return (
      <StyledEntityFollowButton
        outline={FollowButtonOutline.Outlined}
        reference={firstAuthor}
        size={FollowButtonSize.Thin}
        source="Article_top"
      />
    );
  };

  if (
    checkIsPlusArticle(article) ||
    (checkCanShowInPlusPlatform(article) && checkIsContainsPlusQueryString(router.query))
  ) {
    return (
      <PlusArticleAuthorBlock
        className={className}
        firstNthAuthor={firstNthAuthor}
        reference={article}
      />
    );
  }

  return (
    <ArticleAuthorBlockContainer
      $authorsLength={authorsLength}
      $variant={variant}
      className={className}
    >
      <StyledArticleAuthorAvatars firstNthAuthor={firstNthAuthor} reference={article} />

      <InformationContainer $authorsLength={authorsLength}>
        <AuthorHeaderBar />
        <StyledAuthorNames firstNthAuthor={firstNthAuthor} reference={article}>
          {handleRenderFollowButton()}
        </StyledAuthorNames>
        <ArticleDateAndLabelContainer>
          <ArticleDate reference={article} />
          <StyledArticleTrustLabel />
        </ArticleDateAndLabelContainer>
      </InformationContainer>
    </ArticleAuthorBlockContainer>
  );
};

ArticleAuthorBlock.displayName = "ArticleAuthorBlock";
