/**
 * @generated SignedSource<<1a22edf49217d60b07e6f3ad98fc6aeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inlineArticleSeriesInlineArticle$data = {
  readonly entityId: string;
  readonly seriesContainer: {
    readonly entityId: string;
    readonly headline: string | null | undefined;
    readonly series: ReadonlyArray<{
      readonly entityId?: string;
    } | null | undefined> | null | undefined;
    readonly socialHeadline: string | null | undefined;
    readonly urlAlias: string;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  } | null | undefined;
  readonly " $fragmentType": "inlineArticleSeriesInlineArticle";
};
export type inlineArticleSeriesInlineArticle$key = {
  readonly " $data"?: inlineArticleSeriesInlineArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"inlineArticleSeriesInlineArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "inlineArticleSeriesInlineArticle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "seriesContainer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "socialHeadline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "series",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/)
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "entityLink"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "719bc518e088261ead78243afb1f244b";

export default node;
