import type { FunctionComponent } from "react";

import { ImageSize } from "scmp-app/components/article/article-leading/article-leading-images/enums";
import type { DefaultComponentsProvider } from "scmp-app/components/article/article-render";

import MascotDesktopQuiz from "./mascot-desktop-quiz.png";
import MascotDesktop from "./mascot-desktop.png";
import MascotMobileQuiz from "./mascot-mobile-quiz.png";
import MascotMobile from "./mascot-mobile.png";
import {
  LeadingContainer,
  LeadingImageContainer,
  StyledLightBulb,
  StyledMascotDesktop,
  StyledMascotDesktopContainer,
  StyledMascotDesktopForQuiz,
  StyledMascotMobile,
  StyledMascotMobileContainer,
  StyledMascotMobileForQuiz,
  StyledStars,
} from "./styles";

type Props = {
  className?: string;
  hasQuiz?: boolean;
  provider: DefaultComponentsProvider;
  showTitle?: boolean;
};

export const GenericPostiesLeading: FunctionComponent<Props> = ({
  className,
  hasQuiz,
  provider,
  showTitle,
}) => (
  <LeadingContainer className={className}>
    <StyledMascotDesktopContainer>
      {hasQuiz ? (
        <StyledMascotDesktopForQuiz src={MascotDesktopQuiz.src} />
      ) : (
        <StyledMascotDesktop src={MascotDesktop.src} />
      )}
    </StyledMascotDesktopContainer>
    <StyledMascotMobileContainer>
      {hasQuiz ? (
        <StyledMascotMobileForQuiz src={MascotMobileQuiz.src} />
      ) : (
        <StyledMascotMobile src={MascotMobile.src} />
      )}
    </StyledMascotMobileContainer>
    <LeadingImageContainer>
      {provider.leading({
        responsiveDescriptionVariants: {
          desktopUp: "show",
          mobileUp: "hidden",
          tabletUp: "show",
        },
        responsiveImageVariants: {
          desktopUp: "normal",
          mobileUp: "square",
          tabletUp: "normal",
        },
        responsiveVariants: {
          desktopUp: ImageSize["1200x800"],
          mobileUp: ImageSize["1020x680"],
          tabletUp: ImageSize["1020x680"],
        },
        showTitle,
      })}
      {hasQuiz ? <StyledLightBulb /> : <StyledStars />}
    </LeadingImageContainer>
  </LeadingContainer>
);
