/**
 * @generated SignedSource<<61d5d550a2411efaf3052df3fddb2b31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type copyLinkArticle20CopyLinkContent$data = {
  readonly entityId: string;
  readonly headline: string | null | undefined;
  readonly shortUrl: string | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentType": "copyLinkArticle20CopyLinkContent";
};
export type copyLinkArticle20CopyLinkContent$key = {
  readonly " $data"?: copyLinkArticle20CopyLinkContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"copyLinkArticle20CopyLinkContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "copyLinkArticle20CopyLinkContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "405ae0fad8e1f3569515666193ff11ec";

export default node;
